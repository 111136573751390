import {AvatarComponent,} from '@rainbow-me/rainbowkit';

import "./wallet-avatar.scss";

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
    return ensImage ? (
        <img
            src={ensImage}
            width={size}
            height={size}
            style={{ borderRadius: 999 }}
        />
    ) : (
        <div
            style={{
                borderRadius: 999,
                height: size,
                width: size,
                display: "flex",
                position: "relative"
            }}
        >
            <div style={{
                display: "block",
                margin: "auto"
            }}>
                <span className={`material-icons icon ${size < 40 ? "small-size" : ""}`}>
                    wallet
                </span>
            </div>
        </div>
    );
};

export default CustomAvatar