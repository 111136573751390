import { createSlice } from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import { DEFAULT_CHAIN_ID } from "../../config";
import { Contracts } from "../../config/contracts";
import multicall from "../../utils/multicall";
import FounderPassAbi from "../../config/abis/FounderPass.json";
import { getFounderPassAddress } from "../../utils/addressHelpers";
import { getMerkleProof } from "../../utils/merkle";
import { whitelist } from "../../config/whitelist";
export const FounderPass = {
  publicSale: false,
  maxMint: 6969,
  maxCountPerPreSale: 3,
  maxCountPerPublicSale: 6,
  presalePrice: "0",
  publicSalePrice: "0",
  totalMinted: 0,
  user: {
    avaialbeClaimCount: 0,
    balance: 0,
    tokenIds: [],
  },
};

const initialState = { data: FounderPass };
export const founderPassSlice = createSlice({
  name: "FounderPass",
  initialState,
  reducers: {
    setFounderPassPublicData: (state, action) => {
      const liveFounderPassData = action.payload;
      state.data = { ...state.data, ...liveFounderPassData };
    },
    setFounderPassUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setFounderPassPublicData, setFounderPassUserData } =
  founderPassSlice.actions;

export const fetchFounderPassPublicDataAsync = () => async (dispatch) => {
  const founderPassData = await fetchFounderPass();
  dispatch(setFounderPassPublicData(founderPassData));
};

export const fetchFounderPassUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchFounderPassUser(account);
  dispatch(setFounderPassUserData(userData));
};

const fetchFounderPass = async () => {
  const founderPassAddress = Contracts.founderPass[DEFAULT_CHAIN_ID];
  const calls = [
    {
      address: founderPassAddress,
      name: "isPublicSale",
    },
    {
      address: founderPassAddress,
      name: "presalePrice",
    },
    {
      address: founderPassAddress,
      name: "publicsalePrice",
    },
    {
      address: founderPassAddress,
      name: "totalMinted",
    },
  ];

  const [isPublicSale, presalePrice, publicSalePrice, totalMinted] =
    await multicall(FounderPassAbi, calls);

  return {
    ...FounderPass,
    publicSale: isPublicSale[0],
    presalePrice: new BigNumber(presalePrice.toString()).toJSON(),
    publicSalePrice: new BigNumber(publicSalePrice.toString()).toJSON(),
    totalMinted: new BigNumber(totalMinted.toString()).toJSON(),
  };
};

const fetchFounderPassUser = async (account) => {
  const founderPassAddress = getFounderPassAddress();

  let merkleProof;

  merkleProof = getMerkleProof(whitelist, account);

  const calls = [
    {
      address: founderPassAddress,
      name: "availableClaimCount",
      params: [account, merkleProof],
    },
    {
      address: founderPassAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [availableClaimCount, balance] = await multicall(FounderPassAbi, calls);

  let tokenIds = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: founderPassAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(FounderPassAbi, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
    }
  }

  return {
    availableClaimCount: Number(availableClaimCount.toString()),
    balance: Number(balance.toString()),
    tokenIds: tokenIds,
  };
};
export default founderPassSlice.reducer;
