import { createSlice } from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import { DEFAULT_CHAIN_ID } from "../../config";
import { Contracts } from "../../config/contracts";
import multicall from "../../utils/multicall";
import SpidoxAbi from "../../config/abis/Spidox.json";
import { getSpidoxAddress } from "../../utils/addressHelpers";
import { getMerkleProof } from "../../utils/merkle";
import { spidoxWhitelist } from "../../config/whitelist";

export const Spidox = {
  MAX_MINT: 1120,
  totalMinted: 0,
  user: {
    isWhitelist: false,
    balance: 0,
    tokenIds: [],
  },
};

const initialState = { data: Spidox };
export const spidoxSlice = createSlice({
  name: "Spidox",
  initialState,
  reducers: {
    setSpidoxPublicData: (state, action) => {
      const liveSpidoxData = action.payload;
      state.data = { ...state.data, ...liveSpidoxData };
    },
    setSpidoxUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setSpidoxPublicData, setSpidoxUserData } = spidoxSlice.actions;

export const fetchSpidoxPublicDataAsync = () => async (dispatch) => {
  const spidoxData = await fetchSpidox();
  dispatch(setSpidoxPublicData(spidoxData));
};

export const fetchSpidoxUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchSpidoxUser(account);
  dispatch(setSpidoxUserData(userData));
};

const fetchSpidox = async () => {
  const spidoxAddress = Contracts.spidox[DEFAULT_CHAIN_ID];
  const calls = [
    {
      address: spidoxAddress,
      name: "MAX_MINT",
    },
    {
      address: spidoxAddress,
      name: "totalMinted",
    },
  ];

  const [maxMint, totalMinted] = await multicall(SpidoxAbi, calls);

  return {
    ...Spidox,
    MAX_MINT: new BigNumber(maxMint.toString()).toJSON(),
    totalMinted: new BigNumber(totalMinted.toString()).toJSON(),
  };
};

const fetchSpidoxUser = async (account) => {
  const spidoxAddress = getSpidoxAddress();

  let merkleProof;

  merkleProof = getMerkleProof(spidoxWhitelist, account);

  const calls = [
    {
      address: spidoxAddress,
      name: "isWhiteList",
      params: [account, merkleProof],
    },
    {
      address: spidoxAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [isWhitelist, balance] = await multicall(SpidoxAbi, calls);

  let tokenIds = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: spidoxAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(SpidoxAbi, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
    }
  }

  return {
    isWhitelist: isWhitelist[0],
    balance: Number(balance.toString()),
    tokenIds: tokenIds,
  };
};
export default spidoxSlice.reducer;
