import "./stake-card.scss";

import Button from "../../button";

export default function ConfirmUnstake(params) {
    return (
        <div className="stake-card">


            <footer>
                <Button
                    block={true}
                    kind="highlight"
                    onClick={() => {
                        params.onUnlock()
                    }}
                >
                    Unlock Staking
                </Button>
            </footer>
        </div>
    );
}