import { useCallback, useState } from "react";
import BigNumber from "bignumber.js";
import { useAllowance, useIsApprovedForAll } from "../../../hooks/useAllowance";
import { Contracts } from "../../../config/contracts";
import { DEFAULT_CHAIN_ID } from "../../../config";
import "./mint-status.scss";
import Button from "../../button";
import { useApprove, useApproveForAll } from "../../../hooks/useApprove";
import {
  useERC20,
  useMnAGameContract,
  useSpidoxContract,
} from "../../../hooks/useContracts";
import { toast } from "react-toastify";
import { useMnA, useMnAGameCR, useMnAGameCRUser } from "../../../state/hooks";
import { useTokenBalance } from "../../../hooks/useTokenBalance";
import {
  getMnAGameAddress,
  getORESAddress,
  getSpidoxAddress,
} from "../../../utils/addressHelpers";
import { useMintReveal } from "../../../hooks/useMintReveal";
import {useWeb3Context} from "../../../hooks/web3Context";
import {useAccount} from "wagmi";

export default function MintStatus() {
  const [counter, setCounter] = useState(0);
  const [approveConfirmed, setApproveConfirmed] = useState(false);
  //const cost = counter * 30000;

  const { address, connected, connect } = useAccount();
  const allowance = useAllowance(
    Contracts.ores[DEFAULT_CHAIN_ID],
    Contracts.mnAGameCR[DEFAULT_CHAIN_ID]
  );
  const mnAContract = useMnAGameContract();
  const mnA = useMnA();
  const mnAGameCR = useMnAGameCR();
  const mnAGameCRUser = useMnAGameCRUser(address);
  const isApproved =
    (address &&
      allowance &&
      new BigNumber(allowance.toString()).isGreaterThan(0)) ||
    approveConfirmed;

  const oresBalance = useTokenBalance(getORESAddress());
  const oresBalanceFormatted = oresBalance
    ? `${oresBalance.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })} $ORES`
    : `0.00 $ORES`;

  const allowCommits = mnAGameCR.allowCommits;
  const hasMintPending = mnAGameCRUser.hasMintPending;

  const canMint = mnAGameCRUser.canMint;

  const oresContract = useERC20(Contracts.ores[DEFAULT_CHAIN_ID]);
  const { onApprove } = useApprove(
    oresContract,
    Contracts.mnAGameCR[DEFAULT_CHAIN_ID]
  );

  const spidoxContract = useSpidoxContract();

  const [spidoxApproved, setSpidoxApproved] = useState(false);
  const isApprovedForAllForSpidox =
    useIsApprovedForAll(getSpidoxAddress(), getMnAGameAddress()) ||
    spidoxApproved;

  const { onApprovalForAll } = useApproveForAll(
    spidoxContract,
    getMnAGameAddress()
  );

  const [pendingApproveTx, setPendingApproveTx] = useState(false);
  //const [pendingMintCommitTx, setPendingMintCommitTx] = useState(false);
  const [pendingMintRevealTx, setPendingMintRevealTx] = useState(false);
  const handleApprove = useCallback(async () => {
    try {
      setPendingApproveTx(true);
      const approveStatus = await onApprove();
      if (approveStatus === 1) {
        setApproveConfirmed(true);
        toast.success("Approved successfully!");
      }
      setPendingApproveTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Approve failed");
    }
  }, [onApprove]);

  // Mint Commit & Reveal
  //const { onMintCommit } = useMintCommit(mnAContract);
  const { onMintReveal } = useMintReveal(mnAContract);
  /*const handleMintCommit = useCallback(async () => {
    try {
      //setPendingMintCommitTx(true);
      const status = await onMintCommit(counter, false);
      if (status === 1) {
        toast.success("Commit successfully!");
      }
      setPendingMintCommitTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Commit failed!");
    }
  }, [onMintCommit, counter]);*/

  const handleMintReveal = useCallback(async () => {
    try {
      setPendingMintRevealTx(true);
      const status = await onMintReveal();
      if (status === 1) {
        toast.success("Revealed successfully!");
      }
      setPendingMintRevealTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Reveal failed");
    }
  }, [onMintReveal]);

  // STATUS BAR PROGRESS
  const progress = mnA.maxTokens > 0 ? (mnA.minted * 100.0) / mnA.maxTokens : 0;

  const oresCost = (counter, minted) => {
    let sum = 0;
    for (let idx = 0; idx < counter; idx++) {
      let cost = 0;
      if (minted + idx < 6969) {
        cost = 0;
      } else if (minted + idx < 14000) {
        cost = 30000;
      } else if (minted + idx < 20999) {
        cost = 60000;
      } else if (minted + idx < 27998) {
        cost = 90000;
      } else {
        cost = 90000;
      }

      sum += cost;
    }

    return sum;
  };

  // DECREASE MINT COUNT
  const decrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  // INCREASE MINT COUNT
  const increment = () => {
    if (counter < 10) {
      setCounter(counter + 1);
    }
  };

  return (
    <div className="mint-status">
      <h3>MINTING</h3>

      <table>
        <thead>
          <tr>
            <th>GEN-0</th>
            <th>
              7030 GEN 1 <em>30K</em>
            </th>
            <th>
              6999 GEN 1 <em>60K</em>
            </th>
            <th>
              6999 GEN 1 <em>90K</em>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>
              <div className="mint-status-total">
                <div
                  className="mint-status-progress"
                  style={{ width: `${progress.toFixed()}%` }}
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="grid">
        <div className="col-4_md-12">
          <p>
            MINTED:{" "}
            <strong>
              {mnA.minted}/{mnA.maxTokens}
            </strong>
          </p>

          <p>
            $ORES BALANCE: <strong>{oresBalanceFormatted}</strong>
          </p>
        </div>

        <div className="col-8_md-12 l-right">
          <div className="l-flex">
            <div>
              <div className="mint-quantity">
                <button disabled={true} onClick={decrement}>-</button>
                <div className="quantity">{counter}</div>
                <button disabled={true} onClick={increment}>+</button>
              </div>
              <h3 className="mint-status-tip">QUANTITY</h3>
            </div>

            <div className="l-pad-left-3">
              {isApproved ? (
                allowCommits && hasMintPending ? (
                  <Button
                    disabled={!canMint}
                    kind="highlight"
                    onClick={() => {
                      if (!pendingMintRevealTx) {
                        handleMintReveal();
                      }
                    }}
                  >
                    Reveal
                  </Button>
                ) : (
                  <Button
                    disabled={true/*pendingMintCommitTx*/}
                    kind="highlight"
                    onClick={() => {
                      /*if (cost > oresBalance) {
                        setCounter(1);
                      } else {
                        handleMintCommit();
                      }*/
                    }}
                  >
                    Commit
                  </Button>
                )
              ) : (
                <Button
                  disabled={pendingApproveTx}
                  kind="highlight"
                  onClick={() => {
                    handleApprove();
                  }}
                >
                  Approve
                </Button>
              )}

              {!isApprovedForAllForSpidox ? (
                <Button
                  disabled={pendingApproveTx}
                  kind="highlight"
                  space="left"
                  onClick={async () => {
                    const status = onApprovalForAll();
                    if (status === 1) {
                      setSpidoxApproved(true);
                    }
                  }}
                >
                  Approve Spidox
                </Button>
              ) : null}


              <h3 className="mint-status-tip">
                PRICE: {oresCost(counter, mnA.minted)} $ORES
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
