import { useCallback } from "react";

export const useMigration = (mnaV2Contract) => {
  const handleMigration = useCallback(
    async (tokenIds) => {
      try {
        const tx = await mnaV2Contract.claimTokens(tokenIds);
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [mnaV2Contract]
  );

  return { onMigration: handleMigration };
};
