import { useState, useCallback } from "react";
import Button from "../button";
import Spidox from "../../images/characters/spidox.png";

import { toast } from "react-toastify";
import { useSpidoxContract } from "../../hooks/useContracts";
import { useSpidoxUser } from "../../state/hooks";

import { useSpidoxClaim } from "../../hooks/useSpidoxClaim";
import AccountTree from "../../merkle/accountTree";
import { spidoxWhitelist } from "../../config/whitelist";
import {useWeb3Context} from "../../hooks/web3Context";
import {useAccount} from "wagmi";

export default function SpidoxCard() {
  const { address, connected, connect } = useAccount();
  const spidoxContract = useSpidoxContract();
  const { onSpidoxClaim } = useSpidoxClaim(spidoxContract);

  // state variables
  const [pendingSpidoxClaimTx, setPendingSpidoxClaimTx] = useState(false);
  const spidoxUser = useSpidoxUser();
  let isClaimable = spidoxUser.balance === 0 && spidoxUser.isWhitelist;
  let isAlreadyClaimed = spidoxUser.isWhitelist && spidoxUser.balance > 0;

  const handleSpidoxClaim = useCallback(async () => {
    try {
      setPendingSpidoxClaimTx(true);
      const accountTree = new AccountTree(spidoxWhitelist);
      const merkleProof = accountTree.getProof(address);
      const claimStatus = await onSpidoxClaim(1, merkleProof);
      if (claimStatus === 1) {
        toast.success(`Claiming success!`);
      }
      setPendingSpidoxClaimTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Claiming failed!");
    }
  }, [address, onSpidoxClaim]);

  return (
    <div>
    {isClaimable ? 
     (<div className="mint-wrapper">
        <div className="mint">
          <header>
            <h4>Spidox Claim</h4>
            <img className="is-alien" width="24" src={Spidox} alt="Spidox" />
          </header>
          <div className="mint-options">
            <h4>Quantity</h4>

            <div className="mint-quantity">
              <div className="quantity">1</div>
            </div>
          </div>

          <footer>
            {connected ? (
              isClaimable ? (
                <Button
                  kind="primary"
                  disabled={pendingSpidoxClaimTx}
                  block={true}
                  onClick={() => {
                    if (!isAlreadyClaimed) {
                      handleSpidoxClaim();
                    }
                  }}
                >
                  {!isAlreadyClaimed ? "CLAIM" : "Already Claimed"}
                </Button>
              ) : (
                <Button kind="primary" block={true}>
                  You can't claim Spidox
                </Button>
              )
            ) : (
              <Button
                kind="secondary"
                block={true}
                onClick={() => {
                  connect()
                }}
              >
                CONNECT WALLET
              </Button>
            )}
          </footer>
        </div>
      </div>):(null)
    }
    </div>
  );
}
