import {useState} from "react";
import BigNumber from "bignumber.js";
import {getBalanceNumber} from "../../utils/formatBalance";
import "./sidenav.scss";
import {NavLink} from "react-router-dom";
import Axe from "../../images/characters/missions/axe.png";
import Ores from "../../images/characters/ores.png";
import Label from "../label";
import Button from "../button";
import {shorter} from "../../utils/formatBalance";
import {
    ORES_PREMINT,
    ORES_TOTAL_SUPPLY,
    ORES_FOR_EPOCH1,
    ORES_FOR_EPOCH2,
} from "../../config";
import Bars from "../../images/icons/bars.svg";
import {useGameStats} from "../../hooks/useTokenBalance";
import {useWeb3Context} from "../../hooks/web3Context";
import {ConnectButton, useConnectModal} from "@rainbow-me/rainbowkit";
import {useAccount, useConnect, useDisconnect} from "wagmi";

export default function SideNav(params) {
    const {address = "", isConnected, isReconnecting} = useAccount();
    const {error: errorMessage, disconnect} = useDisconnect();
    const {openConnectModal} = useConnectModal()
    const username = "User Name";
    const [selected, toggleSelected] = useState(false);

    const premintedFormatted = `${ORES_PREMINT.toLocaleString(undefined, {
        maximumFractionDigits: 3,
    })}`;

    const totalSupplyFormatted = `${ORES_TOTAL_SUPPLY.toLocaleString(undefined, {
        maximumFractionDigits: 3,
    })}`;

    const gameStats = useGameStats();
    const collected = getBalanceNumber(new BigNumber(gameStats.oresClaimed));
    const uncollected = ORES_FOR_EPOCH1 + ORES_FOR_EPOCH2 - collected;

    const collectedFormatted = `${collected.toLocaleString(undefined, {
        maximumFractionDigits: 2,
    })}`;

    const uncollectedFormatted = `${uncollected.toLocaleString(undefined, {
        maximumFractionDigits: 2,
    })}`;

    // ON CLICK FIRE CALLBACK FUNCTION PASSING ID AND TOGGLE SELECTED STATE
    const toggleMenu = () => {
        toggleSelected(!selected);
    };

    return (
        <nav className={`sidenav ${selected ? "is-visible" : ""}`}>
            <header>
                <Label>Epoch 2</Label>
                <figure>
                    <img className="avatar" width="96" src={Axe} alt={username}/>
                </figure>

                <div className="sidenav-ores">
                    <img className="avatar" width="96" src={Ores} alt={username}/>

                    <ul>
                        <li>Premine: {premintedFormatted}</li>
                        <li>Collected: {collectedFormatted}</li>
                        <li>Uncollected: {uncollectedFormatted}</li>
                        <li className="is-total">Total $Ores: {totalSupplyFormatted}</li>
                    </ul>
                </div>

                <button onClick={toggleMenu} className="sidenav-mobile-button">
                    <img src={Bars} alt="menu"/>
                </button>
            </header>

            <div className="sidenav-links">

                <div className={"connect-wallet"}>
                    <div className={"wallet-container"}>
                        <ConnectButton chainStatus={'icon'} showBalance={false}/>
                    </div>
                </div>

                <h4>GENERAL</h4>
                <ul>
                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/">
              <span className="material-icons">
              analytics
              </span>
                            Game Stats
                        </NavLink>
                    </li>

                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/collector">
              <span className="material-icons">
              store
              </span>
                            The Collector
                        </NavLink>
                    </li>

                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/vault">
              <span className="material-icons">
              lock
              </span>
                            Vault
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="sidenav-links">
                <h4>EPOCH 3</h4>
                <ul>
                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/incubator">
              <span className="material-icons">
              swap_horiz
              </span>
                            Egg Incubation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/battles">
              <span className="material-icons">
              sports_kabaddi
              </span>
                            Boss Battles
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="sidenav-links">
                <h4>EPOCH 2</h4>
                <ul>
                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/swap">
              <span className="material-icons">
              swap_horiz
              </span>
                            Swap NFT
                        </NavLink>
                    </li>

                    {/* <li>
            <NavLink onClick={toggleMenu} to="/dashboard/training">
              <span className="material-icons">
              trending_up
              </span>
              Training [Lvl Up]
            </NavLink>
          </li> */}

                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/bootcamp">
              <span className="material-icons">
              sports_martial_arts
              </span>
                            Bootcamp [staking]
                        </NavLink>
                    </li>

                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/claim">
            <span className="material-icons">
            account_balance
            </span>
                            $ORE Claim
                        </NavLink>
                    </li>

                </ul>
            </div>


            <div className="sidenav-links">
                <h4>EPOCH 1</h4>
                <ul>
                    <li>
                        <NavLink onClick={toggleMenu} to="/dashboard/minting">
                            <span className="material-icons">monetization_on</span>
                            Minting &amp; Staking
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="sidenav-links">
                <h4>MORE INFORMATION</h4>
                <ul>
                    <li>
                        <a onClick={toggleMenu} target="_blank" rel="noreferrer"
                           href="https://medium.com/@spacenftdotgame/space-game-l1-l2-hybrid-100-onchain-nft-game-3ecba7b7c690">
                            <span className="material-icons">videogame_asset</span> How to Play
                        </a>
                    </li>
                    <li>
                        <NavLink onClick={toggleMenu} to="/">
                            <span className="material-icons">public</span>
                            Website
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/*<footer>
                <ConnectButton/>
            </footer>*/}
        </nav>
    );
}
