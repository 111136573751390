import { useCallback } from "react";

export const useKlayeClaim = (stakingContract) => {
  const handleKlayeClaim = useCallback(
    async (tokenIds, unstake) => {
      try {
        const tx = await stakingContract.claimManyFromMarinePoolAndAlienPool(
          tokenIds,
          unstake
        );
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [stakingContract]
  );
  return { onKlayeClaim: handleKlayeClaim };
};
