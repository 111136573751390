import "./klaye-vault.scss";
import { useCallback, useState } from "react";
import Button from "../../button";
import { toast } from "react-toastify";
import { BigNumber as bn } from "ethers";
import {useWeb3Context} from "../../../hooks/web3Context";
import ore_reimbursement from "../../../config/ore_reimburse.json";
import BalanceTree from "../../../helpers/merkle/balance-tree";
import {useClaimOreReimbursement} from "../../../hooks/useOreReimbursementPool";
import {useOreReimbursementUser} from "../../../state/hooks";
import {numberWithCommas} from "../../../helpers/numbers";
import numeral from "numeral";
import {useAccount, useConnect} from "wagmi";
import {useConnectModal} from "@rainbow-me/rainbowkit";

export default function VaultCard({ img, title }) {

  const [pendingClaimTx, setPendingClaimTx] = useState(false);
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const {index, amount, proof, claimableBalance, perDay, remainingBalance, totalClaimed, lastClaimTime}
      = useOreReimbursementUser()

  const { onOreReimbursement } = useClaimOreReimbursement()
  const handleClaimOreReimbursement = async () => {
    if (!address) return;
    try {
      const status = await onOreReimbursement(
          address,
          index,
          amount,
          proof
      );
      if (status === 1) {
        toast.success("Claimed successfully!");
      }
      setPendingClaimTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Claim failed");
    }
  }

  return (
    <div className="klaye-vault-card">
      <div className="grid">
        <div className="col-9_md-12">
          <h3>{title}</h3>
          {<h4>Withdraw rate: 10% per day</h4>}
        </div>
      </div>

      <figure>
        <img alt={title} src={img} />
      </figure>

      <table>
        <thead>
          <tr>
            <th>Total Claimable</th>
            <th>Per day</th>
            <th>Remaining Claimable</th>
            <th>Total Claimed</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{numberWithCommas(numeral(claimableBalance).format("0.00")) + " $ORE"}</td>
            <td>{numberWithCommas(numeral(perDay).format("0.00")) + " $ORE"}</td>
            <td>{numberWithCommas(numeral(remainingBalance).format("0.00")) + " $ORE"}</td>
            <td>{numberWithCommas(numeral(totalClaimed).format("0.00")) + " $ORE"}</td>
          </tr>
        </tbody>
      </table>

      <footer className="grid">
        {!isConnected ? (
          <div className="col-12">
            <Button block={true} kind="highlight" onClick={() => {openConnectModal()}}>
              Unlock Wallet
            </Button>
          </div>
        ) : (
            <>
              <div className="col-12_md-12">
                <Button
                    disabled={pendingClaimTx || remainingBalance === 0}
                    block={true}
                    kind={pendingClaimTx ? "secondary" : "highlight"}
                    onClick={!pendingClaimTx && remainingBalance > 0 ? handleClaimOreReimbursement : null}
                >
                  Claim $ORE
                </Button>
              </div>
            </>
        )}
      </footer>
    </div>
  );
}
