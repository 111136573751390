import { useCallback } from "react";
import { useRewardsContract, useVaultContract } from "./useContracts";

export const useRewardsDeposit = () => {
  const rewardsContract = useRewardsContract();
  const handleRewardsDeposit = useCallback(
    async (amount, value = "") => {
      try {
        const tx = await rewardsContract.stake(amount, {
          value: value !== "" ? value : 0,
        });
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [rewardsContract]
  );

  return { onRewardsDeposit: handleRewardsDeposit };
};

export const useVaultDeposit = (address) => {
  const rewardsContract = useVaultContract(address);
  const handleRewardsDeposit = useCallback(
    async (amount, value = "") => {
      try {
        const tx = await rewardsContract.stake(amount, {
          value: value !== "" ? value : 0,
        });
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [rewardsContract]
  );

  return { onRewardsDeposit: handleRewardsDeposit };
};
