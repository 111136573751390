import "./item-card.scss";
import { useState } from "react";

import Button from "../../button";
import SelectButton from "../select-button";
import Marine from "../../../images/characters/default.png";
import Alien from "../../../images/characters/alien.png";
import { toast } from "react-toastify";

// HELPER NUMBER FUNCTION
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ItemCard({
  img,
  name,
  sacrafice = false,
  aliens = [],
  marines = [],
  remaining,
  price,
  onPurchase,
  isApproved = false,
  isApproveForAll = false,
  onApprove,
  onApproveForAll,
  claimed = false,
  disabled = false
}) {
  const [selected, setSelected] = useState(null);
  const [approveConfirmed, setApproveConfirmed] = useState(false);
  const [approveAllConfirmed, setApproveAllConfirmed] = useState(false);
  const [pendingPurchaseTx, setPendingPurchaseTx] = useState(false);
  const approved = isApproved || approveConfirmed;
  const approvedAll = isApproveForAll || approveAllConfirmed;
  const cta = sacrafice ? "Sacrifice & Obtain" : "Purchase";
  const hasItems = !!(marines.length || aliens.length); 

  const toggleSelected = (item) => {
    // IF ALREADY SELECTED TOGGLE OFF
    if (selected === item) {
      setSelected(null);
    }
    // ELSE TOGGLE ON
    else {
      setSelected(item);
    }
  };

  return (
    <div className="item-card">
      <figure>
        <img className="item-card-image" alt={name} src={img} />
      </figure>

      <h3>{name}</h3>
      <ul>
        <li>
          Remaining: <strong>{numberWithCommas(remaining)}</strong>
        </li>
        <li>
          Current Price: <strong>{price}</strong>
        </li>
      </ul>

      {/* SHOW SELECTOR IF SACRAFICE IS NEEDED */}
      {sacrafice && (
        <div className="item-card-content">
          {hasItems &&
            <h4 className="item-card-content-title">
              Select an item to sacrifice
            </h4>
          }

          {marines.map((item) => (
            <SelectButton
              key={item.id}
              img={Marine}
              id={item.id}
              rank={item.rank}
              isMarine={true}
              title="Marine"
              selected={item.id === selected}
              onSelect={toggleSelected}
            />
          ))}

          {aliens.map((item) => (
            <SelectButton
              key={item.id}
              img={Alien}
              id={item.id}
              rank={item.rank}
              title="Alien"
              selected={item.id === selected}
              onSelect={toggleSelected}
            />
          ))}

          {!marines.length && !aliens.length  &&
            <p className="item-card-placeholder">
              No items
            </p>
          }
        </div>
      )}

      {!disabled && 
        <footer>
          {approved ? (
            sacrafice ? (
              approvedAll ? (
                <Button
                  disabled={!selected || pendingPurchaseTx || claimed}
                  kind="purchase"
                  onClick={async () => {
                    if (selected) {
                      try {
                        setPendingPurchaseTx(true);
                        const status = await onPurchase(selected);
                        if (status === 1) {
                          toast.success(`Purchasing ${name} successfully!`);
                          setPendingPurchaseTx(false);
                        }
                      } catch (e) {
                        console.error(e);
                        toast.error(`Purchase ${name} failed!`);
                        setPendingPurchaseTx(false);
                      }
                    }
                  }}
                >
                  {claimed ? "Already claimed" : cta}
                </Button>
              ) : (
                <Button
                  kind="purchase"
                  onClick={async () => {
                    try {
                      const status = await onApproveForAll();
                      if (status === 1) {
                        toast.success("Approved MnA successfully!");
                        setApproveAllConfirmed(true);
                      }
                    } catch (e) {
                      console.error(e);
                      toast.error("Approve MnA failed!");
                    }
                  }}
                >
                  Approve MnA
                </Button>
              )
            ) : (
              <Button
                disabled={pendingPurchaseTx || claimed}
                kind="purchase"
                onClick={async () => {
                  try {
                    setPendingPurchaseTx(true);
                    const status = await onPurchase();
                    if (status === 1) {
                      toast.success(`Purchasing ${name} successfully!`);
                      setPendingPurchaseTx(false);
                    }
                  } catch (e) {
                    console.error(e);
                    toast.error(`Purchase ${name} failed!`);
                    setPendingPurchaseTx(false);
                  }
                }}
              >
                {claimed ? "Already claimed" : cta}
              </Button>
            )
          ) : (
            <Button
              kind="purchase"
              onClick={async () => {
                try {
                  const status = await onApprove();
                  if (status === 1) {
                    toast.success("Approved $ORES successfully!");
                    setApproveConfirmed(true);
                  }
                } catch (e) {
                  console.error(e);
                  toast.error("Approve $ORES failed!");
                }
              }}
            >
              Approve $ORES
            </Button>
          )}
        </footer>
      }

      {disabled && 
        <footer>
          <Button kind="secondary" disabled={true}>Sold Out</Button>
        </footer>
      }
    </div>
  );
}
