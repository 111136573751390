import './bio.scss'

export default function Bio({img, alt, quote}) {
    return (
        <div className='dashboard-bio'>
            <div className='grid-middle'>
                <div className='col-4_md-12'>
                    <img height="400" src={img} alt={alt}/> 
                </div>

                <div className='col-8_md-12'>
                    <blockquote>
                        <span>"</span>
                        {quote}
                    </blockquote>
                </div>
            </div>
        </div>
    )
}