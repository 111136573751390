import {createSlice} from "@reduxjs/toolkit";
import multicall from "../../utils/multicall";
import MnAv2ABI from "../../config/abis/MnAv2.json";
import {abi as EggIncubatorABI} from "../../config/abis/EggIncubator.json";
import {abi as Protoss} from "../../config/abis/Protoss.json";
import {getIncubatorAddress, getMnAv2Address, getProtossAddress,} from "../../utils/addressHelpers";
import moment, {duration} from "moment/moment";
import {getLevelMath} from "../../utils/levelMath";
import {useIncubatorContract} from "../../hooks/useContracts";
import {getIncubatorContract} from "../../utils/contractHelpers";
import {BigNumber, Contract, ethers} from "ethers";

export const IncubationData = {
    user: {
        incubators: [
            {
                unlocked: false,
                occupied: false,
                records: []
            },
            {
                unlocked: false,
                occupied: false,
                records: []
            },
            {
                unlocked: false,
                occupied: false,
                records: []
            },
            {
                unlocked: false,
                occupied: false,
                records: []
            }
        ],
        tokens: []
    }
}

const initialState = {data: IncubationData};

export const incubationSlice = createSlice({
    name: "ProtossIncubator",
    initialState,
    reducers: {
        setMnAv2PublicData: (state, action) => {
            const liveMnAData = action.payload;
            state.data = {...state.data, ...liveMnAData};
        },
        setMnAv2UserData: (state, action) => {
            const userData = action.payload;
            state.data = {...state.data, user: userData};
        },
    },
});

export const {setMnAv2PublicData, setMnAv2UserData} = incubationSlice.actions;

export const fetchMnAv2PublicDataAsync = () => async (dispatch) => {
    const mnAData = await fetchIncubatorData();
    dispatch(setMnAv2PublicData(mnAData));
};

export const fetchIncubatorUserDataAsync = (address, signer) => async (dispatch) => {
    try {
        const userData = await fetchIncubatorUserData(address, signer);
        dispatch(setMnAv2UserData(userData));
    } catch (e) {
        console.error(e)
    }
};

const fetchIncubatorData = async () => {
    const mnAv2Address = getMnAv2Address();
    const calls = [
        {
            address: mnAv2Address,
            name: "minted",
        },
    ];

    const [minted] = await multicall(MnAv2ABI, calls);
    return {
        ...incubationSlice,
        minted: Number(minted.toString()),
    };
};

const fetchIncubatorUserData = async (address, signer) => {
    console.log("test")
    const incubatorAddress = getIncubatorAddress();

    const mnAv2Address = getMnAv2Address();

    //address = "0xcb4d3cFFE63172b78b7346BF152EB0BC78eF3Bf5"

    //mock user reported account
    //account = "0xA74f86b247Cd1C8790E309B0319e2927df661768"
    const calls = [
        {
            address: mnAv2Address,
            name: "balanceOf",
            params: [address],
        },
    ];
    const [balance] = await multicall(MnAv2ABI, calls);

    const tokenIds = [];
    const tokens = [];
    const balanceInNum = Number(balance.toString());
    const tokenIdCalls = [];
    const tokenRaritiesCalls = [];
    const incubators = []

    if (balanceInNum > 0) {
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIdCalls.push({
                address: mnAv2Address,
                name: "tokenOfOwnerByIndex",
                params: [address, idx],
            });
        }

        const tokenIdsRes = await multicall(MnAv2ABI, tokenIdCalls);
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIds.push(Number(tokenIdsRes[idx].toString()));
            tokenRaritiesCalls.push({
                address: incubatorAddress,
                name: "getRarities",
                params: [7, Number(tokenIdsRes[idx].toString())],
            });
        }

        const tokenRarities = await multicall(EggIncubatorABI, tokenRaritiesCalls);

        //todo: check incubator unlock status
        tokenRarities.forEach((x, index) => {
            tokens.push({
                id: Number(tokenIds[index]),
                rarities: x
            })
        })
    }

    const contract = new Contract(getIncubatorAddress(), EggIncubatorABI, signer)

    const incubatorUnlocks = []
    for (let i = 0; i < 4; i++) {
        incubatorUnlocks.push({
            id: i + 1,
            unlocked: await contract.isIncubatorUnlocked(i)
        })
    }

    // console.log(signer)
    const protossContract = new Contract(getProtossAddress(), Protoss, signer)

    const remainingDurationRecords = await contract.getAllRecordDurations()

    const metadata = async (id) => {
        return await protossContract.encodeMetadata(id)
    }

    const getEndTime = (endTime) => {
        const current = moment(Date.now());
        const end = moment(endTime * 1000)
        return end.isValid() ? current.isAfter(end) ? null : end.toLocaleString() : null;
    }

    const recordMetadata = []
    for (const x of remainingDurationRecords) {
        let protossId = await contract.getProtossIdFromRecord(x.recordId)
       // if(Number(protossId) !== 0)
       //     console.log((await metadata(protossId)))
        recordMetadata.push({
            recordId: x.recordId,
            eggType: x.eggType,
            protossId: Number(protossId),
            metadata: Number(protossId) !== 0 ? JSON.parse(await metadata(protossId)).image
                : "",
            endTime: ""
        })
    }

    incubatorUnlocks.forEach((record, index) => {
        let records = remainingDurationRecords ? remainingDurationRecords.filter((s) => s.eggType === index).map((s) => {
            let record = recordMetadata.find((x) => x.recordId === s.recordId)
            record.endTime = getEndTime(s.remainingDuration)
            return record
        }) : []
        incubators.push({
            unlocked: incubatorUnlocks[index]?.unlocked ?? false,
            records: records
        })
    })
    return {
        incubators,
        tokens
    };
};
export default incubationSlice.reducer;
