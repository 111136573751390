export const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc",
};

export const NETWORK = {
  RINKEBY: 4,
  MATIC: 137,
  LOCAL: 31337
};
