import { useMnAv2 } from "../../../state/hooks";
import "./swap-status.scss";

export default function MintStatus() {
  const mnAv2 = useMnAv2();

  // STATUS BAR PROGRESS
  const progress = (mnAv2.minted / mnAv2.maxTokens) * 100;

  return (
    <div className="mint-status">
      <h3>V1-V2 Percentage Swapped </h3>

      <table>
        <thead>
          <tr>
            <th>GEN-0/1</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="mint-status-total">
                <div
                  className="mint-status-progress"
                  style={{ width: `${progress.toFixed()}%` }}
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="grid">
        <div className="col-4_md-12">
          <p>
            <strong></strong>
          </p>

          <p>
            SWAPPED: <strong>{`${mnAv2.minted} / ${mnAv2.maxTokens}`}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
