import { createSlice } from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import { DEFAULT_CHAIN_ID } from "../../config";
import { Contracts } from "../../config/contracts";
import multicall from "../../utils/multicall";
import FireEggAbi from "../../config/abis/FireEgg.json";
import { getFireEggAddress } from "../../utils/addressHelpers";

export const FireEgg = {
  MAX_MINT: 0,
  totalMinted: 0,
  mintCost: 0,
  user: {
    claimed: false,
    balance: 0,
    tokenIds: [],
  },
};

const initialState = { data: FireEgg };
export const fireeggSlice = createSlice({
  name: "FireEgg",
  initialState,
  reducers: {
    setFireEggPublicData: (state, action) => {
      const liveFireEggData = action.payload;
      state.data = { ...state.data, ...liveFireEggData };
    },
    setFireEggUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setFireEggPublicData, setFireEggUserData } =
  fireeggSlice.actions;

export const fetchFireEggPublicDataAsync = () => async (dispatch) => {
  const fireeggData = await fetchFireEgg();
  dispatch(setFireEggPublicData(fireeggData));
};

export const fetchFireEggUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchFireEggUser(account);
  dispatch(setFireEggUserData(userData));
};

const fetchFireEgg = async () => {
  const fireeggAddress = Contracts.fireegg[DEFAULT_CHAIN_ID];
  const calls = [
    {
      address: fireeggAddress,
      name: "MAX_MINT",
    },
    {
      address: fireeggAddress,
      name: "totalMinted",
    },
    {
      address: fireeggAddress,
      name: "mintCost",
    },
  ];

  const [maxMint, totalMinted, mintCost] = await multicall(FireEggAbi, calls);

  return {
    ...FireEgg,
    MAX_MINT: new BigNumber(maxMint.toString()).toJSON(),
    totalMinted: new BigNumber(totalMinted.toString()).toJSON(),
    mintCost: new BigNumber(mintCost.toString()).toJSON(),
  };
};

const fetchFireEggUser = async (account) => {
  const fireeggAddress = getFireEggAddress();

  const calls = [
    {
      address: fireeggAddress,
      name: "claimed",
      params: [account],
    },
    {
      address: fireeggAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [claimed, balance] = await multicall(FireEggAbi, calls);

  let tokenIds = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: fireeggAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(FireEggAbi, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
    }
  }

  return {
    claimed: claimed[0],
    balance: Number(balance.toString()),
    tokenIds: tokenIds,
  };
};
export default fireeggSlice.reducer;
