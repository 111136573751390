import "./stake-card.scss"
import Marine from "../../../images/characters/default.png";
import StatsCard from "../stats-card";
import {numberWithCommas} from "../../../helpers/numbers";
import Symbols from "../../../images/backgrounds/bridge.gif";
import Button from "../../button";

import Ores from "../../../images/characters/ores.png";
import ConfirmUnstake from "../stake-card/stake-confirm-overlay";
import {useState} from "react";
import {useAccountTokenBalance, useTokenBalance} from "../../../hooks/useTokenBalance";
import {DEFAULT_CHAIN_ID} from "../../../config";
import {
    getKlayeAddress,
    getMnAAddress,
    getORESAddress,
    getORESV2Address,
    getPoisonEggAddress, getStakingPoolv3Address
} from "../../../utils/addressHelpers";
import {useAllowance, useIsApprovedForAll} from "../../../hooks/useAllowance";
import BigNumber from "bignumber.js";
import {useApprove} from "../../../hooks/useApprove";
import {useERC20} from "../../../hooks/useContracts";
import {useWeb3Context} from "../../../hooks/web3Context";
import {toast} from "react-toastify";

export default function StakingStatsCard(
    {
        stakedMarines,
        stakedAliens,
        claimableKlaye,
        unclaimedReserves,
        reservesBalance = 0,
        unclaimedKlaye = 0,
        dailyProduction,
        onClaim,
        onUnlock,
        needsUpdated = true
    }
) {
    const [pendingClaimTx, setPendingClaimTx] = useState(false);

    const klayeBalance = useAccountTokenBalance(1);
    const formattedKlayeBalance = klayeBalance
        ? `${klayeBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $KLAYE`
        : `0.000 $KLAYE`;

    const oreBalance = useTokenBalance(getORESV2Address());
    const formattedOreBalance = oreBalance
        ? `${oreBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $ORE`
        : `0.000 $ORE`;

    const dailyProd = dailyProduction()
    const dailyProductionFormatted = dailyProd
        ? `${dailyProd.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $KLAYE`
        : `0.000 $KLAYE`;

    return (
        <div className="stake-stats-card">
            <h3 style={{backgroundImage: `url("${Symbols}")`}}>Staking Stats</h3>
            <div className="grid-middle">
                <div className="col-12_md-12">
                    {needsUpdated
                        ?
                        <ConfirmUnstake onUnlock={async () => {
                            try {
                                if (needsUpdated === false) return;
                                setPendingClaimTx(true);
                                let status = await onUnlock();
                                setPendingClaimTx(false);
                            } catch (e) {
                                console.error(e);
                                setPendingClaimTx(false);
                            }
                        }
                        }
                        />
                        : <>
                            <table>
                                <thead>
                                <tr>
                                    <th>Claimable Reserves</th>
                                    <th className="ores">{unclaimedReserves}</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <figure>
                                            Total Reserves: {reservesBalance} <span className="ore-text">$ORES</span>
                                        </figure>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <figure>
                                            <Button
                                                disabled={unclaimedReserves === "0.000 $ORE"}
                                                onClick={() => {
                                                    onClaim()
                                                }}
                                                kind={"highlight"}
                                            >
                                                Claim Reserves {unclaimedReserves}
                                            </Button>
                                        </figure>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table>
                                <thead>
                                <tr>
                                    <th>Token Balances</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                            <tr style={{alignItems: "center"}}>
                                                <td colSpan={2}>
                                                    $ORE: <span className={"ore-text"}>{formattedOreBalance}</span>
                                                </td>
                                                <td colSpan={2}>
                                                    $KLAYE: <span className={"ore-text"}>{formattedKlayeBalance}</span>
                                                </td>
                                                <td colSpan={2}>
                                                    Unclaimed $KLAYE: <span className={"ore-text"}>{unclaimedKlaye}</span>
                                                </td>
                                                <td colSpan={2}>
                                                    $KLAYE/day: <span className={"ore-text"}>{dailyProductionFormatted}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            </div>
        </div>

    );
}
