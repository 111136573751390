import Header from "../components/dashboard/header";
import Marine from  "../images/characters/berserk.png";
import MarineBanner from "../images/backgrounds/symbols.png";
import Stars from "../images/backgrounds/ep2-stars.png";
import Alien from "../images/characters/alien-wings.png";
import Eggs from "../images/backgrounds/egg.png";
import StakeCard from "../components/dashboard/stake-card";

// IMPORTS
import { useMnAv2User } from "../state/hooks";
import {
  getMnAv2Address, getORESAddress, getORESV2Address,
  getStakingPoolv2Address, getStakingPoolv3Address,
} from "../utils/addressHelpers";
import {
  useERC20,
  useMnAv2Contract,
  useStakingPoolv2Contract, useStakingPoolv3Contract,
} from "../hooks/useContracts";
import { toast } from "react-toastify";
import {useAllowance, useIsApprovedForAll} from "../hooks/useAllowance";
import {useApprove, useApproveForAll} from "../hooks/useApprove";
import { useMnAStake } from "../hooks/useMnAStake";
import { useKlayeClaim } from "../hooks/useKlayeClaim";
import {useWeb3Context} from "../hooks/web3Context";
import StakingStatsCard from "../components/dashboard/staking-stats-card";
import {formatNumber} from "../helpers";
import {useOreReserves} from "../hooks/claimReserves";
import {getLevelMath} from "../utils/levelMath";
import {useState} from "react";
import BigNumber from "bignumber.js";
import Button from "../components/button";
import {useAccount} from "wagmi";

export default function Bootcamp(props) {
  const { address } = useAccount();

  // claim action

  const MnAContract = useMnAv2Contract();

  // MnA
  const mnAv2User = useMnAv2User();
  const stakedMarines = mnAv2User.stakedTokens.filter(
    (stakedToken) => stakedToken.isMarine
  );
  const unstakedMarines = mnAv2User.tokens.filter(
    (unstakedToken) => unstakedToken.isMarine
  );

  const stakedAliens = mnAv2User.stakedTokens.filter(
    (stakedToken) => !stakedToken.isMarine
  );

  const dailyProduction = () => {
    let total = 0;
    if(stakedMarines && stakedMarines.length > 0)
      stakedMarines.forEach((s) => {
        total += getLevelMath(s.level).klayePerDay
      });
    return total;
  }

  const stakedTokenIds = mnAv2User.stakedTokenIds;

  const needsUpdated = false;

  const unclaimedReserves = mnAv2User.unclaimedReserves;
  const reserveBalance = mnAv2User.reservesBalance

  const unclaimedReservesFormatted = unclaimedReserves ? `${formatNumber(unclaimedReserves, 3)} $ORE`
      : `0.000 $ORE`;

  const reservesFormatted = reserveBalance ? `${formatNumber(reserveBalance, 3)}` : `0.000`;

  const unstakedAliens = mnAv2User.tokens.filter((token) => !token.isMarine);

  // MnAGameCR

  const unclaimedKlayeForMarines = mnAv2User.marineRewards;

  const unclaimedKlayeForMarinesFormatted = unclaimedKlayeForMarines
    ? `${unclaimedKlayeForMarines.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })} $KLAYE`
    : `0.000 $KLAYE`;

  const unclaimedKlayeForAliens = mnAv2User.alienRewards;
  const unclaimedKlayeForAliensFormatted = unclaimedKlayeForAliens
    ? `${unclaimedKlayeForAliens.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })} $KLAYE`
    : `0.000 $KLAYE`;

  const unclaimedKlayeCombination = unclaimedKlayeForMarines && unclaimedKlayeForAliens
      ? `${(unclaimedKlayeForMarines + unclaimedKlayeForAliens).toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })} $KLAYE`
      : `0.000 $KLAYE`;

  // Staking
  const stakingPoolContract = useStakingPoolv2Contract();
  const stakingPoolContractV3 = useStakingPoolv3Contract();

  const isApprovedForAll = useIsApprovedForAll(
    getMnAv2Address(),
    getStakingPoolv3Address()
  );

  const { onApprovalForAll } = useApproveForAll(
    MnAContract,
    getStakingPoolv3Address()
  );

  const { onMnAStake } = useMnAStake(stakingPoolContractV3, address);
  const { onKlayeClaim } = useKlayeClaim(stakingPoolContractV3);
  const { onClaimOreReserves } = useOreReserves(stakingPoolContractV3);

  // CALLBACK METHODS FOR BUTTONS
  const claimOres = async (ids) => {
    try {
      const status = await onKlayeClaim(ids, false);
      if (status === 1) {
        toast.success(`Claimed $KLAYE successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Claimed $KLAYEs failed!`);
    }
  };

  const claimOreReserves = async () => {
    try {
      console.log("staked: ", stakedTokenIds)
      const status = await onClaimOreReserves(address, stakedTokenIds);
      if (status === 1) {
        toast.success(`Claimed $ORE reserves successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Claiming $ORE reserves failed!`);
    }
  };

  const stakeMarines = async (ids) => {
    try {
      const status = await onMnAStake(ids);
      if (status === 1) {
        toast.success(`Staked ${ids} successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Staked ${ids} failed!`);
    }
  };

  const unlockStaking = async () => {
    try {
      toast.success(`This feature has been removed!`);
    } catch (e) {
      console.error(e);
      toast.error(`Could not unlock staking!`);
    }
  };

  const unstakeMarines = async (ids) => {
    try {
      const status = await onKlayeClaim(ids, true);
      if (status === 1) {
        toast.success(`Unstaked ${ids} successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Unstake3d ${ids} failed!`);
    }
  };


  const [approveConfirmed, setApproveConfirmed] = useState(false);
  const [pendingApproveTx, setPendingApproveTx] = useState(false);

  const oreAllowance = useAllowance(
      getORESV2Address(),
      getStakingPoolv3Address()
  );
  const isOreApproved =
      address &&
      oreAllowance &&
      new BigNumber(oreAllowance.toString()).isGreaterThan(0);

  const oresContract = useERC20(getORESV2Address());
  const { onApprove: onApproveForOre } = useApprove(
      oresContract,
      getStakingPoolv3Address()
  );

  const approveOre = async () => {
    try {
      setPendingApproveTx(true)
      const status = await onApproveForOre();
      if (status === 1) {
        toast.success("Approved $ORES successfully!");
        setApproveConfirmed(true);
        setPendingApproveTx(false)
      }
    } catch (e) {
      console.error(e);
      toast.error("Approve $ORES failed!");
    }
  }

  return (
    <div>
      <div className="l-space-bottom-4">
        <Header title="Bootcamp [Staking]" background={Stars} />
      </div>

      <div className="grid">
        {!isOreApproved ?
            <Button
              onClick={async () => await approveOre()}
              kind={pendingApproveTx ? "secondary" : "highlight"}
            >
            Approve $ORE
            </Button>
            :
            <>
              <div className="col-12_md-12">
                {/* MARINES MINTING */}
                <StakingStatsCard
                    stakedMarines={stakedMarines.length}
                    stakedAliens={stakedAliens.length}
                    unclaimedReserves={unclaimedReservesFormatted}
                    reservesBalance={reservesFormatted}
                    needsUpdated={needsUpdated}
                    unclaimedKlaye={unclaimedKlayeCombination}
                    dailyProduction={dailyProduction}
                    onClaim={claimOreReserves}
                    onUnlock={unlockStaking}/>
              </div>
              <div className="col-6_md-12">
                {/* MARINES MINTING */}
                <StakeCard
                    background={MarineBanner}
                    title="Marine Staking"
                    img={Marine}
                    isApprovedForAll={isApprovedForAll}
                    onApprovalForAll={onApprovalForAll}
                    staked={stakedMarines}
                    unstaked={unstakedMarines}
                    isMarines={true}
                    onClaim={claimOres}
                    onUnstake={unstakeMarines}
                    onStake={stakeMarines}
                    onUnlock={unlockStaking}
                    needsUpdated={needsUpdated}
                    unclaimed={unclaimedKlayeForMarinesFormatted}
                    reservesBalance={reservesFormatted}
                    currency="$KLAYE"/>
              </div>
              <div className="col-6_md-12">
                {/* ALIENS MINTING */}
                <StakeCard
                    background={Eggs}
                    title="Alien Staking"
                    img={Alien}
                    isApprovedForAll={isApprovedForAll}
                    onApprovalForAll={onApprovalForAll}
                    staked={stakedAliens}
                    unstaked={unstakedAliens}
                    isMarines={false}
                    onClaim={claimOres}
                    onUnstake={unstakeMarines}
                    onStake={stakeMarines}
                    onUnlock={unlockStaking}
                    needsUpdated={needsUpdated}
                    unclaimed={unclaimedKlayeForAliensFormatted}
                    reservesBalance={reservesFormatted}
                    currency="$KLAYE"/>
              </div>
            </>
        }
      </div>
    </div>
  );
}
