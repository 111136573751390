import React, {cloneElement, useMemo, useState} from "react";
import {
    Placement,
    offset,
    flip,
    shift,
    autoUpdate,
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    useId,
    useClick,
    FloatingFocusManager,
    FloatingPortal, useFocus, useHover
} from "@floating-ui/react-dom-interactions";
import {mergeRefs} from "react-merge-refs";
import {autoPlacement, inline} from "@floating-ui/react-dom";
import "./incubator-hover.scss";

interface Props {
    render: (data: {
        close: () => void;
        labelId: string;
        descriptionId: string;
    }) => React.ReactNode;
    placement?: Placement;
    children: JSX.Element;
}

export const IncubatorHoverContext = ({children, render, placement}: Props) => {
    const [open, setOpen] = useState(false);

    const {x, y, reference, floating, strategy, context} = useFloating({
        open,
        onOpenChange: setOpen,
        middleware: [shift(), offset()],
        placement,
        whileElementsMounted: autoUpdate
    });

    const id = useId();
    const labelId = `${id}-label`;
    const descriptionId = `${id}-description`;

    const {getReferenceProps, getFloatingProps} = useInteractions([
        useHover(context),
        useFocus(context),
        useRole(context, {role: "tooltip"}),
        useDismiss(context)
    ]);

    const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [
        reference,
        children
    ]);

    return (
        <>

            {cloneElement(
                children,
                getReferenceProps({ref, ...children.props})
            )}
            {open && (
                <FloatingFocusManager context={context}>
                    <div
                        {...getFloatingProps({
                            className: "Popover",
                            ref: floating,
                            style: {
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0
                            },
                            "aria-labelledby": labelId,
                            "aria-describedby": descriptionId
                        })}
                    >
                        {render({
                            labelId,
                            descriptionId,
                            close: () => {
                                setOpen(false);
                            }
                        })}
                    </div>
                </FloatingFocusManager>
            )}
        </>
    );
};