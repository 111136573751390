import Spidox from "../../images/characters/spidox.png";

export default function SpidoxItem({ tokenIds = [] }) {
  return (
    <div className="crates">
      <h3 className="subtitle">Claimed Spidox ({tokenIds.length} total)</h3>
      <div className={`grid`}>
        {tokenIds.map((id) => (
          <div key={id} className="col-3_md-12">
            <div className="crate">
              <div className="crate-inner">
                <img src={Spidox} alt={`Spidox #${id}`} />
                <h3>Spidox-{id}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
