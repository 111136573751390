import {createSlice} from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import {DEFAULT_CHAIN_ID} from "../../config";
import {Contracts} from "../../config/contracts";
import multicall from "../../utils/multicall";
import {abi as OreReimbursementPool} from "../../config/abis/OreReimbursementPool.json";
import {getOreReimbursementAddress} from "../../utils/addressHelpers";
import ore_reimbursement from "../../config/ore_reimburse.json";
import BalanceTree from "../../helpers/merkle/balance-tree";
import {BigNumber as bn} from "@ethersproject/bignumber/lib/bignumber";
import {useClaimOreReimbursement} from "../../hooks/useOreReimbursementPool";
import {useCallback} from "react";
import {useOreReimbursementContract} from "../../hooks/useContracts";
import {ethers} from "ethers";

export const OreReimbursementUser = {
    user: {
        claimed: false,
        balance: 0,
        lastClaim: 0,
    },
};

const initialState = {data: OreReimbursementUser};
export const oreReimbursementSlice = createSlice({
    name: "OreReimbursement",
    initialState,
    reducers: {
        setOreReimbursementUserData: (state, action) => {
            const userData = action.payload;
            state.data = {...state.data, user: userData};
        },
    },
});

export const {setOreReimbursementUserData} =
    oreReimbursementSlice.actions;

export const fetchOreReimbursementDataAsync = (contract, account) => async (dispatch) => {
    const userData = await fetchOreReimbursementUser(contract, account);
   // console.log(userData)
    dispatch(setOreReimbursementUserData(userData));
};

const fetchOreReimbursementUser = async (contract, account) => {
    if(!account) return;
    //account = "0x73235C91293a5120594192d9cc2e4fFF517Fb3C2"
    const accounts = ore_reimbursement.map(o => o);
    const tree = new BalanceTree(ore_reimbursement.map((d) => {
        return {account: d.account, amount: bn.from(Math.round(d.total_ores).toString())}
    }));

    const valid_account = accounts.find(o => o.account === account.toLowerCase())
    if (!valid_account) return
    const index = accounts.indexOf(valid_account)
    const amount = bn.from(Math.round(valid_account.total_ores))
    let proof

    let claimableBalance = 0
    let lastClaimTime = 0
    let remainingBalance = 0
    try {
        proof = tree.getProof(index, account, amount)
        claimableBalance = await contract.calculateClaimable(index, account, amount, proof)
        lastClaimTime = await contract.lastClaimTime(index, account, amount, proof)
        const rem = await contract.remainingBalance(index, account, amount, proof)
        remainingBalance = Number(ethers.utils.formatEther(rem))
    } catch (e) {
        console.error(e)
        return {
            claimableBalance: 0,
            lastClaimTime: 0,
            remainingBalance: 0
        };
    }
    return {
        index,
        amount: amount.toNumber(),
        proof,
        claimableBalance: bn.from(claimableBalance).toNumber(),
        lastClaimTime: bn.from(lastClaimTime).toNumber(),
        remainingBalance: remainingBalance,
        totalClaimed: bn.from(amount - remainingBalance).toNumber(),
        perDay: remainingBalance > 0 ? amount / 10 : 0
    };
};
export default oreReimbursementSlice.reducer;
