import Header from "../components/dashboard/header";
import KlayeToken from "../images/characters/ores.png";
import Stars from "../images/backgrounds/ep2-stars.png";
import KlayeVaultCard from "../components/dashboard/klaye-vault";

export default function Vault() {
  return (
    <div>
      <Header title="$ORE CLAIM" background={Stars} />

      <div className="grid-equalHeight l-pad-top-5">
        <div className="col-12_md-12">
          <KlayeVaultCard title="$ORE Claim" img={KlayeToken} />
        </div>
      </div>
    </div>
  );
}
