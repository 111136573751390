import { useCallback } from "react";

export const usePurchase = (eggContract) => {
  const handlePurchase = useCallback(async () => {
    try {
      const tx = await eggContract.mint(1);
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [eggContract]);

  return { onPurchase: handlePurchase };
};
