const lpPools = [
  {
    name: "WETH",
    lpAddress: {
      4: "",
      137: "0xf6422b997c7f54d1c6a6e103bcb1499eea0a7046", // WETH-USDT
    },
    tokenSymbol: "WETH",
    tokenAddress: {
      4: "",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    tokenDecimal: 18,
    quoteTokenSymbol: "USDT",
    quoteTokenAddress: {
      4: "",
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
    quoteTokenDecimal: 6,
  },
  {
    name: "$ORES",
    lpAddress: {
      4: "",
      137: "0xC3252C1ada2334CaB9d0bDbD47132680d1F6B410", // $ORES-WETH
    },
    tokenSymbol: "$ORES",
    tokenAddress: {
      4: "",
      137: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
    },
    tokenDecimal: 18,
    quoteTokenSymbol: "WETH",
    quoteTokenAddress: {
      4: "",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    quoteTokenDecimal: 18,
  },
  {
    name: "$KLAYE",
    lpAddress: {
      4: "",
      137: "0xd3af04D7C976083c1288759D0A91a4573922232c", // $KLAYE-WETH
    },
    tokenSymbol: "$KLAYE",
    tokenAddress: {
      4: "",
      137: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
    },
    tokenDecimal: 18,
    quoteTokenSymbol: "WETH",
    quoteTokenAddress: {
      4: "",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    quoteTokenDecimal: 18,
  },
  {
    name: "$ORES-WETH",
    lpAddress: {
      4: "",
      137: "0xC3252C1ada2334CaB9d0bDbD47132680d1F6B410", // $ORES-WETH
    },
    tokenSymbol: "$ORES",
    tokenAddress: {
      4: "",
      137: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
    },
    tokenDecimal: 18,
    quoteTokenSymbol: "WETH",
    quoteTokenAddress: {
      4: "",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    quoteTokenDecimal: 18,
  },
  {
    name: "$KLAYE-WETH",
    lpAddress: {
      4: "",
      137: "0xd3af04D7C976083c1288759D0A91a4573922232c", // $KLAYE-WETH
    },
    tokenSymbol: "$KLAYE",
    tokenAddress: {
      4: "",
      137: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
    },
    tokenDecimal: 18,
    quoteTokenSymbol: "WETH",
    quoteTokenAddress: {
      4: "",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
    quoteTokenDecimal: 18,
  },
];

export default lpPools;
