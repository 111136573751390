import { useEffect, useState } from "react";
import { BIG_ZERO } from "../utils/bigNumber";
import { getAllowance, getIsApprovedForAll } from "../utils/callHelpers";
import useRefresh from "./useRefresh";
import {useWeb3Context} from "./web3Context";
import {useAccount} from "wagmi";

export const useAllowance = (tokenAddress, spender) => {
  const { address } = useAccount();
  const [allowance, setAllowance] = useState(BIG_ZERO);
  useEffect(() => {
    if (address) {
      const fetchAllowance = async (_owner, _spender, _tokenAddress) => {
        const res = await getAllowance(_owner, _spender, _tokenAddress);
        setAllowance(res);
      };

      fetchAllowance(address, spender, tokenAddress);
    }
  }, [address, tokenAddress, spender]);

  return allowance;
};

export const useIsApprovedForAll = (nftTokenAddress, operator) => {
  const { address } = useAccount();
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    if (address) {
      const fetchIsApprovedForAll = async () => {
        const res = await getIsApprovedForAll(
          address,
          operator,
          nftTokenAddress
        );
        setIsApprovedForAll(res[0]);
      };

      fetchIsApprovedForAll();
    }
  }, [nftTokenAddress, address, operator, slowRefresh]);

  return isApprovedForAll;
};
