import './header.scss'

export default function Header({title, background}) {
    const hasBackground = background ? "has-background" : ""; 
    let bgImg = background || ''; 

    return (
        <header className={`dashboard-header ${hasBackground}`} style={{backgroundImage: `url(${bgImg})`}}>
            <h3 className='title'>{title}</h3>
        </header>
    )
}