import { useState, useCallback } from "react";
import "./mint.scss";
import Button from "../button";
import Alien from "../../images/characters/alienoo.png";
import Marine from "../../images/characters/default.png";

import { toast } from "react-toastify";
import { useMnAContract } from "../../hooks/useContracts";
import { useFounderPassUser } from "../../state/hooks";
import { useGenClaim } from "../../hooks/useGenClaim";
import { getOwnerOfMnAToken } from "../../utils/callHelpers";
import { getMnAAddress } from "../../utils/addressHelpers";
import {useWeb3Context} from "../../hooks/web3Context";
import {useAccount} from "wagmi";

export default function Mint() {
  const { address, connected, connect } = useAccount();
  const mnAContract = useMnAContract();
  const [claimable, setClaimable] = useState(false);
  const { onGenClaim } = useGenClaim(mnAContract);

  // state variables
  const [pendingGenClaimTx, setPendingGenClaimTx] = useState(false);
  const founderPassUser = useFounderPassUser();
  const quantity = founderPassUser.balance ?? 0;

  if (founderPassUser.tokenIds.length > 0) {
    getOwnerOfMnAToken(getMnAAddress(), founderPassUser.tokenIds[0])
      .then((owner) => {
        if (owner.toLowerCase() === getMnAAddress().toLowerCase()) {
          setClaimable(true);
        }
      })
      .catch((e) => {});
  }

  const handleGenClaim = useCallback(async () => {
    try {
      setPendingGenClaimTx(true);
      const mintStatus = await onGenClaim();
      if (mintStatus === 1) {
        toast.success(`Claiming success!`);
      }
      setPendingGenClaimTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Claiming failed!");
    }
  }, [onGenClaim]);

  return (
    <div className="mint-wrapper">
      <div className="mint">
        <header>
          <h4>Gen 0 Claim</h4>
          <img className="is-alien" width="24" src={Alien} alt="Alien" />
          <img className="is-marine" width="24" src={Marine} alt="Marine" />
        </header>
        <div className="mint-options">
          <h4>Quantity</h4>

          <div className="mint-quantity">
            {/* <button onClick={decrement}>-</button> */}
            <div className="quantity">{quantity}</div>
            {/* <button onClick={increment}>+</button> */}
          </div>
        </div>

        <footer>
          {connected ? (
            quantity > 0 ? (
              <Button
                kind="primary"
                disabled={pendingGenClaimTx}
                block={true}
                onClick={() => {
                  if (claimable) {
                    handleGenClaim();
                  }
                }}
              >
                {claimable ? "CLAIM" : "Already Claimed"}
              </Button>
            ) : (
              <Button kind="primary" block={true}>
                You don't have FounderPass
              </Button>
            )
          ) : (
            <Button
              kind="secondary"
              block={true}
              onClick={() => {
                connect()
              }}
            >
              CONNECT WALLET
            </Button>
          )}
        </footer>
      </div>
    </div>
  );
}
