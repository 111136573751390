import { useCallback } from "react";

export const useRescueV1 = (stakingContract) => {
    const handleRescue = useCallback(
        async (tokenIds) => {
            try {
                const tx = await stakingContract.rescue(
                    tokenIds
                );
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [stakingContract]
    );
    return { onRescue: handleRescue };
};
