import { useCallback } from "react";
import { useRewardsContract, useVaultContract } from "./useContracts";
import {BigNumber} from "ethers";
import {getBalanceAmount} from "../utils/formatBalance";
import {formatNumber} from "../helpers";

export const useRewardsWithdraw = () => {
  const rewardsContract = useRewardsContract();
  const handleRewardsWithdraw = useCallback(
    async (amount, precision) => {
      try {
        const tx = await rewardsContract.withdraw(amount.toString());
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [rewardsContract]
  );

  return { onRewardsWithdraw: handleRewardsWithdraw };
};

export const useVaultWithdraw = (address) => {
  const rewardsContract = useVaultContract(address);
  const handleRewardsWithdraw = useCallback(
    async (amount, precision) => {
      try {
        const tx = await rewardsContract.withdraw(amount.toString());
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [rewardsContract]
  );

  return { onRewardsWithdraw: handleRewardsWithdraw };
};
