import { createSlice } from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import { DEFAULT_CHAIN_ID } from "../../config";
import { Contracts } from "../../config/contracts";
import multicall from "../../utils/multicall";
import WaterEggAbi from "../../config/abis/WaterEgg.json";
import { getWaterEggAddress } from "../../utils/addressHelpers";

export const WaterEgg = {
  MAX_MINT: 0,
  totalMinted: 0,
  mintCost: 0,
  user: {
    claimed: false,
    balance: 0,
    tokenIds: [],
  },
};

const initialState = { data: WaterEgg };
export const watereggSlice = createSlice({
  name: "WaterEgg",
  initialState,
  reducers: {
    setWaterEggPublicData: (state, action) => {
      const liveWaterEggData = action.payload;
      state.data = { ...state.data, ...liveWaterEggData };
    },
    setWaterEggUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setWaterEggPublicData, setWaterEggUserData } =
  watereggSlice.actions;

export const fetchWaterEggPublicDataAsync = () => async (dispatch) => {
  const watereggData = await fetchWaterEgg();
  dispatch(setWaterEggPublicData(watereggData));
};

export const fetchWaterEggUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchWaterEggUser(account);
  dispatch(setWaterEggUserData(userData));
};

const fetchWaterEgg = async () => {
  const watereggAddress = Contracts.wateregg[DEFAULT_CHAIN_ID];
  const calls = [
    {
      address: watereggAddress,
      name: "MAX_MINT",
    },
    {
      address: watereggAddress,
      name: "totalMinted",
    },
    {
      address: watereggAddress,
      name: "mintCost",
    },
  ];

  const [maxMint, totalMinted, mintCost] = await multicall(WaterEggAbi, calls);

  return {
    ...WaterEgg,
    MAX_MINT: new BigNumber(maxMint.toString()).toJSON(),
    totalMinted: new BigNumber(totalMinted.toString()).toJSON(),
    mintCost: new BigNumber(mintCost.toString()).toJSON(),
  };
};

const fetchWaterEggUser = async (account) => {
  const watereggAddress = getWaterEggAddress();

  const calls = [
    {
      address: watereggAddress,
      name: "claimed",
      params: [account],
    },
    {
      address: watereggAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [claimed, balance] = await multicall(WaterEggAbi, calls);

  let tokenIds = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: watereggAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(WaterEggAbi, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
    }
  }

  return {
    claimed: claimed[0],
    balance: Number(balance.toString()),
    tokenIds: tokenIds,
  };
};
export default watereggSlice.reducer;
