import MerkleTree from "./merkleTree";
import { utils } from "ethers";

export default class AccountTree {
  constructor(accounts) {
    this.tree = new MerkleTree(
      accounts.map((account) => {
        return AccountTree.toNode(account);
      })
    );
  }

  static verifyProof(account, proof, root) {
    let pair = AccountTree.toNode(account);
    for (const item of proof) {
      pair = MerkleTree.combinedHash(pair, item);
    }

    return pair.equals(root);
  }

  // keccak256(abi.encode(account))
  static toNode(account) {
    return Buffer.from(
      utils.solidityKeccak256(["address"], [account]).substr(2),
      "hex"
    );
  }

  getHexRoot() {
    return this.tree.getHexRoot();
  }

  // returns the hex bytes32 values of the proof
  getProof(account) {
    return this.tree.getHexProof(AccountTree.toNode(account));
  }
}
