import { useCallback } from "react";
import { useRewardsContract, useVaultContract } from "./useContracts";

export const useRewardsClaim = () => {
  const rewardsContract = useRewardsContract();
  const handleRewardsClaim = useCallback(async () => {
    try {
      const tx = await rewardsContract.getReward();
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [rewardsContract]);

  return { onRewardsClaim: handleRewardsClaim };
};

export const useVaultClaim = (address) => {
  const rewardsContract = useVaultContract(address);
  const handleRewardsClaim = useCallback(async () => {
    try {
      const tx = await rewardsContract.getReward();
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [rewardsContract]);

  return { onRewardsClaim: handleRewardsClaim };
};
