import { useMemo } from "react";
import {
  getERC20Contract,
  getERC721Contract,
  getFounderPassContract,
  getMnAContract,
  getMnAGameCRContract,
  getStakingContract,
  getSpidoxContract,
  getRewardsContract,
  getWaterEggContract,
  getFireEggContract,
  getDarknessEggContract,
  getPoisonEggContract,
  getMnAv2Contract,
  getStakingv2Contract,
  getVaultContract,
  getStakingv3Contract,
  getOreReimbursementContract,
  getAccountTokenStorageContract, getIncubatorContract, getProtossContract, getBossBattleContract
} from "../utils/contractHelpers";
import {useWeb3Context} from "./web3Context";
import {useAccount, useProvider, useSigner} from "wagmi";

export const useERC20 = (address) => {
  const { data: signer } = useSigner();
  return useMemo(
    () => getERC20Contract(address, signer),
    [address, signer]
  );
};

export const useERC721 = (address) => {
  const { data: signer } = useSigner();
  return useMemo(
    () => getERC721Contract(address, signer),
    [address, signer]
  );
};
export const useFounderPassContract = () => {
  const { data: signer } = useSigner();
  return useMemo(
    () => getFounderPassContract(signer),
    [signer]
  );
};

export const useMnAContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getMnAContract(signer), [signer]);
};

export const useMnAv2Contract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getMnAv2Contract(signer), [signer]);
};

export const useMnAGameContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getMnAGameCRContract(signer), [signer]);
};

export const useStakingPoolContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getStakingContract(signer), [signer]);
};

export const useStakingPoolv2Contract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getStakingv2Contract(signer), [signer]);
};

export const useStakingPoolv3Contract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getStakingv3Contract(signer), [signer]);
};


export const useSpidoxContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getSpidoxContract(signer), [signer]);
};

export const useRewardsContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getRewardsContract(signer), [signer]);
};

export const useVaultContract = (address) => {
  const { data: signer } = useSigner();
  return useMemo(
    () => getVaultContract(signer, address),
    [signer, address]
  );
};

export const useWaterEggContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getWaterEggContract(signer), [signer]);
};

export const useFireEggContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getFireEggContract(signer), [signer]);
};

export const useDarknessEggContract = () => {
  const { data: signer } = useSigner();
  return useMemo(
    () => getDarknessEggContract(signer),
    [signer]
  );
};

export const usePoisonEggContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getPoisonEggContract(signer), [signer]);
};

export const useOreReimbursementContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getOreReimbursementContract(signer), [signer]);
};

export const useAccountTokenStorageContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getAccountTokenStorageContract(signer), [signer]);
};

export const useIncubatorContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getIncubatorContract(signer), [signer]);
};

export const useBossBattleContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getBossBattleContract(signer), [signer]);
};

export const useProtossContract = () => {
  const { data: signer } = useSigner();
  return useMemo(() => getProtossContract(signer), [signer]);
};