import {createSlice} from "@reduxjs/toolkit";
import multicall from "../../utils/multicall";
import MnAv2ABI from "../../config/abis/MnAv2.json";
import {abi as EggIncubatorABI} from "../../config/abis/EggIncubator.json";
import {abi as BossBattlesABI} from "../../config/abis/BossBattles.json";
import {abi as Protoss} from "../../config/abis/Protoss.json";
import {
    getBossBattleAddress, getIncubatorAddress,
    getMnAv2Address,
    getProtossAddress,
} from "../../utils/addressHelpers";
import moment, {duration} from "moment/moment";
import {BigNumber, Contract, ethers} from "ethers";

export const BattleRecordData = {
    user: {
        activeRecords: [],
        previousRecords: [],
        tokens: [],
        ownedProtoss: []
    }
}

const initialState = {data: BattleRecordData};

export const bossBattleSlice = createSlice({
    name: "BossBattles",
    initialState,
    reducers: {
        setMnAv2PublicData: (state, action) => {
            const liveMnAData = action.payload;
            state.data = {...state.data, ...liveMnAData};
        },
        setMnAv2UserData: (state, action) => {
            const userData = action.payload;
            state.data = {...state.data, user: userData};
        },
    },
});

export const {setMnAv2PublicData, setMnAv2UserData} = bossBattleSlice.actions;

export const fetchBossBattleUserDataAsync = (address, signer) => async (dispatch) => {
    try {
        const userData = await fetchIncubatorUserData(address, signer);
        dispatch(setMnAv2UserData(userData));
    } catch (e) {
        console.error(e)
    }
};

const fetchIncubatorData = async () => {
    const mnAv2Address = getMnAv2Address();
    const calls = [
        {
            address: mnAv2Address,
            name: "minted",
        },
    ];

    const [minted] = await multicall(MnAv2ABI, calls);
    return {
        ...bossBattleSlice,
        minted: Number(minted.toString()),
    };
};

const fetchIncubatorUserData = async (address, signer) => {
    const incubatorAddress = getBossBattleAddress();
    const mnAv2Address = getMnAv2Address()
    //address = "0xcb4d3cFFE63172b78b7346BF152EB0BC78eF3Bf5"

    //mock user reported account
    //account = "0xA74f86b247Cd1C8790E309B0319e2927df661768"
    const calls = [
        {
            address: mnAv2Address,
            name: "balanceOf",
            params: [address],
        },
    ];
    const [balance] = await multicall(MnAv2ABI, calls);

    const tokenIds = [];
    const tokens = [];
    const balanceInNum = Number(balance.toString());
    const tokenIdCalls = [];
    const tokenRaritiesCalls = [];
    const incubators = []
    const activeRecords = []
    const previousRecords = []

    if (balanceInNum > 0) {
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIdCalls.push({
                address: mnAv2Address,
                name: "tokenOfOwnerByIndex",
                params: [address, idx],
            });
        }

        const tokenIdsRes = await multicall(MnAv2ABI, tokenIdCalls);
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIds.push(Number(tokenIdsRes[idx].toString()));
            tokenRaritiesCalls.push({
                address: getIncubatorAddress(),
                name: "getRarities",
                params: [7, Number(tokenIdsRes[idx].toString())],
            });
        }

        const tokenRarities = await multicall(EggIncubatorABI, tokenRaritiesCalls);

        //todo: check incubator unlock status
        tokenRarities.forEach((x, index) => {
            tokens.push({
                id: Number(tokenIds[index]),
                rarities: x
            })
        })
    }

    const contract = new Contract(getBossBattleAddress(), BossBattlesABI, signer)

    const protossContract = new Contract(getProtossAddress(), Protoss, signer)

    const activeBattleRecords = await contract.getActiveBattleRecords()
    const previousBattleRecords = await contract.getPreviousBattleRecords()
    const protossIds = await protossContract.tokensOfOwner(address)

    const metadata = async (id) => {
        return await protossContract.encodeMetadata(id)
    }

    const getEndTime = (endTime) => {
        const current = moment(Date.now());
        const end = moment(endTime * 1000)
        return end.isValid() ? current.isAfter(end) ? null : end.toLocaleString() : null;
    }

    const protoss = []
    if(protossIds && protossIds.length > 0) {
        for (const id of protossIds) {
            protoss.push({
                id: Number(id),
                metadata: JSON.parse(await metadata(id)).image
            });
        }
    }
    return {
        activeRecords: activeBattleRecords.map((record) => {
            return {
                id: Number(record.recordId),
                boss: Number(record.boss),
                damage: Number(record.damage),
                endTimestamp: getEndTime(record.endTimestamp),
                marines: record.marines.map((marine) => { return { id: Number(marine) }}),
                aliens: record.aliens.map((alien) => { return { id: Number(alien) }}),
                protoss: record.protoss.map((protoss) => { return { id: Number(protoss) }}),
            }
        }),
        previousRecords,
        ownedProtoss: protoss
    };
};
export default bossBattleSlice.reducer;
