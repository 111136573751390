import * as React from "react"
import {Link} from "react-router-dom"
import "./button.scss"

export default function Button({children, kind, to, href, space, onClick, block, disabled}) {
    const layout = block ? "is-block" : "";
    const margin = space ? `btn-space-${space}` : "";
    const isDisabled = disabled ? "disabled" : ""; 


    if(to) {
        return <Link to={to} className={`button ${isDisabled} ${kind} ${layout} ${margin}`}>{children}</Link>
    }
    else if(href) {
        return <a href={href} className={`button ${isDisabled} ${kind} ${layout} ${margin}`}>{children}</a>
    }
    else {
        return <button onClick={onClick} className={`button ${isDisabled} ${kind} ${layout} ${margin}`}>{children}</button>
    }
}