export const getLevelMath = (level) => {
  const dayTimeInSecs = 86400;
  let maxRewardDuration = 0;
  if (level < 11) {
    maxRewardDuration = 5 * dayTimeInSecs;
  } else if (level < 31) {
    maxRewardDuration = 4 * dayTimeInSecs;
  } else if (level < 45) {
    maxRewardDuration = 3 * dayTimeInSecs;
  } else if (level < 69) {
    maxRewardDuration = 2 * dayTimeInSecs;
  } else {
    maxRewardDuration = 9999 * dayTimeInSecs;
  }

  const oresToken = ((1500 * level + 500) * 31) / 100;
  const klayeToSkip = 0.15 * level + 0.2;
  const coolDownTime = (level + 1) * 3600;
  const klayePerDay = (0.25 * level + 1) + (((0.25 * level + 1) * 7) / 100);

  return { oresToken, klayeToSkip, coolDownTime, maxRewardDuration, klayePerDay };
};
