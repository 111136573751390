import "./infocard.scss"

export default function InfoCard({title, description, img, video, spacing}) {
    let symbol = <img src={img} alt={title} />; 

    if(video) {
      symbol = <video height="172"><source src={video} type="video/mp4"/></video>; 
    }

    return (
      <div className={`info-card ${spacing}`}>
          {symbol}
          <div className="info-card-content">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
      </div>
    )
  }