import "./vault.scss";
import { useCallback, useState } from "react";
import Button from "../../button";
import {
  useRewardPoolFromName,
  useRewardPoolUser,
  useTokenPrice,
} from "../../../state/hooks";
import BigNumber from "bignumber.js";
import { useApprove } from "../../../hooks/useApprove";
import { useERC20 } from "../../../hooks/useContracts";
import { DEFAULT_CHAIN_ID, YEAR_DURATION } from "../../../config";
import { toast } from "react-toastify";
import {
  formatNumber,
  getBalanceAmount,
  getBalanceAmount2,
  getBalanceNumber,
  getDecimalAmount,
} from "../../../utils/formatBalance";
import { useTokenBalance } from "../../../hooks/useTokenBalance";
import { useRewardsClaim } from "../../../hooks/useRewardsClaim";
import { useRewardsDeposit } from "../../../hooks/useRewardsDeposit";
import { useRewardsWithdraw } from "../../../hooks/useRewardsWithdraw";
import {useWeb3Context} from "../../../hooks/web3Context";
import {useAccount, useConnect} from "wagmi";
import {useConnectModal} from "@rainbow-me/rainbowkit";

export default function VaultCard({ img, title }) {
  const [depositToggle, setDepositToggle] = useState(false);
  const [depositField, setDepositField] = useState(0);
  const [withdrawToggle, setWithdrawToggle] = useState(false);
  const [withdrawField, setWithdrawField] = useState(0);
  const [approveConfirmed, setApproveConfirmed] = useState(false);
  const [pendingApproveTx, setPendingApproveTx] = useState(false);
  const [pendingClaimTx, setPendingClaimTx] = useState(false);
  const [pendingDepositTx, setPendingDepositTx] = useState(false);

  const [endsIn, setEndsIn] = useState("");
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { allowance, stakedBalance, earned, lastDeposited, rewardPerToken } =
    useRewardPoolUser("$ORES-WETH");

  const pool = useRewardPoolFromName("$ORES-WETH");
  const lockEndTime = lastDeposited + pool.lockingDuration;
  let isLocked = lockEndTime > Math.floor(Date.now()) / 1000;
  setInterval(() => {
    const curTime = Math.floor(Date.now() / 1000);
    let leftTimeInSecs = lockEndTime - curTime;
    if (leftTimeInSecs > 0) {
      let days = Math.floor(leftTimeInSecs / 86400);
      leftTimeInSecs %= 86400;
      let hours = Math.floor(leftTimeInSecs / 3600);
      leftTimeInSecs %= 3600;
      let minutes = Math.floor(leftTimeInSecs / 60);
      let seconds = leftTimeInSecs % 60;

      let daysFormatted = String(days).padStart(2, "0");
      let hoursFormatted = String(hours).padStart(2, "0");
      let minutesFormatted = String(minutes).padStart(2, "0");
      let secondsFormatted = String(seconds).padStart(2, "0");
      setEndsIn(
        `${daysFormatted}Days ${hoursFormatted}Hours ${minutesFormatted}Mins ${secondsFormatted}Seconds`
      );
    }
  }, 1000);
  const stakeTokenBalance = useTokenBalance(pool.stakeToken[DEFAULT_CHAIN_ID]);
  const tokenPrice = useTokenPrice(
    pool.stakeTokenSymbol,
    true,
    DEFAULT_CHAIN_ID
  );
  const rewardTokenPrice = useTokenPrice(
    pool.rewardTokenSymbol,
    false,
    DEFAULT_CHAIN_ID
  );

  const isApproved =
    (address &&
      allowance &&
      new BigNumber(allowance.toString()).isGreaterThan(0)) ||
    approveConfirmed;

  const earnedInNum = getBalanceNumber(earned, pool.rewardTokenDecimal);
  const userEarned = earned
    ? `${earnedInNum.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
    : "-";

  const stakedInNum = getBalanceNumber(stakedBalance, pool.stakeTokenDecimal);
  const stakedInUsd = stakedInNum * tokenPrice;
  const stakedInUsdFormatted = `${stakedInUsd.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })}`;
  const userStaked = stakedBalance
    ? `${stakedInNum.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
    : "-";

  const rewardRate = pool.rewardRate;
  const rewardRateInNum = getBalanceNumber(rewardRate, pool.rewardTokenDecimal);

  const rewardPerTokenInNum = getBalanceNumber(rewardPerToken, pool.rewardTokenDecimal);
  const dailyEarnedInNum = rewardPerTokenInNum / rewardRateInNum / 1000;
  const userDailyEarned = dailyEarnedInNum && stakedInNum
      ? `${(dailyEarnedInNum * stakedInNum).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
      : "-";
  const dailyPerToken = dailyEarnedInNum
      ? `${dailyEarnedInNum.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
      : "-";

  const totalSupply = pool.totalSupply;
  const totalSupplyInNum = getBalanceNumber(
    totalSupply,
    pool.stakeTokenDecimal
  );
  const totalStaked = totalSupply
    ? `${totalSupplyInNum.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}`
    : "-";

  const totalStakedInUsd = totalSupplyInNum * tokenPrice;
  const totalStakedInUsdFormatted = `${totalStakedInUsd.toLocaleString(
    undefined,
    {
      maximumFractionDigits: 2,
    }
  )}`;

  const depositFee = pool.depositFee
    ? (Number(pool.depositFee.toString()) * 100) / 10000
    : 0;

  let aprInNum =
    totalStakedInUsd > 0
      ? (rewardTokenPrice * rewardRateInNum * YEAR_DURATION * 100) /
        totalStakedInUsd
      : 0;

  const fakeFactor = 55;
  aprInNum = aprInNum * fakeFactor;
  const aprInNumFormatted = aprInNum.toLocaleString(undefined, {
    maximumFractionDigits: 3,
  });

  const stakeTokenContract = useERC20(pool.stakeToken[DEFAULT_CHAIN_ID]);
  const { onApprove } = useApprove(
    stakeTokenContract,
    pool.address[DEFAULT_CHAIN_ID]
  );

  const handleApprove = useCallback(async () => {
    try {
      setPendingApproveTx(true);
      const approveStatus = await onApprove();
      if (approveStatus === 1) {
        setApproveConfirmed(true);
        toast.success("Approved successfully!");
      }
      setPendingApproveTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Approve failed");
    }
  }, [onApprove]);

  const { onRewardsClaim } = useRewardsClaim();
  const { onRewardsDeposit } = useRewardsDeposit();
  const { onRewardsWithdraw } = useRewardsWithdraw();
  const handleClaim = useCallback(async () => {
    try {
      setPendingClaimTx(true);
      const status = await onRewardsClaim();
      if (status === 1) {
        toast.success("Claimed successfully!");
      }
      setPendingClaimTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Claim failed");
    }
  }, [onRewardsClaim]);

  const handleDeposit = useCallback(
    async (amount) => {
      try {
        setPendingDepositTx(true);
        const status = await onRewardsDeposit(amount);
        if (status === 1) {
          toast.success("Deposited successfully!");
        }
        setPendingDepositTx(false);
      } catch (e) {
        console.error(e);
        toast.error("Deposit failed");
      }
    },
    [onRewardsDeposit]
  );

  const handleWithdraw = useCallback(
    async (amount) => {
      try {
        const status = await onRewardsWithdraw(amount, pool.stakeTokenDecimal);
        if (status === 1) {
          toast.success("Withdrawn successfully!");
        }
      } catch (e) {
        console.error(e);
        toast.error("Withdraw failed");
      }
    },
    [onRewardsWithdraw]
  );

  const availableForDesposit = stakeTokenBalance;
  const availableForDepositFormatted = stakeTokenBalance.toLocaleString(
    undefined,
    {
      maximumFractionDigits: 2,
    }
  );
  const availableForWithdraw = stakedInNum;
  const availableForWithdrawFormatted = stakedInNum.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });

  // HIDE OR SHOW THE DESPOSIT SCREEN
  const toggleDepositScreen = () => {
    setDepositToggle(!depositToggle);
  };

  // SET THE DEPOSIT TO MAX AMOUNT
  const setDepositMax = () => {
    setDepositField(getBalanceAmount2(availableForDesposit));
  };

  // INPUT CHANGE FOR DEPOSIT FIELD
  const handleDepositField = (event) => {
    event.persist();
    setDepositField(event.target.value);
  };

  // ------------------------------------ //

  // HIDE OR SHOW THE WITHDRAW SCREEN
  const toggleWithdrawScreen = () => {
    setWithdrawToggle(!withdrawToggle);
  };

  // SET THE WITHDRAW TO MAX AMOUNT
  const setWithdrawMax = () => {
    setWithdrawField(availableForWithdraw);
  };

  // INPUT CHANGE FOR WITHDRAW FIELD
  const handleWithdrawField = (event) => {
    event.persist();
    setWithdrawField(event.target.value);
  };

  return (
    <div className="vault-card">
      <div className="grid">
        <div className="col-9_md-12">
          <h3>{title}</h3>
          {<h4>Unlocked in {endsIn}</h4>}
        </div>
        <div className="col-3_md-12 l-right">
          <a
            className="vault-lp-button"
            target="_blank"
            rel="noreferrer"
            href="https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xE47a7eBF8eC907854CCabead79a5E720a329Bd31"
          >
            Add Liquidity
          </a>
        </div>
      </div>

      <figure>
        <img alt={title} src={img} />
      </figure>

      <h4>Earned</h4>
      <div className="l-flex">
        <div className="l-pad-right-3">
          <h5>${userEarned}</h5>
        </div>
        <div>
          <Button
            disabled={pendingClaimTx}
            kind="secondary"
            onClick={() => {
              handleClaim();
            }}
          >
            Collect
          </Button>
        </div>
      </div>

      <h4>Daily <span style={{color:"red"}}>(Rough estimate)</span> </h4>
      <div className="l-flex">
        <div className="l-pad-right-3">
          <h5>{userDailyEarned} $ORES</h5>
        </div>
      </div>

      <h4>Daily/Token <span style={{color:"red"}}>(Rough estimate)</span> </h4>
      <div className="l-flex">
        <div className="l-pad-right-3">
          <h5>{dailyPerToken} $ORES</h5>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>APY</th>
            <th>Your Stake</th>
            <th>Total Staked</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{aprInNumFormatted}%</td>
            <td>
              {userStaked} {pool.stakeTokenSymbol} ({stakedInUsdFormatted} USD)
            </td>
            <td>
              {totalStaked} {pool.stakeTokenSymbol} ({totalStakedInUsdFormatted}{" "}
              USD)
            </td>
          </tr>
        </tbody>
      </table>

      <footer className="grid">
        {isApproved && (
          <>
            <div className="col-6_md-12">
              <Button
                disabled={pendingDepositTx}
                block={true}
                kind={pendingDepositTx ? "secondary" : "highlight"}
                onClick={!pendingDepositTx ? toggleDepositScreen : null}
              >
                Deposit
              </Button>
            </div>
            <div className="col-6_md-12">
              <Button
                disabled={isLocked}
                block={true}
                kind={isLocked ? "secondary" : "highlight"}
                onClick={!isLocked ? toggleWithdrawScreen : null}
              >
                Withdraw
              </Button>
            </div>
          </>
        )}

        {!isConnected ? (
          <div className="col-12">
            <Button block={true} kind="highlight" onClick={() => { openConnectModal() }}>
              Unlock Wallet
            </Button>
          </div>
        ) : isApproved ? null : (
          <div className="col-12">
            <Button
              disabled={pendingApproveTx}
              block={true}
              kind="highlight"
              onClick={() => {
                handleApprove();
              }}
            >
              Approve
            </Button>
          </div>
        )}
      </footer>

      {/* DEPOSIT MODAL */}
      {depositToggle && (
        <div className="vault-card-overlay">
          <span
            onClick={toggleDepositScreen}
            className="material-icons close-button"
          >
            close
          </span>
          <h3>Deposit {pool.stakeTokenSymbol}</h3>

          <figure>
            <img alt={title} src={img} />
          </figure>

          <div className="valut-card-field">
            <input
              type="number"
              value={depositField}
              onChange={handleDepositField}
            />
            <button onClick={setDepositMax}>Max</button>
          </div>
          <div className="grid">
            <div className="col-6_md-12">
              <h4>
                Available Balance:{" "}
                <strong>{availableForDepositFormatted}</strong>
              </h4>
            </div>
            <div className="col-6_md-12 l-right">
              <h4 className="vault-tax">{depositFee}% Staking fee</h4>
            </div>
          </div>

          <footer>
            <Button
              block={true}
              kind="highlight"
              onClick={() => {
                if (depositField > availableForDesposit) {
                  setDepositField(0);
                } else {
                  const tokenAmountInBigNum = getDecimalAmount(
                    new BigNumber(depositField),
                    pool.stakeTokenDecimal
                  );
                  handleDeposit(tokenAmountInBigNum.toString());
                }
              }}
            >
              Confirm
            </Button>
          </footer>
        </div>
      )}

      {/* WITHDRAW MODAL */}
      {withdrawToggle && (
        <div className="vault-card-overlay">
          <span
            onClick={toggleWithdrawScreen}
            className="material-icons close-button"
          >
            close
          </span>
          <h3>Withdraw {pool.stakeTokenSymbol}</h3>

          <figure>
            <img alt={title} src={img} />
          </figure>

          <div className="valut-card-field">
            <input
              type="number"
              value={withdrawField}
              onChange={handleWithdrawField}
            />
            <button onClick={setWithdrawMax}>Max</button>
          </div>
          <h4>
            Available Balance: <strong>{availableForWithdrawFormatted}</strong>
          </h4>

          <footer>
            <Button
              block={true}
              kind="highlight"
              onClick={() => {
                if (withdrawField > availableForWithdraw) {
                  setWithdrawField(0);
                } else {
                  const tokenAmountInBigNum = getDecimalAmount(
                      new BigNumber(withdrawField),
                      pool.stakeTokenDecimal
                  );
                  handleWithdraw(tokenAmountInBigNum);
                }
              }}
            >
              Confirm
            </Button>
          </footer>
        </div>
      )}
    </div>
  );
}
