import { useCallback } from "react";

export const useSpidoxClaim = (spidoxContract) => {
  const handleSpidoxClaim = useCallback(
    async (numberOfTokens, merkleProof) => {
      try {
        const tx = await spidoxContract.claim(numberOfTokens, merkleProof);
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [spidoxContract]
  );

  return { onSpidoxClaim: handleSpidoxClaim };
};
