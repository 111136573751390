import {useCallback} from "react";

export const useEnlistInBattle = (bossBattleContract, protossContract) => {
  const handleEnlist = useCallback(async (bossId, marines, aliens, protoss) => {
    try {
      let tx = await bossBattleContract.enlist(bossId, marines, aliens, protoss);
      let receipt = await tx.wait();
      let status = receipt.status;
      return status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [bossBattleContract]);
  const handleFinalizeBattle = useCallback(async (battleId) => {
    try {
      let tx = await bossBattleContract.finalizeBattle(battleId);
      let receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [bossBattleContract]);
  return { onEnlist: handleEnlist, onFinalize: handleFinalizeBattle };
};
