import { Contracts } from "../config/contracts";
import { CHAIN_CONFIG, DEFAULT_CHAIN_ID } from "../config";

export const getWETHAddress = () => {
  return Contracts.weth[DEFAULT_CHAIN_ID];
};

export const getFounderPassAddress = () => {
  return Contracts.founderPass[DEFAULT_CHAIN_ID];
};

export const getMulticallAddress = () => {
  return Contracts.multicall[DEFAULT_CHAIN_ID];
};

export const getMnAAddress = () => {
  return Contracts.mnA[DEFAULT_CHAIN_ID];
};

export const getMnAv2Address = () => {
  return Contracts.mnAv2[DEFAULT_CHAIN_ID];
};

export const getMnAGameAddress = () => {
  return Contracts.mnAGameCR[DEFAULT_CHAIN_ID];
};

export const getStakingPoolAddress = () => {
  return Contracts.stakingPool[DEFAULT_CHAIN_ID];
};

export const getStakingPoolv2Address = () => {
  return Contracts.stakingPoolv2[DEFAULT_CHAIN_ID];
};

export const getStakingPoolv3Address = () => {
  return Contracts.stakingPoolv2[DEFAULT_CHAIN_ID];
};

export const getORESAddress = () => {
  return Contracts.ores[DEFAULT_CHAIN_ID];
};

export const getORESV2Address = () => {
  return Contracts.oresV2[DEFAULT_CHAIN_ID];
};

export const getKlayeAddress = () => {
  return Contracts.klaye[DEFAULT_CHAIN_ID];
};

export const getOreReimbursementAddress = () => {
  return Contracts.oreReimbursement[DEFAULT_CHAIN_ID];
};

export const getAccountTokenStorageAddress = () => {
  return Contracts.accountTokenStorage[DEFAULT_CHAIN_ID];
};

export const getCalculatorAddress = () => {
  return Contracts.calculator[DEFAULT_CHAIN_ID];
};

export const getSubgraphUrl = () => {
  return CHAIN_CONFIG[DEFAULT_CHAIN_ID].subgraph;
};
export const getSpidoxAddress = () => {
  return Contracts.spidox[DEFAULT_CHAIN_ID];
};

export const getRewardsAddress = () => {
  return Contracts.rewards[DEFAULT_CHAIN_ID];
};

export const getWaterEggAddress = () => {
  return Contracts.wateregg[DEFAULT_CHAIN_ID];
};

export const getFireEggAddress = () => {
  return Contracts.fireegg[DEFAULT_CHAIN_ID];
};

export const getDarknessEggAddress = () => {
  return Contracts.darknessegg[DEFAULT_CHAIN_ID];
};

export const getPoisonEggAddress = () => {
  return Contracts.poisonegg[DEFAULT_CHAIN_ID];
};

export const getIncubatorAddress = () => {
  return Contracts.incubator[DEFAULT_CHAIN_ID];
};

export const getBossBattleAddress = () => {
  return Contracts.bossBattles[DEFAULT_CHAIN_ID];
};

export const getProtossAddress = () => {
  return Contracts.protoss[DEFAULT_CHAIN_ID];
};
