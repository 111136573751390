export const localStorageKey = "accountStatus";
export const BASE_EXPLORER_URL = "https://polygonscan.com/";
export const DEFAULT_CHAIN_ID = 137;

export const DEFAULT_GAS_LIMIT = 200000;
export const DEFAULT_GAS_PRICE = 5;
export const IS_PRESALE_STAGE = true;
export const ORES_TOTAL_SUPPLY = 4000000000;
export const ORES_PREMINT = 1000000000;
export const ORES_FOR_EPOCH1 = 2000000000;
export const ORES_FOR_EPOCH2 = 1000000000;
export const GUARDING_DURATION = 0; // 2 days
export const COOLDOWN = 86400 * 2; // 2 days
export const YEAR_DURATION = 31536000; // 365days in seconds

export const CHAIN_CONFIG = {
  4: {
    name: "Rinkeby Testnet",
    shortName: "Rinkeby Testnet",
    provider: ["https://rinkeby.infura.io/v3/38f8f85747014e87b48035d84398a97c"],
    subgraph:
      "https://api.thegraph.com/subgraphs/name/cryptodev130/spacegame-runtime-rinkeby-v2",
    image: "",
  },
  137: {
    name: "Matic Mainnet",
    shortName: "Matic",
    provider: ["https://polygon-rpc.com"],
    subgraph:
      "https://api.thegraph.com/subgraphs/name/cryptodev130/spacegame-runtime-matic-v2",
    image: "",
  },
  31337: {
    name: "Localhost",
    shortName: "Localhost",
    provider: ["http://127.0.0.1:8545"],
    subgraph:
        "https://api.thegraph.com/subgraphs/name/cryptodev130/spacegame-runtime-matic-v2",
    image: "",
  },
};
