import Header from "../components/dashboard/header";
import Stars from "../images/backgrounds/ep2-stars.png";

// IMPORTS
import "./incubator.scss";
import water from "../images/items/egg-blue.png";
import fire from "../images/items/egg-orange.png";
import poison from "../images/items/egg-green.png";
import darkness from "../images/items/egg-purple.png";
import water_incubator from "../images/icons/incubator/3.png";
import fire_incubator from "../images/icons/incubator/6.png";
import poison_incubator from "../images/icons/incubator/4.png";
import darkness_incubator from "../images/icons/incubator/7.png";
import ores from "../images/characters/ores.png";
import marine from "../images/characters/marine.png";
import alien from "../images/characters/alien.png";
import protoss from "../images/characters/protoss.svg";
import Button from "../components/button";
import incubator from "../images/backgrounds/bridge.gif";
import {useState} from "react";
import {IncubatorHoverContext} from "../components/incubator/hover-context";
import {
    useDarknessEggContract,
    useERC20,
    useFireEggContract,
    useIncubatorContract,
    useMnAv2Contract,
    usePoisonEggContract,
    useProtossContract,
    useWaterEggContract
} from "../hooks/useContracts";
import {
    useDarknessEggUser,
    useFireEggUser,
    useIncubatorUser,
    useMnAv2User,
    usePoisonEggUser,
    useWaterEggUser
} from "../state/hooks";
import {numberWithCommas} from "../helpers/numbers";
import numeral from "numeral";
import {useAccount} from "wagmi";
import {useIncubator} from "../hooks/useIncubator";
import {toast} from "react-toastify";
import Countdown from "react-countdown";
import {useAllowance, useIsApprovedForAll} from "../hooks/useAllowance";
import {
    getDarknessEggAddress,
    getFireEggAddress,
    getIncubatorAddress,
    getMnAv2Address,
    getORESV2Address,
    getPoisonEggAddress,
    getWaterEggAddress
} from "../utils/addressHelpers";
import BigNumber from "bignumber.js";
import {useApprove, useApproveForAll} from "../hooks/useApprove";
import {formatNumber} from "../helpers";
import {useAccountTokenBalance, useTokenBalance} from "../hooks/useTokenBalance";

const incubator_metadata = [
    {
        type: "Water",
        unlockCost: 100000,
        marineCount: 1,
        alienCount: 0
    }
]

interface EntitySelection {
    isMarine: boolean,
    id: number | undefined,
    level: number | undefined
}

interface IncubatorSelection {
    primaryId: number | undefined,
    sacrifices: EntitySelection[]
}

interface IncubatorDefinition {
    displayImg: string,
    displayName: string,
    type: string,
    id: number,
    unlockCost: number,
    selector: IncubatorSelection
}

const Incubators: IncubatorDefinition[] = [
    {
        displayImg: water_incubator,
        displayName: "Water",
        type: water,
        id: 0,
        unlockCost: 25000,
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: fire_incubator,
        displayName: "Fire",
        type: fire,
        id: 1,
        unlockCost: 35000,
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: poison_incubator,
        displayName: "Poison",
        type: poison,
        id: 2,
        unlockCost: 100000,
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: darkness_incubator,
        displayName: "Darkness",
        type: darkness,
        id: 3,
        unlockCost: 200000,
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    }
]

export default function Incubator() {
    const [selectedStakedItems, setSelectedStakedItems] = useState([]);
    const [selectEntity, setSelectEntity] = useState(false);

    const [selectedIncubator, setSelectedIncubator] = useState<IncubatorDefinition | undefined>(undefined);

    const [selectMnAIndex, setSelectMnAIndex] = useState(0);
    const [selectMnAType, setSelectMnAType] = useState(0);
    const [useReserves, setUseReserves] = useState(true)
    const [pendingTx, setPendingTx] = useState(false)
    const [update, setUpdate] = useState(false)

    const {address} = useAccount();

    const incubatorUser = useIncubatorUser()

    const waterEggUser = useWaterEggUser()
    const waterEggTokenIds = waterEggUser.tokenIds

    const fireEggUser = useFireEggUser()
    const fireEggTokenIds = fireEggUser.tokenIds

    const poisonEggUser = usePoisonEggUser()
    const poisonEggTokenIds = poisonEggUser.tokenIds

    const darknessEggUser = useDarknessEggUser()
    const darknessEggTokenIds = darknessEggUser.tokenIds

    // claim action

    const MnAContract = useMnAv2Contract();
    const WaterEggContract = useWaterEggContract();
    const FireEggContract = useFireEggContract();
    const PoisonEggContract = usePoisonEggContract();
    const DarknessEggContract = useDarknessEggContract();
    const oresContract = useERC20(getORESV2Address());

    const oreAllowance = useAllowance(
        getORESV2Address(),
        getIncubatorAddress()
    );
    const isOreApproved =
        address &&
        oreAllowance &&
        new BigNumber(oreAllowance.toString()).isGreaterThan(0);
    const {onApprove: onApproveOre} = useApprove(
        oresContract,
        getIncubatorAddress()
    );

    const isMnAApprovedForAll = useIsApprovedForAll(
        getMnAv2Address(),
        getIncubatorAddress()
    );

    const { onApprovalForAll: onMnAApprove } = useApproveForAll(
        MnAContract,
        getIncubatorAddress()
    );

    const isWaterEggApprovedForAll = useIsApprovedForAll(
        getWaterEggAddress(),
        getIncubatorAddress()
    );

    const { onApprovalForAll: onWaterApprove } = useApproveForAll(
        WaterEggContract,
        getIncubatorAddress()
    );

    const isFireEggApprovedForAll = useIsApprovedForAll(
        getFireEggAddress(),
        getIncubatorAddress()
    );

    const { onApprovalForAll: onFireApprove } = useApproveForAll(
        FireEggContract,
        getIncubatorAddress()
    );

    const isPoisonEggApprovedForAll = useIsApprovedForAll(
        getPoisonEggAddress(),
        getIncubatorAddress()
    );

    const { onApprovalForAll: onPoisonApprove } = useApproveForAll(
        PoisonEggContract,
        getIncubatorAddress()
    );

    const isDarknessEggApprovedForAll = useIsApprovedForAll(
        getDarknessEggAddress(),
        getIncubatorAddress()
    );

    const { onApprovalForAll: onDarknessApprove } = useApproveForAll(
        DarknessEggContract,
        getIncubatorAddress()
    );

    // MnA
    const mnAv2User = useMnAv2User();

    const reserveBalance = mnAv2User.reservesBalance
    const reservesFormatted = reserveBalance ? `${formatNumber(reserveBalance, 3)}` : `0.000`;

    function hasEnoughReserves() {
        return !selectedIncubator?.unlockCost ? true : reserveBalance >= selectedIncubator?.unlockCost
    }

    const oreBalance = useTokenBalance(getORESV2Address());
    const formattedOreBalance = oreBalance
        ? `${oreBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $ORE`
        : `0.000 $ORE`;

    const klayeBalance = useAccountTokenBalance(1);
    const formattedKlayeBalance = klayeBalance
        ? `${klayeBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $KLAYE`
        : `0.000 $KLAYE`;

    function hasEnoughOre() {
        return !selectedIncubator?.unlockCost ? true : oreBalance >= selectedIncubator?.unlockCost
    }

    function hasEnoughCombinedOre() {
        return !selectedIncubator?.unlockCost ? true : (reserveBalance + oreBalance) >= selectedIncubator?.unlockCost
    }

    const incubationContract = useIncubatorContract()
    const protossContract = useProtossContract()

    const {onIncubate, onReveal, onSpeedUp} = useIncubator(incubationContract, protossContract)

    function fetchRarityScore(id: number) {
        if (!incubatorUser || !incubatorUser.tokens) return 0;
        let record = incubatorUser.tokens.filter((token: { id: number }) => token.id === id)[0]
        let rarities = record?.rarities
        return rarities ? rarities[0].reduce((a: number, b: number) => a + b) : 0
    }

    function fetchIncubatorData(idx: number) {
        return incubatorUser.incubators[idx]
    }

    const submitIncubation = async () => {
        try {
            setPendingTx(true)
            const marines = selectedIncubator?.selector.sacrifices.filter((s) => s.isMarine).map((s) => s.id)
            const aliens = selectedIncubator?.selector.sacrifices.filter((s) => !s.isMarine).map((s) => s.id)
            let egg;
            if (selectedIncubator?.id === 0) egg = waterEggTokenIds[0]
            else if (selectedIncubator?.id === 1) egg = fireEggTokenIds[0]
            else if (selectedIncubator?.id === 2) egg = poisonEggTokenIds[0]
            else if (selectedIncubator?.id === 3) egg = darknessEggTokenIds[0]

            console.log("Incubation attempt: ", selectedIncubator?.id, egg, selectedIncubator?.selector?.primaryId)

            const status = await onIncubate(selectedIncubator?.id, egg, selectedIncubator?.selector.primaryId, marines, aliens ? aliens : [], useReserves);
            if (status === 1) {
                toast.success(`Successfully submitted incubation!`);
            }
            setPendingTx(false)
            return status
        } catch (e) {
            setPendingTx(false)
            console.error(e);
            toast.error(`Incubation failed!`);
        }
    }

    const submitReveal = async (recordId: number) => {
        try {
            setPendingTx(true)
            const status = await onReveal(recordId);
            if (status === 1) {
                toast.success(`Successfully revealed Protoss!`);
            }
            setPendingTx(false)
        } catch (e) {
            setPendingTx(false)
            console.error(e);
            toast.error(`Failed to reveal Protoss!`);
        }
    }

    const submitSpeedUp = async (recordId: number) => {
        try {
            setPendingTx(true)
            const status = await onSpeedUp(recordId);
            if (status === 1) {
                toast.success(`Successfully sped up hatching Protoss!`);
            }
            setPendingTx(false)
        } catch (e) {
            setPendingTx(false)
            toast.warn(`Failed to speed up hatching Protoss!`);
            console.error(e);
        }
    }

    const stakedMarines = mnAv2User.tokens?.filter(
        (stakedToken: { id: number, isMarine: any; }) => stakedToken.isMarine && stakedToken.id > 6969
    );

    const stakedAliens = mnAv2User.tokens?.filter(
        (stakedToken: { id: number, isMarine: any; }) => !stakedToken.isMarine && stakedToken.id > 6969
    );

    function availableMarines() {
        return stakedMarines && stakedMarines?.filter((x: any) => {
            return !selectedIncubator?.selector.sacrifices.find((s: any) => s.id === x.id)
        })
    }

    function availableAliens() {
        return stakedAliens && stakedAliens?.filter((x: any) => {
            return !selectedIncubator?.selector.sacrifices.find((s: any) => s.id === x.id)
        })
    }

    function isApprovedForIncubator() {
        if (selectedIncubator?.id === 0) return isWaterEggApprovedForAll
        if (selectedIncubator?.id === 1) return isFireEggApprovedForAll
        if (selectedIncubator?.id === 2) return isPoisonEggApprovedForAll
        if (selectedIncubator?.id === 3) return isDarknessEggApprovedForAll
    }

    return (
        <div>
            <div className="l-space-bottom-4">
                <Header title="Incubation" background={Stars}/>
            </div>

            <div className="incubator incubator-bg" style={{backgroundImage: `url(${incubator})`}}>
                <div className="">
                    <div className="grid-middle-align-center">
                        <table>
                            <tbody>
                            <tr>
                                {Incubators.map((incubator, index) => {
                                    let first = index === 0;
                                    let last = index === Incubators.length - 1;
                                    let isMiddle = !first && !last;
                                    return (
                                        <td colSpan={1} key={index}>
                                            <div className={`incubator-button-container`}>
                                                <button
                                                    className={`select-button ${isMiddle ? "incubator-middle-margin" : "incubator-margin"} undecorated`}
                                                    onClick={() => {
                                                        setSelectedIncubator(incubator)
                                                    }}
                                                >
                                                    <IncubatorHoverContext
                                                        render={({close, labelId, descriptionId}) => (
                                                            <>
                                                                <div className={`incubator-button-container-hover`}>
                                                                    <div className="display-box">
                                                                        <img className={"egg-image"}
                                                                             src={incubator?.type}/>
                                                                        {incubator?.selector.sacrifices.map((s, index) => {
                                                                            return (
                                                                                <img key={index} className={"egg-image"}
                                                                                     src={s.isMarine ? marine : alien}/>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )} placement={"top"}>
                                                        <div className="incubator-image"
                                                             style={{
                                                                 backgroundImage: `url(${incubator?.displayImg})`,
                                                                 filter: `${fetchIncubatorData(index)?.unlocked ?? false ? "" : /*fetchIncubatorData(index).remainingDuration ?*/ "grayscale(80%)"}`
                                                             }}
                                                        >
                                                            <div className="display-box">
                                                                <div className="unlock-cost-text">
                                                                    <div
                                                                        className="ore-text">{numberWithCommas(numeral(incubator.unlockCost).format("0"))}</div>
                                                                    <div className="ore-text"><img src={ores}/></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </IncubatorHoverContext>
                                                </button>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="incubator">
                <div className={`incubator-button-container`}>
                    {!selectedIncubator ?
                        <div style={{justifyContent: "center", textAlign: "center"}}>
                            <span className="display-text pos-top">Select an incubator to get started</span>
                        </div>
                        : !selectEntity
                            ? <div style={{textAlign: "center", display: "block"}}>
                                <div style={{textAlign: "center"}}>
                                    <span>The </span>
                                    <span style={{color: "#B9EA2B"}}>{selectedIncubator?.displayName} </span>
                                    <span>incubator </span>
                                    <span
                                        style={{color: "red", textDecoration: "underline"}}>has not been unlocked</span>.
                                    <span> There will be an additional </span>
                                    <span style={{
                                        color: "red",
                                        textDecoration: "underline"
                                    }}> {numberWithCommas(numeral(selectedIncubator?.unlockCost).format("0"))} </span>
                                    <span style={{
                                        color: "#B9EA2B",
                                        textDecoration: "underline",
                                        textDecorationColor: "red"
                                    }}> $ORE</span>
                                    <span> in a </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>one-time</span>
                                    <span> transaction </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>combined</span>
                                    <span> with your sacrifices.</span>
                                </div>
                                < br/>
                                <div style={{textAlign: "center"}}>
                                    <span style={{color: "green"}}>Rarity Score:</span>
                                    <span> This is the recorded rarity of each of your individual assets traits combined. The </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>lower</span>
                                    <span> the number, the </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>better</span>
                                    <span> the assets rarity score is.</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className={`incubator-info-content`} style={{ display: `${selectEntity ? "block" : "flex"}` }}>
                        {!selectEntity
                            ? <button className={`select-button`}>
                                {!selectedIncubator ?
                                    <span className="display-text pos-top" style={{textAlign: "center"}}>Select an incubator to get started</span> : null}
                                <div className={"selection-image top-margin"}
                                     style={{
                                         filter: `${!selectedIncubator ? "grayscale(80%)" : "none"}`,
                                         backgroundImage: `url(${!selectedIncubator ? water : selectedIncubator.type})`
                                     }}/>
                            </button>
                            : null
                        }
                        {!selectEntity ? (
                                selectedIncubator?.selector.sacrifices.map((item: EntitySelection, index) => {
                                    return (
                                        <div key={index} className={"display-box"}>
                                            <button
                                                className={`select-button ${selectedIncubator.selector.primaryId && selectedIncubator.selector.primaryId === item.id ? "primary-selection" : ""}`}
                                                onClick={() => {
                                                    setSelectMnAIndex(index)
                                                    setSelectMnAType(item.isMarine ? 0 : 1)
                                                    setSelectEntity(true)
                                                }}
                                            >
                                                {!item.id ?
                                                    <span
                                                        className="display-text pos-top">Click to select a sacrifice</span> : null}
                                                {!item.id ?
                                                    <div className={"selection-image top-margin"}
                                                         style={{
                                                             filter: `${!item.id ? "grayscale(80%)" : "none"}`,
                                                             backgroundImage: `url(${item.isMarine ? marine : alien})`
                                                         }}/>
                                                    :
                                                    <div className={"selection-image top-margin"}>
                                                        <strong>ID: {item.id}</strong>
                                                        <span>LV. {item.level}</span>
                                                        <div className={"mna-image"}
                                                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/svg/${item.id}.svg)`}}
                                                        />
                                                        {item.id === selectedIncubator?.selector.primaryId
                                                            ? <div
                                                                className="display-text sacrifice">Primary
                                                                Sacrifice</div>
                                                            : null
                                                        }
                                                        <div className="display-text">Rarity
                                                            Score: {fetchRarityScore(item.id)}</div>
                                                    </div>
                                                }
                                            </button>
                                            {!item.id ? null
                                                :
                                                <Button
                                                    onClick={() => {
                                                        selectedIncubator.selector.primaryId = item.id
                                                    }}
                                                    block={true}
                                                    kind={false ? "secondary" : "highlight"}
                                                    to={undefined}
                                                    href={undefined}
                                                    space={undefined}
                                                    disabled={!item.id || selectedIncubator?.selector.primaryId === item.id}
                                                >
                                                    Make Primary
                                                </Button>
                                            }
                                        </div>
                                    )
                                }))
                            : (<>
                                <div className="incubator-button-container">
                                    <div className="grid-middle">
                                        <div className="col-1_md-3 back-selector">
                                            <h4 className="hover-cursor" onClick={() => {
                                                setSelectMnAIndex(0)
                                                setSelectMnAType(0)
                                                setSelectEntity(false)
                                            }}>
                                                {"<< Back"}
                                            </h4>
                                        </div>
                                        <div className="col-10_md-12">
                                            <h4>Select a {selectMnAType === 0 ? "Marine" : "Alien"} to sacrifice</h4>
                                        </div>
                                    </div>
                                    {(selectMnAType === 0 ? availableMarines() : availableAliens()).map((item: any, index: number) => {
                                        return (
                                            <button key={index} className={`select-button`}
                                                    onClick={() => {
                                                        let selection = selectedIncubator?.selector.sacrifices.at(selectMnAIndex);
                                                        if (selection) {
                                                            selection.id = item.id;
                                                            selection.level = item.level;
                                                            selection.isMarine = item.isMarine;
                                                        }
                                                        setSelectMnAIndex(0)
                                                        setSelectEntity(false)
                                                    }}
                                            >
                                                <strong>ID: {item.id}</strong>
                                                <span>LV. {item.level}</span>
                                                <div className={"mna-image"}
                                                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/svg/${item.id}.svg)`}}
                                                />
                                                <div className="display-text">Rarity
                                                    Score: {fetchRarityScore(item.id)}</div>
                                            </button>
                                        )
                                    })
                                    }
                                </div>
                            </>)
                        }
                    </div>
                    { selectedIncubator
                        ?
                    <div style={{justifyContent: "center", textAlign: "center"}}>
                        <br/>
                        <span>Selected </span>
                        <span style={{
                            color: "#B9EA2B",
                            textDecoration: "underline",
                            textDecorationColor: "red"
                        }}> Primary</span>
                        <span> selections are used to </span>
                        <span style={{color: "red", textDecoration: "underline"}}>determine</span>
                        <span> the rarity of your created Protoss.</span>
                        <br/>
                    </div>
                        : null
                    }
                    <div
                        style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "12px"}}>
                        { selectedIncubator
                            ? <>
                                <div style={{justifyContent: "center", textAlign: "center", paddingBottom: "16px"}}>
                                    {useReserves ?
                                        <>
                                            <span>Currently using </span>
                                            <span style={{ color: "#B9EA2B" }}>$ORE</span>
                                            <span> Reserves.</span>
                                            <br/>
                                            <br/>
                                            <span style={{ textDecoration: "underline", color: "red" }}>Using reserve balance will use reserves, then fallback to paying the remaining $ORE with your wallet balance</span>
                                            <br/>
                                            <br/>
                                            <span style={{
                                                color: "#B9EA2B"}}>$ORE</span>
                                            <span> balance: {formattedOreBalance}</span>
                                            <br/>
                                            <span style={{
                                                color: "#B9EA2B"}}>Reserve</span>
                                            <span> balance: {reservesFormatted}</span>
                                        </>
                                        : <div style={{justifyContent: "center", textAlign: "center"}}>
                                            <br/>
                                            <span>You have chosen to use your </span>
                                            <span style={{
                                                color: "#B9EA2B",
                                                textDecoration: "underline",
                                                textDecorationColor: "red"
                                            }}> $ORE</span>
                                            <span> balance, which </span>
                                            <span style={{color: "red", textDecoration: "underline"}}>requires</span>
                                            <span> a token approval.</span>
                                            <br/>
                                            <span>If this was unintentional, select to use reserve balance instead.</span>
                                            <br/>
                                            <span style={{ color: "red"}}> (Select below to change)</span>
                                            <br/>
                                            <br/>
                                            <span style={{
                                                color: "#B9EA2B"}}>$ORE</span>
                                            <span> balance: {formattedOreBalance}</span>
                                            <br/>
                                            <span style={{
                                                color: "#B9EA2B"}}>Reserve</span>
                                            <span> balance: {reservesFormatted}</span>
                                        </div>}
                                </div>
                                {fetchIncubatorData(selectedIncubator.id)?.unlocked ?? false
                                    ? null
                                    : <Button
                                        onClick={() => {
                                            setUseReserves(!useReserves)
                                        }}
                                        kind={"primary"}
                                        to={undefined}
                                        href={undefined}
                                        space={"bottom"}
                                        block={undefined}
                                        disabled={useReserves ? !hasEnoughCombinedOre() : !hasEnoughOre()}
                                    >
                                        {
                                            useReserves ? "Use $ORE" : "Use Reserves"
                                        }
                                    </Button>
                                }
                                { !isOreApproved && (!useReserves || !hasEnoughReserves())
                                    ?
                                    <Button
                                        onClick={async () => {
                                            try {
                                                if(isOreApproved) return;
                                                setPendingTx(true)
                                                let status = await onApproveOre()
                                                if (status === 1) {
                                                    toast.success("Approved $ORE Successfully!");
                                                }
                                                setPendingTx(false)
                                            } catch (e) {
                                                console.error(e);
                                                toast.error("Approval Failed!");
                                                setPendingTx(false)
                                            }
                                        }}
                                        kind={"primary"}
                                        to={undefined}
                                        href={undefined}
                                        space={undefined}
                                        block={undefined}
                                        disabled={!(!isOreApproved && (!useReserves || !hasEnoughReserves())) || pendingTx}
                                    >
                                        Approve $ORE
                                    </Button>
                                    :
                                    isMnAApprovedForAll
                                        ? isApprovedForIncubator()
                                            ? <Button
                                                onClick={async () => {
                                                    let status;
                                                    try {
                                                        if (!selectedIncubator?.selector.primaryId) {
                                                            toast.error("Select a primary sacrifice")
                                                            return;
                                                        }
                                                        setPendingTx(true)
                                                        status = await submitIncubation()
                                                        setPendingTx(false)
                                                    } catch (e) {
                                                        console.error(e);
                                                        toast.error("Approval Failed!");
                                                        setPendingTx(false)
                                                    } finally {
                                                        if(status !== 1) return
                                                        selectedIncubator.selector.sacrifices = selectedIncubator.selector.sacrifices.map((s) => {
                                                            return {
                                                                isMarine: s.isMarine,
                                                                id: undefined,
                                                                level: undefined
                                                            }
                                                        })
                                                        selectedIncubator.selector.primaryId = undefined
                                                    }
                                                }}
                                                kind={"primary"}
                                                to={undefined}
                                                href={undefined}
                                                space={undefined}
                                                block={undefined}
                                                disabled={pendingTx || !selectedIncubator?.selector.primaryId || !selectedIncubator?.selector.sacrifices.every((s) => s.id)}
                                            >
                                                Sacrifice
                                            </Button>
                                        : <Button
                                                onClick={async () => {
                                                    try {
                                                        if(isApprovedForIncubator()) return
                                                        setPendingTx(true)
                                                        let success;
                                                        if (selectedIncubator?.id === 0) success = await onWaterApprove()
                                                        if (selectedIncubator?.id === 1) success = await onFireApprove()
                                                        if (selectedIncubator?.id === 2) success = await onPoisonApprove()
                                                        if (selectedIncubator?.id === 3) success = await onDarknessApprove()
                                                        if(success) {
                                                            toast.success(`Successfully Approved ${selectedIncubator?.displayName} Egg!`)
                                                        }
                                                        setPendingTx(false)
                                                    } catch (e) {
                                                        console.error(e);
                                                        toast.error("Approval Failed!");
                                                        setPendingTx(false)
                                                    }
                                                }}
                                                kind={"primary"}
                                                to={undefined}
                                                href={undefined}
                                                space={undefined}
                                                block={undefined}
                                                disabled={pendingTx || isApprovedForIncubator()}
                                            >
                                                Approve {selectedIncubator.displayName} Egg
                                            </Button>
                                        : <Button
                                            onClick={async () => {
                                                try {
                                                    setPendingTx(true)
                                                    let status = await onMnAApprove()
                                                    if(status === 1) {
                                                        toast.success("MnA Approved!")
                                                    }
                                                    setPendingTx(false)
                                                } catch (e) {
                                                    console.error(e);
                                                    toast.error("Approval Failed!");
                                                    setPendingTx(false)
                                                }
                                            }}
                                            kind={"primary"}
                                            to={undefined}
                                            href={undefined}
                                            space={undefined}
                                            block={undefined}
                                            disabled={pendingTx || isMnAApprovedForAll}
                                        >
                                            Approve Marines and Aliens
                                        </Button>
                                }
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className="incubator">
                <div className={`incubator-button-container`}>
                    {selectedIncubator
                        ? fetchIncubatorData(selectedIncubator?.id)?.records?.length === 0
                            ? null
                            :
                            <div style={{justifyContent: "center", textAlign: "center", paddingBottom: "16px"}}>
                                <span>You can </span>
                                <span style={{
                                    color: "red",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}>speed up</span>
                                <span> the process of hatching your protoss by spending </span>
                                <span style={{
                                    color: "#B9EA2B",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}> $KLAYE</span>
                                <br/>
                                <span>Otherwise wait the duration </span>
                                <span style={{
                                    color: "red",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}>indicated under your protoss</span>
                                <span> and reveal for the </span>
                                <span style={{
                                    color: "red",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}>cost of gas.</span>
                                <br/>
                                <br/>
                                <br/>
                                <span style={{
                                    color: "#B9EA2B",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}>$KLAYE</span>
                                <span> balance: </span>
                                <span style={{
                                    color: "#B9EA2B",
                                    textDecoration: "underline",
                                    textDecorationColor: "red"
                                }}>{formattedKlayeBalance}</span>
                            </div>
                        : null
                    }
                    <div className="incubator-info-content">
                        <div className="incubator-button-container protoss">
                            {selectedIncubator
                                ? fetchIncubatorData(selectedIncubator?.id)?.records?.length === 0
                                    ? <p>No Incubation Records Found</p>
                                    : (fetchIncubatorData(selectedIncubator?.id)?.records?.map((record: any, index: number) => {
                                        return (
                                            <div key={index} style={{padding: "4px"}}>
                                                <button key={index} className={`select-button protoss`}
                                                        onClick={() => {
                                                        }}
                                                >
                                                    <div className={"protoss-image"}
                                                         style={{
                                                             backgroundImage: `url(${record.metadata ? record.metadata : protoss})`,
                                                             filter: `${record.endTime ? "grayscale(80%)" : "none"}`
                                                         }}
                                                    >
                                                        {!record.endTime ?
                                                            <div className="display-text">Id: {record.protossId}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    {!record.endTime ? null : <Countdown date={record.endTime} className={"display-text"}/>}
                                                </button>
                                                {record.protossId ? null :
                                                    <Button
                                                        block={true}
                                                        kind={false ? "secondary" : "highlight"}
                                                        to={undefined}
                                                        href={undefined}
                                                        space={undefined}
                                                        onClick={async () => {
                                                            if((record.endTime && klayeBalance < (selectedIncubator.id + 1) * 1000)) {
                                                                toast.error("Insufficient $KLAYE balance")
                                                                return
                                                            }
                                                            setPendingTx(true)
                                                            record.endTime
                                                                ? await submitSpeedUp(record.recordId)
                                                                : await submitReveal(record.recordId)
                                                            setPendingTx(false)
                                                        }}
                                                        disabled={pendingTx || (record.endTime && klayeBalance < (selectedIncubator.id + 1) * 1000)}
                                                    >

                                                        {record.endTime ? `${numberWithCommas(numeral((selectedIncubator.id + 1) * 1000).format("0.00")) + " $KLAYE"}` : "Reveal"}
                                                    </Button>
                                                }
                                            </div>
                                        )
                                    }))
                                : <p>No Incubation Records Found</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}
