import "./tile.scss";

export default function Tile({ id, isMarine, img, title, rank, level }) {
  return (
    <div className="tile">
      <strong>ID: {id}</strong>
      <span>LV. {level}</span>
      {!isMarine ? <em>R{rank}</em> : null}
      <img src={img} alt={title} />
    </div>
  );
}
