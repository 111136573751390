import * as React from "react";
import { Link } from "react-router-dom";
import "./top-nav.scss";
import Logo from "../../images/logos/logo.png";
import Button from "../button";

export default function TopNav() {
  return (
    <nav className="top-nav">
      <div className="top-nav-inner">
        <h1>
          <Link to="/">
            <img src={Logo} width="86" alt="Space Game" />{" "}
          </Link>
        </h1>
        
        <Button kind="primary" to="/dashboard/">Play Game</Button>
        
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/WGKk3E7J3k"
            >
              Discord
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/spacenftdotgame"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/@spacenftdotgame"
            >
              Blog
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
