import Web3 from "web3";
import getRpcUrl from "./getRpcUrl";

export const getWeb3NoAccount = (chainId) => {
  const RPC_URL = getRpcUrl();
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL, {
    timeout: 10000,
  });
  const web3NoAccount = new Web3(httpProvider);
  return web3NoAccount;
};

export const getWeb3WithArchivedNodeProvider = (chainId) => {
  const RPC_URL = getRpcUrl();
  const archivedHttpProvider = new Web3.providers.HttpProvider(RPC_URL, {
    timeout: 10000,
  });
  return new Web3(archivedHttpProvider);
};

export default getWeb3NoAccount;
