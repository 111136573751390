import Header from "../components/dashboard/header";
import Crystal from "../images/characters/crystal.gif";
import VaultCard from "../components/dashboard/vault-card";

export default function Vault() {
  return (
    <div>
      <Header title="The Vault" />

      <div className="grid l-pad-top-5">
        <div className="col-12_md-12">
          <VaultCard title="$ORES-WETH POOL" img={Crystal} />
        </div>
      </div>
    </div>
  );
}
