import { useCallback } from "react";

export const useOresClaim = (stakingContract) => {
  const handleOresClaim = useCallback(
    async (tokenIds, unstake) => {
      try {
        const tx = await stakingContract.claimManyFromMarinePoolAndAlienPool(
          tokenIds,
          unstake
        );
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [stakingContract]
  );
  return { onOresClaim: handleOresClaim };
};
