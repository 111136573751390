import "./slug.scss"

export default function Slug({title, subtitle, img}) {
    return (
      <div className={`slug`}>
        <img src={img} alt={title} />
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
    )
  }