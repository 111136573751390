import "./roadmap.scss"

export default function RoadmapCard({title, description, img, epoch, blankstate}) {
    if(blankstate) {
        return (
            <div className={`roadmap-card`}>
                <div className="empty-title"></div>
                <figure className="is-empty"></figure>
                <div className="empty-paragraph">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="empty-paragraph">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        )
    }


    return (
      <div className={`roadmap-card`}>
          <h3>{title}</h3>

          <figure>
            <img src={img} alt={title} /> 
            <figcaption><span>{`Epoch ${epoch}`}</span></figcaption>
          </figure>
          
          {description.map((item, key) => <p key={key}>{item}</p>)}
      </div>
    )
  }