import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import multicall from "../../utils/multicall";
import MnAABI from "../../config/abis/MnA.json";
import StakingPoolABI from "../../config/abis/StakingPool.json";
import {
  getMnAAddress,
  getStakingPoolAddress,
  getSubgraphUrl,
} from "../../utils/addressHelpers";
import BigNumber from "bignumber.js";
import { getBalanceNumber } from "../../utils/formatBalance";
import {ethers} from "ethers";

export const MnA = {
  paidTokens: 0,
  maxTokens: 0,
  minted: 0,
  user: {
    balance: 0,
    tokens: [],
    stakedTokens: [],
    marineRewards: 0,
    alienRewards: 0,
  },
};

const initialState = { data: MnA };
export const mnASlice = createSlice({
  name: "MnA",
  initialState,
  reducers: {
    setMnAPublicData: (state, action) => {
      const liveMnAData = action.payload;
      state.data = { ...state.data, ...liveMnAData };
    },
    setMnAUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setMnAPublicData, setMnAUserData } = mnASlice.actions;

export const fetchMnAPublicDataAsync = () => async (dispatch) => {
  const mnAData = await fetchMnA();
  dispatch(setMnAPublicData(mnAData));
};

export const fetchMnAUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchMnAUser(account);
  dispatch(setMnAUserData(userData));
};

const fetchMnA = async () => {
  const mnAAddress = getMnAAddress();
  const calls = [
    {
      address: mnAAddress,
      name: "PAID_TOKENS",
    },
    {
      address: mnAAddress,
      name: "maxTokens",
    },
    {
      address: mnAAddress,
      name: "minted",
    },
  ];

  const [paidTokens, maxTokens, minted] = await multicall(MnAABI, calls);

  return {
    ...MnA,
    paidTokens: Number(paidTokens.toString()),
    maxTokens: Number(maxTokens.toString()),
    minted: Number(minted.toString()),
  };
};

const fetchMnAUser = async (account) => {
  //account = "0xa80Ba0403735D575836bda1BAf661dB7a8CCf1Cb"
  const mnAAddress = getMnAAddress();

  const calls = [
    {
      address: mnAAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [balance] = await multicall(MnAABI, calls);

  const tokenIds = [];
  const tokens = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  const itemTypeCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: mnAAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(MnAABI, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
      itemTypeCalls.push({
        address: mnAAddress,
        name: "getTokenTraits",
        params: [Number(tokenIdsRes[idx].toString())],
      });
    }

    const tokenTypesRes = await multicall(MnAABI, itemTypeCalls);

    for (let idx = 0; idx < balanceInNum; idx++) {
      tokens.push({
        id: Number(tokenIdsRes[idx].toString()),
        isMarine: tokenTypesRes[idx][0][0],
        rank: tokenTypesRes[idx][0][12],
      });
    }
  }
  const res = await axios.post(getSubgraphUrl(), {
    query: `{
      stakes(first:500,where:{account:"${account.toLowerCase()}", status:Staked}) {
        id
        account
        tokenId
        type
        timestamp
      }
    }
    `,
  });

  const stakes = res.data.data.stakes;
  const stakedTokens = [];
  const tokenTraitCalls = stakes.map((stake) => {
    return {
      address: mnAAddress,
      name: "getTokenTraits",
      params: [Number(stake.id)],
    };
  });

  const tokenTraitCallRes = await multicall(MnAABI, tokenTraitCalls);

  const stakingPoolAddress = getStakingPoolAddress();
  const marineRewardCalls = [];
  const alienRewardCalls = [];
  for (let idx = 0; idx < stakes.length; idx++) {
    stakedTokens.push({
      id: Number(stakes[idx].id),
      isMarine: tokenTraitCallRes[idx][0][0],
      rank: tokenTraitCallRes[idx][0][12],
      lastStaked: Number(stakes[idx].timestamp),
    });

    if (tokenTraitCallRes[idx][0][0]) {
      marineRewardCalls.push({
        address: stakingPoolAddress,
        name: "calculateRewards",
        params: [Number(stakes[idx].id)],
      });
    } else {
      alienRewardCalls.push({
        address: stakingPoolAddress,
        name: "calculateRewards",
        params: [Number(stakes[idx].id)],
      });
    }
  }
  const marineRewardCallRes = await multicall(
    StakingPoolABI,
    marineRewardCalls
  );

  let totalMarineReward = ethers.utils.parseEther("0");
  for (let idx = 0; idx < marineRewardCallRes.length; idx++) {
    let reward = ethers.utils.parseEther(marineRewardCallRes[idx].toString());
    reward -= reward * 0.02
    totalMarineReward = totalMarineReward + reward;
  }

  const alientRewardCallRes = await multicall(StakingPoolABI, alienRewardCalls);
  let totalAlienReward = new BigNumber(0);
  for (let idx = 0; idx < alientRewardCallRes.length; idx++) {
    const reward = new BigNumber(alientRewardCallRes[idx].toString());
    totalAlienReward = totalAlienReward.plus(reward);
  }

  return {
    balance: Number(balance.toString()),
    tokens,
    stakedTokens,
    marineRewards: getBalanceNumber(totalMarineReward),
    alienRewards: getBalanceNumber(totalAlienReward),
  };
};
export default mnASlice.reducer;
