import getWeb3 from "./getWeb3";
import MultiCallAbi from "../config/abis/multicall.json";
import { getMulticallAddress } from "./addressHelpers";
import {CHAIN_CONFIG, DEFAULT_CHAIN_ID} from "../config";
import {Interface} from "ethers/lib/utils";

const multicall = async (abi, calls) => {
  const web3 = getWeb3(DEFAULT_CHAIN_ID);
  const multi = new web3.eth.Contract(MultiCallAbi, getMulticallAddress());
  const itf = new Interface(abi);

  const calldata = calls.map((call) => [
    call.address,
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );

  return res;
};

export default multicall;
