import { useCallback } from "react";

export const useOreReserves = (stakingContract) => {
    const handeClaimOreReserves = useCallback(
        async (address, tokenIds) => {
            try {
                const tx = await stakingContract.claimReserves(
                    address,
                    tokenIds
                );
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [stakingContract]
    );
    return { onClaimOreReserves: handeClaimOreReserves };
};
