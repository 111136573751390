import './stats.scss'
import {numberWithCommas} from "../../../helpers/numbers"


export default function StatsCard({
    img, 
    title, 
    heading1, 
    heading2,
    data1, 
    data2,
}) {
    return (
        <div className='stats-card'>
            <figure>
                <img alt={title} src={img}/> 
            </figure>

            <h3>{title}</h3>

            <table>
              <thead>
                <tr>
                  <th>{heading1}</th>
                  <th>{heading2}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{numberWithCommas(data1)}</td>
                  <td>{numberWithCommas(data2)}</td>
                </tr>
              </tbody>
            </table>

        </div>
    )
}