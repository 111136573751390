import Alien from "../../images/characters/alienoo.png";
import Marine from "../../images/characters/default.png";

export default function MnAs({ tokens = [] }) {
  return (
    <div className="crates">
      <h3 className="subtitle">Claimed Marines &amp; Aliens ({tokens.length} total)</h3>
      <div className={`grid`}>
        {tokens.map((token) => (
          <div key={token.id} className="col-3_md-12">
            <div className="crate">
              <div className="crate-inner">
                <img
                  src={token.isMarine ? Marine : Alien}
                  alt={
                    token.isMarine
                      ? `Marine #${token.id}`
                      : `Alien #${token.id}`
                  }
                />
                <h3>
                  {token.isMarine ? "Marine #" : "Alien #"}
                  {token.id}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
