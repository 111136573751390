import "./feature.scss"

export default function FeatureCard({title, description, img, spacing}) {
    return (
      <div className={`feature ${spacing}`}>
          <figure style={{background: `url(${img}) center bottom no-repeat`}}></figure>
          <div className="feature-content">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
      </div>
    )
  }