import { useCallback } from "react";

export const useMnAClaim = (mnAContract) => {
  const handleMnAClaim = useCallback(async () => {
    try {
      const tx = await mnAContract.claim();

      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [mnAContract]);

  return { onMnAClaim: handleMnAClaim };
};
