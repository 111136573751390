import { useCallback } from "react";

export const useMnAResetCoolDown = (mnaContract) => {
  const handleMnAResetCoolDown = useCallback(
    async (tokenIds) => {
      try {
        const tx = await mnaContract.resetCoolDown(tokenIds);
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [mnaContract]
  );

  return { onMnAResetCoolDown: handleMnAResetCoolDown };
};
