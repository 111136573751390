import "./section.scss"

export default function Section({children, background}) {
    return (
      <div className={`section-wrapper ${background}`}>
        <div className={`section ${background}`}>
          <div className="section-inner">
            {children}
          </div>
        </div>
      </div>
    )
  }