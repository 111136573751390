import Section from "../section"
import { Link } from "react-router-dom"
import "./footer.scss"

export default function Footer() {
    return (
        <Section background="bg-eggs">
            <div className="grid l-pad-top-5">
                <div className="col-3_md-12">
                    <h4 className="heading">Social Media</h4>
                    <ul className="text-list">
                        <li>
                            <a href="https://discord.gg/spacegame">Discord</a>
                        </li>
                        <li>
                            <a href="https://twitter.com/spacenftdotgame">Twitter</a>
                        </li>
                    </ul>
                </div>

                <div className="col-3_md-12">
                    <h4 className="heading">Basic Training</h4>
                    <ul className="text-list">
                        <li>
                            <a href="https://medium.com/@spacenftdotgame/space-game-l1-l2-hybrid-100-onchain-nft-game-3ecba7b7c690">Whitepaper</a>
                        </li>
                        <li>
                            <a href="https://medium.com/@spacenftdotgame">Blog</a>
                        </li>
                    </ul>
                </div>

                <div className="col-3_md-12">
                    <h4 className="heading" id="rollout">!Rollout</h4>
                    <ul className="text-list">
                        <li>
                            <Link to="dashboard">Play Game</Link>
                        </li>
                        <li>
                            <a href="#minting">Mint Now!</a>
                        </li>
                        
                    </ul>
                </div>
            </div>

        
            <p className="text-small copyright">Space Game &copy; 2022 All Rights Reserved.</p>
        </Section>
    )
}