import { useCallback } from "react";
import {useOreReimbursementContract} from "./useContracts";

export const useClaimOreReimbursement = () => {
    const oreReimbursementContract = useOreReimbursementContract();
    const handleClaimOreReimbursement = useCallback(
        async (address, index, amount, proof) => {
            try {
                const tx = await oreReimbursementContract.claim(index, address, amount, proof);
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [oreReimbursementContract]
    );

    return { onOreReimbursement: handleClaimOreReimbursement };
};