import { useCallback } from "react";
import moment from "moment";

export const useIncubator = (incubatorContract, protossContract) => {
  const handleIncubate = useCallback(async (eggType, eggTokenId, primaryId, marines, aliens, useReserves) => {
    try {
      let tx = await incubatorContract.incubate(eggType, useReserves, eggTokenId, primaryId, marines, aliens);
      let receipt = await tx.wait();
      let status = receipt.status;
      return status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [incubatorContract]);

  const handleReveal = useCallback(async (recordId) => {
    try {
      let tx = await incubatorContract.hatch(recordId);
      let receipt = await tx.wait();
      let status = receipt.status;
      if(status === 1) {
        const protossId = await incubatorContract.getProtossIdFromRecord(recordId)
        console.log("protoss id: ", protossId)
        if(protossId !== 0) {
          let metadata = await protossContract.encodeMetadata(protossId)
          console.log("metadata: ", metadata.image)
          return metadata
        }
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [incubatorContract]);

  const handleSpeedUp = useCallback(async (recordId) => {
    try {
      let tx = await incubatorContract.payDownTime(recordId);
      let receipt = await tx.wait();
      let status = receipt.status;
      if(status === 1) {
        const protossId = await incubatorContract.getProtossIdFromRecord(recordId)
        console.log("protoss id: ", protossId)
        if(protossId !== 0) {
          let metadata = await protossContract.encodeMetadata(protossId)
          console.log("metadata: ", metadata.image)
          return metadata
        }
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [incubatorContract]);

  return { onIncubate: handleIncubate, onReveal: handleReveal, onSpeedUp: handleSpeedUp };
};
