import {useCallback} from "react";

export const useMnAStake = (stakingContract, account) => {
    const handleMnAStake = useCallback(
        async (tokenIds) => {
            try {
                const tx = await stakingContract.addManyToMarinePoolAndAlienPool(
                    account,
                    tokenIds
                );
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [stakingContract, account]
    );

    const handleUnlock = useCallback(
        async (tokenIds) => {
            try {
                const tx = await stakingContract.populateOwned(
                    account,
                    tokenIds
                );
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [stakingContract, account]
    );

    return {onMnAStake: handleMnAStake, onUnlock: handleUnlock};
};
