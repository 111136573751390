import "../assets/rainbowkit.css";

import { connectorsForWallets, wallet } from "@rainbow-me/rainbowkit";
import { Chain, chain, configureChains, createClient } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

const local: Chain = {
   name: "Localhost",
   network: "localhost",
   id: 31337,
   nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
   },
   rpcUrls: { default: "http://localhost:8545" },
};
export const { chains, provider, webSocketProvider } = configureChains(
   [
      chain.polygon,
      {
         ...local,
         iconUrl:
            "https://chainlist.org/_next/image?url=https%3A%2F%2Fdefillama.com%2Fchain-icons%2Frsz_fantom.jpg&w=32&q=100",
      },
      chain.rinkeby
   ],
   [
      alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
      jsonRpcProvider({ rpc: chain => ({ http: chain.rpcUrls.default }) }),
      publicProvider(),
   ],
);

const needsInjectedWalletFallback =
   typeof window !== "undefined" && window.ethereum && !window.ethereum.isMetaMask && !window.ethereum.isCoinbaseWallet;

const connectors = connectorsForWallets([
   {
      groupName: "Recommended",
      wallets: [
         wallet.metaMask({ chains, shimDisconnect: true }),
         wallet.brave({ chains, shimDisconnect: true }),
         wallet.rainbow({ chains }),
         wallet.walletConnect({ chains }),
         wallet.coinbase({ appName: "SpaceGame", chains }),
         ...(needsInjectedWalletFallback ? [wallet.injected({ chains, shimDisconnect: true })] : []),
      ],
   },
]);

export const wagmiClient = createClient({
   autoConnect: true,
   connectors,
   provider,
});