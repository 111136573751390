import { useCallback } from "react";
import { ethers } from "ethers";

export const useApprove = (tokenContract, spender) => {
  const handleApprove = useCallback(async () => {
    try {
      const tx = await tokenContract.approve(
        spender,
        ethers.constants.MaxUint256
      );
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [tokenContract, spender]);

  return { onApprove: handleApprove };
};

export const useApproveForAll = (nftTokenContract, spender) => {
  const handleApprovalForAll = useCallback(async () => {
    try {
      const tx = await nftTokenContract.setApprovalForAll(spender, true);
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [nftTokenContract, spender]);

  return { onApprovalForAll: handleApprovalForAll };
};
