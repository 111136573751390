import { configureStore } from "@reduxjs/toolkit";
import founderPassReducer from "./founderPass";
import mnAReducer from "./mnA";
import mnAv2Reducer from "./mnAv2";
import mnAGameReducer from "./mnAGameCR";
import spidoxReducer from "./spidox";
import rewardPoolReducer from "./rewardPool";
import watereggReducer from "./wateregg";
import fireeggReducer from "./fireegg";
import darknesseggReducer from "./darknessegg";
import poisoneggReducer from "./poisonegg";
import oreReimbursementReducer from "./oreReimbursement";
import incubatorReducer from "./protoss/protoss-state";
import bossBattleReducer from "./battles/battles-state";

export default configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    founderPass: founderPassReducer,
    mnA: mnAReducer,
    mnAv2: mnAv2Reducer,
    mnAGameCR: mnAGameReducer,
    spidox: spidoxReducer,
    rewardPool: rewardPoolReducer,
    wateregg: watereggReducer,
    fireegg: fireeggReducer,
    darknessegg: darknesseggReducer,
    poisonegg: poisoneggReducer,
    oreReimbursement: oreReimbursementReducer,
    incubator: incubatorReducer,
    bossBattles: bossBattleReducer
  },
});
