import Button from "../components/button";
import TopNav from "../components/topnav";
import Section from "../components/section";
import InfoCard from "../components/info-card";
import Spidox from "../images/features/spidox.png";
import SpidoxAlt from "../images/features/spidox-alt.png";
import Sandbox from "../images/features/sandbox.gif";
import Gen0 from "../images/features/gen-0.png";
import Marine from "../images/characters/default.png";
import $ORES from "../images/characters/ores.png";
import Alien from "../images/characters/alienoo.png";
import RoadmapCard from "../components/roadmap-card";
import FeatureCard from "../components/feature-card";
import BioCard from "../components/bio-card";
import Viking from "../images/characters/viking.png";
import Kirk from "../images/characters/kirk.png";
import Hood from "../images/characters/hood.png";
import Monk from "../images/characters/monk.png";
import Cat from "../images/characters/cat.png";
import Kitty from "../images/characters/kitty.png";
import Ship from "../images/ships/voidship.gif";
import Backpack from "../images/characters/missions/backpack.png";
import Skull from "../images/characters/missions/skull.png";
import Axe from "../images/characters/missions/axe.png";
import MarineAlien from "../images/characters/missions/marines-aliens.png";
import Planet from "../images/characters/missions/planet.png";
import Footer from "../components/footer";
import LogoLarge from "../images/logos/logo-large.png";
import Umbria from "../images/logos/umbria.png";
import Mint from "../components/mint";
import SpidoxCard from "../components/spidox";
import Slug from "../components/slug";
import { useFounderPassUser, useMnAUser, useSpidoxUser } from "../state/hooks";
import Crates from "../components/crates";
import MnAs from "../components/mna";
import SpidoxItem from "../components/spidoxitem";

export default function Home() {
  const founderPassUser = useFounderPassUser();
  const mnAUser = useMnAUser();
  const spidoxUser = useSpidoxUser();
  let mintedCrates = null;
  let claimedMnAs = null;
  let claimedSpidox = null;

  // IF THEY HAVE CRATES SHOW THEM
  if (founderPassUser.tokenIds.length) {
    mintedCrates = <Crates tokenIds={founderPassUser.tokenIds} />;
  }

  if (mnAUser.tokens.length) {
    claimedMnAs = <MnAs tokens={mnAUser.tokens} />;
  }

  if (spidoxUser.balance > 0) {
    claimedSpidox = <SpidoxItem tokenIds={spidoxUser.tokenIds} />;
  }

  return (
    <div>
      <TopNav />

      {/* HERO */}
      <Section background="bg-ship">
        <figure className="logo-large">
          <img src={LogoLarge} alt="Space Game" />
        </figure>
        <div className="grid">
          <div className="col-6_md-12">
            <h3 className="subtitle l-pad-top-10">
              100% Onchain. L2-L1 Hybrid. P2E NFT Game.
            </h3>
            <h2 className="title">SPACE GAME</h2>
            <p className="text">
              The year is 2070… the Earth is dying. Only $ORES from Mars can
              save the planet.{" "}
            </p>
            <p className="text">
              Collect Marines to harvest $ORES but beware of the Aliens...
            </p>

            <div>
              <Button
                to="/dashboard/"
                kind="primary"
                space="right"
                className="l-space-right-3"
              >
                Play Game
              </Button>
              <Button
                href="https://space-game-widget.umbria.network/"
                kind="secondary"
                space="right"
                className="l-space-right-3"
              >
                BRIDGE
              </Button>

              <h3 className="partner-link">
                <img src={Umbria} alt="Umbria" />
                <span>Partnered with Umbria</span>
              </h3>
            </div>
          </div>
        </div>
      </Section>

      {/* MINT */}
      <Section background="bg-lava">
        <div className="grid" id="minting">
          <div className="col-5_md-12" data-push-right="off-1">
            <h3 className="subtitle">GEN 0 NFT Founder Crates</h3>
            <h2 className="title">What's Inside</h2>

            <Slug
              img={MarineAlien}
              title="1 X GEN-0 MINT "
              subtitle="90% MARINES / 10% ALIENS"
            />
            <Slug
              img={Sandbox}
              title="1 X CYBER MARINE/ALIEN"
              subtitle="VOXEL BASED NFT"
            />
            <Slug
              img={SpidoxAlt}
              title="1 X SPIDOX-DROID"
              subtitle="WL MINT EXCLUSIVE - 50% CHANCE [per mint]"
            />
          </div>

          <div className="col-6_md-12 l-middle">
            <Mint />
            <SpidoxCard />
          </div>
        </div>

        {mintedCrates}
        {claimedMnAs}
        {claimedSpidox}
      </Section>

      {/* EPOCH 0 */}
      <Section background="bg-closeup">
        <div className="grid">
          <div className="col-12">
            <h3 className="subtitle">EPOCH 0 - The First Encounter</h3>
            <h2 className="title">The Cast</h2>
            <p className="text">
              Governments around the world assembled the best marines on a
              mission to Mars to mine $ORES. A vicious race of aliens have
              already established a base around Mars. With $ORES being the only
              element capable of saving humanity, the marines negotiated a deal
              with the aliens to give up a 20% of $ORES mined as tribute. In
              return, allowing them access to mine.
            </p>
          </div>
        </div>

        <div className="grid">
          <div className="col-4_md-12">
            <InfoCard
              spacing="right"
              title="Marines"
              description="Earns up to 10k $ORES/day !ROLLOUT"
              img={Marine}
            />
          </div>
          <div className="col-4_md-12">
            <InfoCard
              title="Aliens"
              description="Receives 20% $ORES/day from staked marines."
              img={Alien}
            />
          </div>
          <div className="col-4_md-12">
            <InfoCard
              spacing="left"
              title="$ORES"
              description="Scarce supply &amp; native only to mars. 4B in existence."
              img={$ORES}
            />
          </div>
        </div>
      </Section>

      {/* FEATURES */}
      <Section background="bg-lava">
        <h3 className="subtitle">ARMED WITH UTILITY</h3>
        <h2 className="title">FOUNDER PASS</h2>
        <p className="text">
          6,969 Founder Crates available. Each Founder Crate guarantees you a
          GEN 0 NFT (Marine/Alien), Cyber Marine/Alien VX NFT (playable
          Metaverse Avatars in The Sandbox) &amp; future in-game rewards. In
          addition to this, all whitelisted players will receive a bonus
          exclusive in-game item, SPID0X-Droid. (further reduces the odds of
          your non-mints getting stolen).
        </p>

        <p className="text">
          The value of each Founder Crate will naturally grow with the adoption
          of Space Game and introduction of future utilities. We’re also looking
          to partner up with other metaverse projects like WWW (Worldwide Webb)
          to integrate our NFTs into the web-game as custom playable pixel
          avatars.
        </p>

        <div className="grid">
          <div className="col-4_md-12">
            <FeatureCard
              spacing="right"
              title="GEN-0 NFT (MARINE/ALIEN)"
              description="Guaranteed GEN-0 NFT with every Founder Crate minted. 90% chance of obtaining a Marine & 10% chance of obtaining an Alien."
              img={Gen0}
            />
          </div>
          <div className="col-4_md-12">
            <FeatureCard
              spacing="right"
              title="CYBER MARINE/ALIEN NFT"
              description="Custom-Playable Metaverse Avatars in the form voxel-based avatars built to be used in one of the largest growing Metaverse game, The Sandbox."
              img={Sandbox}
            />
          </div>
          <div className="col-4_md-12">
            <FeatureCard
              spacing="right"
              title="*BONUS: SPID0X-DROID ITEM"
              description="Exclusive to whitelisted minters only. 50% chance to receive a in-game item (SPID0X Droid) per mint. Item reduces the odds of your GEN-1 mints being stolen."
              img={Spidox}
            />{" "}
          </div>
        </div>
      </Section>

     {/* THE TEAM */}
     <Section background="bg-bridge">
        <div className="grid">
          <div className="col-12">
            <h3 className="subtitle">The Team</h3>
            <h2 className="title">SERVANTS OF AGARTHA</h2>
          </div>
        </div>

        <div className="grid">
          <div className="col-2_md-12">
            <BioCard
              role="[Founder/Dev]"
              rank="Commander"
              name="0xNobu"
              img={Viking}
            />
          </div>
          <div className="col-2_md-12">
            <BioCard
              role="[Co-Founder/Dev]"
              rank="Commander"
              name="Kirk"
              img={Kirk}
            />
          </div>
          <div className="col-2_md-12">
            <BioCard
              role="[Marketing Head]"
              rank="Commander"
              name="Atriox"
              img={Hood}
            />
          </div>
          <div className="col-2_md-12">
            <BioCard
              role="[Developer]"
              rank="Commander"
              name="Tempest"
              img={Monk}
            />
          </div>

          <div className="col-2_md-12">
            <BioCard
              role="[Com.Lead]"
              rank="Warmaster"
              name="Asteroid"
              img={Kitty}
            />
          </div>
          <div className="col-2_md-12">
            <BioCard role="[Artist]" rank="Warmaster" name="Pixie" img={Cat} />
          </div>
        </div>
      </Section>

      {/* Roadmap */}
      <Section background="bg-planet">
        <div className="grid">
          <div className="col-12">
            <h3 className="subtitle">STORYLINE</h3>
            <h2 className="title">EPOCHS</h2>
          </div>
        </div>
        <div className="grid-noGutter-equalHeight">
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="0"
              title="Marines &amp; Aliens"
              description={[
                "90% Marines — 10% Aliens",
                "To obtain GEN-0 NFTs, you'll need a Founder's Crate which cost 0.055Ξ for WL & 0.075Ξ for public.",
                "Only 6,969 Founder's Crate & GEN-0's will be available. 1:1 RATIO.",
                "21,028 GEN-1 NFTs are minted by mining $ORES.",
              ]}
              img={MarineAlien}
            />
          </div>
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="1"
              title="The Peace Treaty "
              description={[
                "Stockpiling of valuable $ORES begins. First accumulation phase for $ORES.",
                "Obtain mysterious alien eggs from the Collector which will be needed for the upcoming Mars Wars in Epoch 3.",
                "Launch of new game mechanics: The Collector, Soul Sacrifice, Backpacks, Brotherhood.",
              ]}
              img={Backpack}
            />
          </div>
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="2"
              title="Boot Camp"
              description={[
                "Acquire rare PvP items available only to early players of the Boot Camp.",
                "Train your marine & aliens to prepare for the upcoming Mars Wars.",
                "Second Accumulation phase for $ORES.",
              ]}
              img={Axe}
            />
          </div>
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="3"
              title="Mars Wars"
              description={[
                "War has broken out between both species! In-game PVP mechanics utilizing Marines & Aliens.",
                "High stakes gameplay. Who will survive?",
              ]}
              img={Skull}
            />
          </div>
        </div>

        <div className="grid-noGutter-equalHeight">
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="4"
              title="Build A Battleship"
              description={[
                "Scavenge resources on Mars to craft a battleship for interplanetary conquest.",
                "Item Fusion system — combine materials to upgrade your ships.",
                "Purchase Battleships with $ORES.",
              ]}
              img={Ship}
            />
          </div>
          <div className="col-3_md-12">
            <RoadmapCard
              epoch="5"
              title="Interplanet Conquest"
              description={[
                "Conquer nearby planets, gain a majority stake, dictate rules on the planet.",
                "Battle among players to claim majority stake in nearby planets.",
                "Mission-based system for Marines & Aliens.",
              ]}
              img={Planet}
            />
          </div>
        </div>
      </Section>

      <Footer />
    </div>
  );
}
