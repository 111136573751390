import {useCallback} from "react";
import {toast} from "react-toastify";

export const useMnAMetaTrain = (stakingPoolContract) => {
    const handleMnAMetaTrain = useCallback(
        async (tokenIds) => {
            try {
                const tx = await stakingPoolContract.upgradeLevel(tokenIds);
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [stakingPoolContract]
    );

    const handleMnAMetaTrainV3 = useCallback(
        async (useReserves, isMarine, tokenIds) => {
            try {
                const tx = await stakingPoolContract.upgradeLevel(useReserves, tokenIds);
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                if (e.data && e.data.message) {
                    if (e.data.message.includes("amount exceeds balance") || e.data.message.includes("not_enough_ore")) {
                        toast.error(`You do not have enough $ORE to level ${tokenIds.length > 1 ? "these " : "this "} ${isMarine ? "Marine" : "Alien"}${tokenIds.length > 1 ? "s" : ""}.`);
                    }
                }
                return false;
            }
        },
        [stakingPoolContract]
    );

    const handleDefinedMnAMetaTrainV3 = useCallback(
        async (useReserves, isMarine, level, tokenId) => {
            try {
                const tx = await stakingPoolContract.upgradeLevels(useReserves, tokenId, level);
                const receipt = await tx.wait();
                return receipt.status;
            } catch (e) {
                console.error(e);
                if (e.data && e.data.message) {
                    if (e.data.message.includes("amount exceeds balance") || e.data.message.includes("not_enough_ore")) {
                        toast.error(`You do not have enough $ORE to level this ${isMarine ? "Marine" : "Alien"}.`);
                    }
                }
                return false;
            }
        },
        [stakingPoolContract]
    );

    return {
        onMnAMetaTrain: handleMnAMetaTrain,
        onMnAMetaTrainV3: handleMnAMetaTrainV3,
        onDefinedMnAMetaTrainV3: handleDefinedMnAMetaTrainV3
    };
};
