import _ from "lodash";
import { useState } from "react";
import "./swap-card.scss";
import Button from "../../button";
import SelectButton from "../select-button";
import Tile from "../nft-tile";

export default function SwapCard({
  background,
  img,
  imgV2,
  title,
  v1 = [],
  v2 = [],
  isApproved,
  onApprove,
  onSwap = async () => {},
}) {
  const [pendingSwapTx, setPendingSwapTx] = useState(false);
  const [pendingApproveTx, setPendingApproveTx] = useState(false);
  const [selectedSwapItems, setSelectedSwapItems] = useState([]);
  const noItemsPlaceholder = <p className="swap-card-placeholder">No Items</p>;

  // CALLBACK FOR STAKING
  const toggleSelected = (id) => {
    // STARTS OFF AS NEW ID
    let isNew = true;

    // REMOVE ID IT IS EXISTS (TOGGLE OFF)
    _.remove(selectedSwapItems, (itemId) => {
      let exists = itemId === id;

      // IF THIS EXISTS WE'RE REMOVING IT
      if (exists) {
        isNew = false;
      }

      return exists;
    });

    // ONLY ADD ITEMS THAT DON'T EXIST IN ARRAY
    if (isNew) {
      setSelectedSwapItems((selectedSwapItems) => [...selectedSwapItems, id]);
    }
    // ELSE UPDATE ARRAY WITH REMOVAL OF ID
    else {
      setSelectedSwapItems((selectedSwapItems) => [...selectedSwapItems]);
    }
  };

  const selectAll = () => {
    // GET ALL IDS
    const ids = _.map(v1, (item) => {
      return item.id; 
    });

    setSelectedSwapItems(ids)
  }

  return (
    <div className="swap-card">
      <h3 style={{ backgroundImage: `url("${background}")` }}>{title}</h3>
      {/* STAKED */}
      <table>
        <thead>
          <tr>
            <th>Version 1 - ({v1.length} Total)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="swap-card-content">
                {v1.length > 0 && (
                    <p className="swap-card-tip">
                      Select Items to Swap
                      (<Button disabled={pendingApproveTx || v1.length === 0 || v1.length === selectedSwapItems.length} onClick={selectAll} space="left" kind="link">Select All</Button>)
                    </p>
                )}

                {v1.map((item) => {
                  // SEE IF ITEM IS SELECTED
                  const selected =
                    _.indexOf(selectedSwapItems, item.id) === -1 ? false : true;
                  return (
                    <SelectButton
                      key={item.id}
                      img={img}
                      id={item.id}
                      isMarine={item.isMarine}
                      rank={4 - item.rank}
                      title={title}
                      selected={selected}
                      onSelect={toggleSelected}
                    />
                  );
                })}
                {!v1.length && noItemsPlaceholder}
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              {isApproved ? (
                <Button
                  disabled={pendingSwapTx || selectedSwapItems.length === 0}
                  onClick={async () => {
                    if (selectedSwapItems.length === 0) return;
                    // CALL STAKE FUNCTION, PASS ID ARRAY AND CALLBACK TO CLEAR SELECTED ON SUCCESS
                    try {
                      setPendingSwapTx(true);
                      await onSwap(selectedSwapItems);
                      setSelectedSwapItems([]);
                      setPendingSwapTx(false);
                    } catch (e) {
                      console.error(e);
                      setPendingSwapTx(false);
                    }
                  }}
                  kind={pendingSwapTx ? "secondary" : "highlight"}
                >
                  Swap V1 to V2
                </Button>
              ) : (
                <Button
                  disabled={pendingApproveTx || v1.length === 0}
                  onClick={async () => {
                    try {
                      setPendingApproveTx(true);
                      await onApprove();
                      setPendingApproveTx(false);
                    } catch (e) {
                      console.error(e);
                      setPendingApproveTx(false);
                    }
                  }}
                  kind={pendingSwapTx ? "secondary" : "highlight"}
                >
                  Approve
                </Button>
              )}
            </td>
          </tr>
        </tfoot>
      </table>

      {/* VERSION 2 CARDS */}
      <table>
        <thead>
          <tr>
            <th>Version 2 - ({v2.length} Total)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="swap-card-content">
                {v2.map((item) => {
                  return (
                    <Tile
                      key={item.id}
                      img={imgV2}
                      id={item.id}
                      isMarine={item.isMarine}
                      rank={4 - item.rank}
                      title={title}
                      level={item.level}
                    />
                  );
                })}

                {!v2.length && noItemsPlaceholder}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
