import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import multicall from "../../utils/multicall";
import MnAv2ABI from "../../config/abis/MnAv2.json";
import CalcABI from "../../config/abis/SpaceGameCalculator.json";
import StakingPoolv2ABI from "../../config/abis/StakingPoolv2.json";
import {abi as StakingPoolv3ABI} from "../../config/abis/StakingPoolv3.json";
import {
    getCalculatorAddress, getIncubatorAddress,
    getMnAv2Address,
    getStakingPoolv2Address,
    getSubgraphUrl,
} from "../../utils/addressHelpers";
import BigNumber from "bignumber.js";
import {getBalanceNumber} from "../../utils/formatBalance";
import {getLevelMath} from "../../utils/levelMath";
import moment from "moment";
import {ethers} from "ethers";
import {numberWithCommas} from "../../helpers/numbers";
import {parseBigNumber} from "../../helpers";
import {abi as EggIncubatorABI} from "../../config/abis/EggIncubator.json";

export const MnAv2 = {
    maxTokens: 29997,
    minted: 0,
    user: {
        reservesBalance: 0,
        balance: 0,
        tokens: [],
        stakedTokens: [],
        marineRewards: 0,
        alienRewards: 0,
        unclaimedReserves: 0,
        subGraphTokenIds: []
    },
};

const initialState = {data: MnAv2};
export const mnAv2Slice = createSlice({
    name: "MnAv2",
    initialState,
    reducers: {
        setMnAv2PublicData: (state, action) => {
            const liveMnAData = action.payload;
            state.data = {...state.data, ...liveMnAData};
        },
        setMnAv2UserData: (state, action) => {
            const userData = action.payload;
            state.data = {...state.data, user: userData};
        },
    },
});

export const {setMnAv2PublicData, setMnAv2UserData} = mnAv2Slice.actions;

export const fetchMnAv2PublicDataAsync = () => async (dispatch) => {
    const mnAData = await fetchMnAv2();
    dispatch(setMnAv2PublicData(mnAData));
};

export const fetchMnAv2UserDataAsync = (account) => async (dispatch) => {
    try {
        const userData = await fetchMnAv2User(account);
        dispatch(setMnAv2UserData(userData));
    } catch (e) {
        console.error(e)
    }
};

const fetchMnAv2 = async () => {
    const mnAv2Address = getMnAv2Address();
    const calls = [
        {
            address: mnAv2Address,
            name: "minted",
        },
    ];

    const [minted] = await multicall(MnAv2ABI, calls);
    return {
        ...MnAv2,
        minted: Number(minted.toString()),
    };
};

const fetchMnAv2User = async (account) => {
    const mnAv2Address = getMnAv2Address();

    //mock user reported account
    //account = "0xA74f86b247Cd1C8790E309B0319e2927df661768"
    const calls = [
        {
            address: mnAv2Address,
            name: "balanceOf",
            params: [account],
        },
    ];
    const [balance] = await multicall(MnAv2ABI, calls);

    const tokenIds = [];
    const tokens = [];
    const balanceInNum = Number(balance.toString());
    const tokenIdCalls = [];
    const itemTypeCalls = [];
    const itemLevelCalls = [];
    const itemUpgradeInfoCalls = [];
    const subGraphTokenIds = [];
    const ownedTokenIds = [];
    let reservesBalance = 0;
    let unclaimedReserves = 0;

    const stakingPoolAddress = getStakingPoolv2Address();

    if (balanceInNum > 0) {
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIdCalls.push({
                address: mnAv2Address,
                name: "tokenOfOwnerByIndex",
                params: [account, idx],
            });
        }

        const tokenIdsRes = await multicall(MnAv2ABI, tokenIdCalls);
        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenIds.push(Number(tokenIdsRes[idx].toString()));
            itemTypeCalls.push({
                address: mnAv2Address,
                name: "getTokenTraits",
                params: [Number(tokenIdsRes[idx].toString())],
            });
            itemLevelCalls.push({
                address: mnAv2Address,
                name: "getTokenLevel",
                params: [Number(tokenIdsRes[idx].toString())],
            });

            itemUpgradeInfoCalls.push({
                address: mnAv2Address,
                name: "upgradeEpoches",
                params: [Number(tokenIdsRes[idx].toString())],
            });
        }

        const tokenTypesRes = await multicall(MnAv2ABI, itemTypeCalls);
        const tokenLevelRes = await multicall(MnAv2ABI, itemLevelCalls);
        const tokenUpgradeInfoRes = await multicall(MnAv2ABI, itemUpgradeInfoCalls);

        const tokenRaritiesCalls = []
        const incubatorAddress = getIncubatorAddress()

        for (let idx = 0; idx < balanceInNum; idx++) {
            tokenRaritiesCalls.push({
                address: incubatorAddress,
                name: "getRarities",
                params: [7, Number(tokenIdsRes[idx].toString())],
            });
        }

        const tokenRarities = await multicall(EggIncubatorABI, tokenRaritiesCalls);

        for (let idx = 0; idx < balanceInNum; idx++) {
            tokens.push({
                id: Number(tokenIdsRes[idx].toString()),
                level: Number(tokenLevelRes[idx].toString()),
                isMarine: tokenTypesRes[idx][0][0],
                rank: tokenTypesRes[idx][0][12],
                lastUpdate: Number(tokenUpgradeInfoRes[idx][1].toString()),
                skipped: tokenUpgradeInfoRes[idx][0],
                remainingDuration: /*isMarine ? endTime : */null,
                rarities: tokenRarities[idx]?.rarities
            });
        }
    }

    const res = await axios.post(getSubgraphUrl(), {
        query: `{
      klayeStakes(first:500,where:{account:"${account.toLowerCase()}", status:Staked}) {
        id
        account
        tokenId
        type
        timestamp
      }
    }
    `,
    });

    const stakes = res.data.data.klayeStakes;
   // console.log(stakes.forEach((s) => console.log(Number(s.id))))

    const stakedTokens = [];
    const stakedTokenIds = [];
    const tokenTraitCalls = stakes.map((stake) => {
        return {
            address: mnAv2Address,
            name: "getTokenTraits",
            params: [Number(stake.id)],
        };
    });

    const tokenLevelCalls = stakes.map((stake) => {
        return {
            address: mnAv2Address,
            name: "getTokenLevel",
            params: [Number(stake.id)],
        };
    });

    const upgradeInfoCalls = stakes.map((stake) => {
        return {
            address: mnAv2Address,
            name: "upgradeEpoches",
            params: [Number(stake.id)],
        };
    });

    const tokenTraitCallRes = await multicall(MnAv2ABI, tokenTraitCalls);
    const tokenLevelCallRes = await multicall(MnAv2ABI, tokenLevelCalls);
    const upgradeInfoCallRes = await multicall(MnAv2ABI, upgradeInfoCalls);

    const rewardCalls = [];
    for (let idx = 0; idx < stakes.length; idx++) {
        stakedTokenIds.push(Number(stakes[idx].id))
        rewardCalls.push({
            address: stakingPoolAddress,
            name: "calculateRewards",
            params: [Number(stakes[idx].id)],
        });
    }

    const rewardCallRes = await multicall(StakingPoolv3ABI, rewardCalls);
    let marineRewards = 0;
    let alienRewards = 0;

    const stakingDataCalls = [];
    for (let idx = 0; idx < stakes.length; idx++) {
        stakingDataCalls.push({
            address: stakingPoolAddress,
            name: "marinePool",
            params: [Number(stakes[idx].id)],
        });
    }
    const stakingDataCallResp = await multicall(StakingPoolv3ABI, stakingDataCalls);
    const reservesRes = await multicall(StakingPoolv3ABI, [
        {
            address: stakingPoolAddress,
            name: "calculateBackOwedReserves",
            params: [stakedTokenIds]
        }
    ])
    unclaimedReserves = Number(ethers.utils.formatEther(reservesRes[0].reserves));

    const reserveBalanceRes = await multicall(StakingPoolv3ABI, [
        {
            address: stakingPoolAddress,
            name: "getReserves",
            params: [account]
        }
    ])
    reservesBalance = Number(ethers.utils.formatEther(reserveBalanceRes[0].reserves));

    const getEndTime = (idx) => {
        const current = moment(Date.now());
        const rewardDuration = getLevelMath(Number(tokenLevelCallRes[idx].toString())).maxRewardDuration;
        const end = moment((Number(stakingDataCallResp[idx].startTime) + rewardDuration));
        return end.isValid() ? current.isAfter(end) ? null : end.toLocaleString() : null;
    }

    const tokenRaritiesCalls = []
    const incubatorAddress = getIncubatorAddress()

    for (let idx = 0; idx < stakes.length; idx++) {
        tokenRaritiesCalls.push({
            address: incubatorAddress,
            name: "getRarities",
            params: [7, Number(stakes[idx].id.toString())],
        });
    }

    const tokenRarities = await multicall(EggIncubatorABI, tokenRaritiesCalls);

    for (let idx = 0; idx < stakes.length; idx++) {
        const rewardAmt = new BigNumber(rewardCallRes[idx].toString());
        const rewardBalance = getBalanceNumber(rewardAmt);
        const taxedRewardBalance = (rewardBalance === 0 ? 0 : (rewardBalance * 80) / 100);
        const isMarine = tokenTraitCallRes[idx][0][0];
        const endTime = getEndTime(idx);
        stakedTokens.push({
            id: Number(stakes[idx].id),
            level: Number(tokenLevelCallRes[idx].toString()),
            isMarine: tokenTraitCallRes[idx][0][0],
            rank: tokenTraitCallRes[idx][0][12],
            lastStaked: Number(stakes[idx].timestamp),
            lastUpdate: Number(upgradeInfoCallRes[idx][1].toString()),
            skipped: upgradeInfoCallRes[idx][0],
            reward: isMarine ? taxedRewardBalance : rewardBalance,
            requiresUpgradeLevel: !endTime || moment(endTime).isBefore(moment(Date.now())),
            remainingDuration: isMarine ? endTime : null,
            rarities: tokenRarities[idx]?.rarities
        });

        if (isMarine) {
            // marine
            marineRewards += taxedRewardBalance;
        } else {
            // alient
            alienRewards += rewardBalance;
        }
    }
    return {
        balance: Number(balance.toString()),
        reservesBalance,
        tokens,
        ownedTokenIds,
        stakedTokens,
        marineRewards,
        alienRewards,
        unclaimedReserves,
        stakedTokenIds
    };
};
export default mnAv2Slice.reducer;
