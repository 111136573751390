import "./crates.scss";
import FounderPass from "../../images/characters/foundercrate.svg";

export default function Crates({ tokenIds = [] }) {
  return (
    <div className="crates">
      <h3 className="subtitle">Claimed Crates ({tokenIds.length} total)</h3>
      <div className={`grid`}>
        {tokenIds.map((id) => (
          <div key={id} className="col-3_md-12">
            <div className="crate">
              <div className="crate-inner">
                <img src={FounderPass} alt={`Founder Pass #${id}`} />
                <h3>FounderPass-{id}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
