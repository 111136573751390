import _ from "lodash";
import {useState} from "react";
import "./stake-card.scss";
import Button from "../../button";
import {numberWithCommas} from "../../../helpers/numbers";
import SelectButton from "../select-button";
import {toast} from "react-toastify";
import {GUARDING_DURATION} from "../../../config";
import numeral from 'numeral';
import ConfirmUnstake from "./stake-confirm-overlay";
import {getLevelMath} from "../../../utils/levelMath";
import moment from "moment";
import {useMnAMetaTrain} from "../../../hooks/useMetaTrain";
import {useMnAResetCoolDown} from "../../../hooks/useResetCooDown";
import {useERC20, useMnAv2Contract, useStakingPoolv3Contract} from "../../../hooks/useContracts";
import {useAllowance} from "../../../hooks/useAllowance";
import {getKlayeAddress, getMnAv2Address, getStakingPoolv3Address} from "../../../utils/addressHelpers";
import BigNumber from "bignumber.js";
import {useApprove} from "../../../hooks/useApprove";

export default function StakeCard({
                                      background,
                                      img,
                                      title,
                                      staked = [],
                                      unstaked = [],
                                      isApprovedForAll,
                                      onApprovalForAll,
                                      onClaim,
                                      onUnstake,
                                      onStake,
                                      onUnlock,
                                      unclaimed,
                                      reservesBalance,
                                      isMarines = true,
                                      isV1 = false,
                                      needsUpdated = true,
                                      currency = "$ORES",
                                  }) {
    const [unStakeSelected, setUnStakSelected] = useState(false);
    const [pendingApproveTx, setPendingApproveTx] = useState(false);
    const [pendingStakeTx, setPendingStakeTx] = useState(false);
    const [pendingClaimTx, setPendingClaimTx] = useState(false);

    const [filterById, setFilterById] = useState(false);
    const [filterByLevel, setFilterByLevel] = useState(false);
    const [filterByKlayeBalance, setFilterByKlayeBalance] = useState(false);
    const [filterByCoolDown, setFilterByCoolDown] = useState(false);
    const [filterByRarities, setFilterByRarities] = useState(false);

    const [useReserves, setUseReserves] = useState(false);
    const [useIndividualReserves, setUseIndividualReserves] = useState(false);
    const [klayesApproveConfirmed, setKlayeApproveConfirmed] = useState(false);

    const [selectedStakedItems, setSelectedStakedItems] = useState([]);
    const [selectedTotalKlaye, setSelectedTotalKlaye] = useState(0);
    const [selectedUnstakedItems, setSelectedUnstakedItems] = useState([]);
    const noItemsPlaceholder = <p className="stake-card-placeholder">No Items</p>;

    const unstakeableCount = staked?.filter((s) => s?.reward > 3).length;

    const stakingPoolContract = useStakingPoolv3Contract();
    const {onMnAMetaTrainV3} = useMnAMetaTrain(stakingPoolContract);
    const {onMnAResetCoolDown} = useMnAResetCoolDown(stakingPoolContract);

    const toggleUnStakeSelected = () => {
        setUnStakSelected(!unStakeSelected)
    }

    const clearFilters = () => {
        setFilterById(false);
        setFilterByLevel(false);
        setFilterByKlayeBalance(false);
        setFilterByCoolDown(false);
        setFilterByRarities(false)
    };

    const toggleFilterById = () => {
        clearFilters();
        setFilterById(!filterById);
    }
    const toggleFilterByLevel = () => {
        clearFilters();
        setFilterByLevel(!filterByLevel);
    }
    const toggleFilterByKlayeBalance = () => {
        clearFilters();
        setFilterByKlayeBalance(!filterByKlayeBalance);
    }
    const toggleFilterByCoolDown = () => {
        clearFilters();
        setFilterByCoolDown(!filterByCoolDown);
    }

    const toggleFilterByRarity = () => {
        clearFilters();
        setFilterByRarities(!filterByRarities);
    }

    const toggleUseReserves = () => {
        setUseReserves(!useReserves);
    }

    const toggleUseIndividualReserves = () => {
        setUseIndividualReserves(!useIndividualReserves);
    }

    staked = staked.sort((stake, other) => stake.id < other.id ? -1 : 1);

    staked = staked.sort((stake, other) => {
        let result = false;
        if (filterById) {
            result = stake.id < other.id;
        } else if (filterByLevel) {
            result = stake.level > other.level;
        } else if (filterByKlayeBalance) {
            result = stake.reward && stake.reward > other.reward;
        } else if (filterByCoolDown) {
            const curTime = Math.floor(Date.now() / 1000);
            const now = moment(Date.now())
            const {coolDownTime} = getLevelMath(stake.level);
            const {otherCoolDownTime} = getLevelMath(other.level);
            const hasCoolDown = curTime - stake.lastUpdate < coolDownTime && !stake.skipped;
            const otherHasCoolDown = curTime - other.lastUpdate < otherCoolDownTime && !other.skipped;
            const currentMoment = moment(stake.lastUpdate + coolDownTime);
            const otherMoment = moment(other.lastUpdate + otherCoolDownTime);
            const currentRemaining = hasCoolDown ? currentMoment.isAfter(now) ? stake.lastUpdate + coolDownTime : 0 : 0;
            const otherRemaining = otherHasCoolDown ? otherMoment.isAfter(now) ? (other.lastUpdate + otherCoolDownTime) : 0 : 0;
            result = currentRemaining > otherRemaining;
        } else if (filterByRarities) {
            const currRarity = stake.rarities.reduce((a,b) => a + b)
            const otherRarity = other.rarities.reduce((a,b) => a + b)
            result = currRarity < otherRarity
        }
        return result ? -1 : 1
    });

    unstaked = unstaked.sort((unstake, other) => {
        return !unstake.endTime && other.endTime
    });

    const stakedIds = staked.map((stakedToken) => stakedToken.id);

    const hasCoolDown = (stake) => {
        if (stake) {
            if (stake.level === 0) return false;
            const curTime = Math.floor(Date.now() / 1000);
            const {coolDownTime} = getLevelMath(stake.level);
            return curTime - stake.lastUpdate < coolDownTime && !stake.skipped
        } else return false;
    }

    // CALLBACK FOR UNSTAKING
    const toggleUnstaked = (id) => {
        // STARTS OFF AS NEW ID
        let isNew = true;

        // REMOVE ID IT IS EXISTS (TOGGLE OFF)
        _.remove(selectedUnstakedItems, (itemId) => {
            let exists = itemId === id;

            // IF THIS EXISTS WE'RE REMOVING IT
            if (exists) {
                isNew = false;
            }

            return exists;
        });

        // ONLY ADD ITEMS THAT DON'T EXIST IN ARRAY
        if (isNew) {
            setSelectedUnstakedItems((selectedUnstakedItems) => [
                ...selectedUnstakedItems,
                id,
            ]);
        }
        // ELSE UPDATE ARRAY WITH REMOVAL OF ID
        else {
            setSelectedUnstakedItems((selectedUnstakedItems) => [
                ...selectedUnstakedItems,
            ]);
        }
    };

    // CALLBACK FOR STAKING
    const toggleStaked = (id) => {
        const item = _.find(staked, {id: id});

        // STARTS OFF AS NEW ID
        let isNew = true;

        // REMOVE ID IT IS EXISTS (TOGGLE OFF)
        _.remove(selectedStakedItems, (itemId) => {
            let exists = itemId === id;

            // IF THIS EXISTS WE'RE REMOVING IT
            if (exists) {
                isNew = false;
            }

            return exists;
        });

        // ONLY ADD ITEMS THAT DON'T EXIST IN ARRAY
        if (isNew /*&& (item.reward > 3)*/) {
            setSelectedStakedItems((selectedStakedItems) => [
                ...selectedStakedItems,
                id,
            ]);
            setSelectedTotalKlaye(selectedTotalKlaye + item.reward)
        }
        // ELSE UPDATE ARRAY WITH REMOVAL OF ID
        else {
            setSelectedStakedItems((selectedStakedItems) => [...selectedStakedItems]);
            setSelectedTotalKlaye(selectedTotalKlaye - item.reward)
        }
    };

    function allSelectedHasKlaye() {
        return selectedStakedItems.every((i) => {
            const item = _.find(staked, {id: i});
            if (item && item.reward) return item.reward >= 3;
            return false;
        })
    }

    function anySelectedOnCoolDown() {
        return selectedStakedItems.filter((i) => {
            const item = _.find(staked, {id: i});
            return item && hasCoolDown(item)
        }).length > 0
    }

    function allSelectedOnCoolDown() {
        return selectedStakedItems.every((i) => {
            const item = _.find(staked, {id: i});
            return hasCoolDown(item)
        })
    }

    function allSelectedUpgradeOreCost() {
        let ores = 0;
        selectedStakedItems.forEach((i) => {
            const item = _.find(staked, {id: i});
            if (item && item.level) {
                const {oresToken} = getLevelMath(item.level);
                ores += oresToken;
            } else {
                ores += 155;
            }
        })
        return ores;
    }

    function allSelectedCoolDownCost() {
        let klaye = 0;
        selectedStakedItems.forEach((i) => {
            const item = _.find(staked, {id: i});
            if (item && item.level) {
                const {klayeToSkip} = getLevelMath(item.level);
                klaye += klayeToSkip;
            }
        })
        return klaye;
    }

    function allUpgradeOreCost() {
        let ores = 0;
        trainableItems().forEach((item) => {
            if (item && item.level) {
                const {oresToken} = getLevelMath(item.level);
                ores += oresToken;
            } else {
                ores += 500;
            }
        })
        return ores;
    }

    function allCoolDownCost() {
        let klaye = 0;
        coolDownAvailableItems().forEach((item) => {
            if (item && item.level) {
                const {klayeToSkip} = getLevelMath(item.level);
                klaye += klayeToSkip;
            }
        })
        return klaye;
    }

    function trainableItems() {
        return staked.filter((item) => {
            return hasCoolDown(item) === false
        })
    }

    function coolDownAvailableItems() {
        return staked.filter((item) => {
            return hasCoolDown(item)
        })
    }

    const loadSelect = () => (
        <>
            <div className={"filter-box-container"}>
                <span>Filters: </span>
                <label>
                    <input type={"checkbox"} readOnly={true} checked={filterById} onClick={toggleFilterById}/>
                    <span className={"text"}>Id</span>
                </label>
                <label>
                    <input type={"checkbox"} readOnly={true} checked={filterByLevel} onClick={toggleFilterByLevel}/>
                    <span className={"text"}>Level</span>
                </label>
                <label>
                    <input type={"checkbox"} readOnly={true} checked={filterByKlayeBalance}
                           onClick={toggleFilterByKlayeBalance}/>
                    <span className={"text"}>Klaye Balance</span>
                </label>
                <label>
                    <input type={"checkbox"} readOnly={true} checked={filterByCoolDown}
                           onClick={toggleFilterByCoolDown}/>
                    <span className={"text"}>Has Cooldown</span>
                </label>
                <label>
                    <input type={"checkbox"} readOnly={true} checked={filterByRarities}
                           onClick={toggleFilterByRarity}/>
                    <span className={"text"}>Rarity Score</span>
                </label>
            </div>
            <div className={"filter-box-container"}>
                <span>Options: </span>
                <label>
                    <input type={"checkbox"} readOnly={true}
                           disabled={reservesBalance === '0.000'}
                           checked={reservesBalance !== '0.000' ? useIndividualReserves : false}
                           onClick={() => toggleUseIndividualReserves()}/>
                    <span className={"text"}>Use Reserves</span>
                    <span
                        style={{color: "red"}}> (Uses reserves for individually selected {isMarines ? "Marines" : "Aliens"})</span>
                </label>
            </div>
        </>
    )

    return (
        <>
            {needsUpdated
                ?
                <ConfirmUnstake onUnlock={async () => {
                    try {
                        if (needsUpdated === false) return;
                        setPendingClaimTx(true);
                        let status = await onUnlock(stakedIds);
                        if (status === 1) needsUpdated = false;
                        setSelectedStakedItems([]);
                        setPendingClaimTx(false);
                    } catch (e) {
                        console.error(e);
                        setPendingClaimTx(false);
                    }
                }
                }
                />
                :
                <div className="stake-card">
                    <h3 style={{backgroundImage: `url("${background}")`}}>{title}</h3>
                    {/* STAKED */}
                    <table>
                        <thead>
                        <tr>
                            <th>Staked ({staked.length} Total) ({unstakeableCount} Unstakeable)</th>
                            <th className="ores">{numberWithCommas(unclaimed)} Unclaimed</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>
                            <td colSpan={2}>
                                {loadSelect()}
                                <div className="stake-card-content">
                                    {staked.length > 0 && (
                                        <p className="stake-card-tip">
                                            Select Items - Must earn 3 $Klaye to unstake
                                        </p>
                                    )}

                                    {staked.map((item) => {
                                        // SEE IF ITEM IS SELECTED
                                        const selected = _.indexOf(selectedStakedItems, item.id) !== -1;
                                        let isDisabled = false;//!!(curTime - item.lastStaked < GUARDING_DURATION && item.isMarine)

                                        const {oresToken, coolDownTime} = getLevelMath(item.level);

                                        if (item.reward < 3) {
                                            // isDisabled = true;
                                        } else {
                                            // isDisabled = curTime - item.lastUpdate < coolDownTime &&
                                            //    !item.skipped;
                                        }

                                        const endTime = (item.lastUpdate + coolDownTime) * 1000;

                                        return (
                                            <SelectButton
                                                key={item.id}
                                                img={img}
                                                id={item.id}
                                                isMarine={item.isMarine}
                                                level={item.level}
                                                rank={4 - item.rank}
                                                title={title}
                                                selected={selected}
                                                reward={item.reward}
                                                rarities={item.rarities}
                                                endTime={hasCoolDown(item) ? endTime : null}
                                                useReserves={useIndividualReserves}
                                                isStaking={false}
                                                disabled={isDisabled}
                                                onSelect={toggleStaked}
                                                cost={oresToken}
                                                hasCoolDown={hasCoolDown(item)}
                                            />
                                        );
                                    })}
                                    {!staked.length && noItemsPlaceholder}
                                </div>
                            </td>
                        </tr>
                        </tbody>

                        <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <div className={"filter-box-container"}>
                                    <h4>Staking Options</h4>
                                    <Button
                                        onClick={async () => {
                                            try {
                                                setPendingClaimTx(true);
                                                console.log(selectedStakedItems.length > 0 ? selectedStakedItems : stakedIds);
                                                await onClaim(selectedStakedItems.length > 0 ? selectedStakedItems : stakedIds);
                                                setPendingClaimTx(false);
                                            } catch (e) {
                                                console.error(e);
                                                setPendingClaimTx(false);
                                            }
                                        }}
                                        kind={pendingClaimTx ? "secondary" : "highlight"}
                                        space={"top-right"}
                                    >
                                        {isV1 ? "Rescue"
                                            :
                                            `Claim ${
                                                selectedStakedItems.length > 0
                                                    ? numberWithCommas(numeral(selectedTotalKlaye).format("0.00")) + " " + currency
                                                    : numberWithCommas(numeral(unclaimed).format("0.00")) + " " + currency
                                            }`
                                        }
                                    </Button>
                                    {
                                        selectedStakedItems.length > 0
                                            ? <Button
                                                disabled={
                                                    selectedStakedItems.length > 0
                                                        ? !allSelectedHasKlaye()
                                                        : (pendingClaimTx || selectedStakedItems.length === 0)
                                                }
                                                onClick={async () => {
                                                    if (selectedStakedItems.length === 0) return;
                                                    // CALL STAKE FUNCTION, PASS ID ARRAY AND CALLBACK TO CLEAR SELECTED ON SUCCESS
                                                    try {
                                                        setPendingClaimTx(true);
                                                        await onUnstake(selectedStakedItems);
                                                        setSelectedStakedItems([]);
                                                        setPendingClaimTx(false);
                                                    } catch (e) {
                                                        console.error(e);
                                                        setPendingClaimTx(false);
                                                    }
                                                }}
                                                kind={pendingClaimTx ? "secondary" : "highlight"}
                                                space={"right"}
                                            >
                                                Claim {selectedStakedItems.length > 0
                                                ? numberWithCommas(numeral(selectedTotalKlaye).format("0.00")) + " " + currency
                                                : ""
                                            } &amp; Unstake {selectedStakedItems.length > 0
                                                ? "(" + selectedStakedItems.length + ")"
                                                : ""
                                            }
                                            </Button>
                                            : null
                                    }
                                    {isV1 ? null : selectedStakedItems.length > 0 ? null : <Button
                                        disabled={pendingClaimTx || unstakeableCount === 0 || (selectedStakedItems.length > 0 && unstakeableCount === 0)}
                                        onClick={async () => {
                                            if (unstakeableCount === 0) return;
                                            // CALL STAKE FUNCTION, PASS ID ARRAY AND CALLBACK TO CLEAR SELECTED ON SUCCESS
                                            try {
                                                setPendingClaimTx(true);
                                                await onUnstake(staked.filter((stake) => stake?.reward > 3).map((s) => s.id));
                                                setSelectedStakedItems([]);
                                                setPendingClaimTx(false);
                                            } catch (e) {
                                                console.error(e);
                                                setPendingClaimTx(false);
                                            }
                                        }}
                                        kind={pendingClaimTx ? "secondary" : "highlight"}
                                        space={"top"}
                                    >
                                        Claim All &amp; Unstake {unstakeableCount === 0 ? "" : unstakeableCount}
                                    </Button>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className={"filter-box-container"}>
                                    <h4>
                                        Training Options
                                        <label>
                                            <input type={"checkbox"} readOnly={true}
                                                   disabled={reservesBalance === '0.000'}
                                                   checked={reservesBalance !== '0.000' ? useReserves : false}
                                                   onClick={() => toggleUseReserves()}/>
                                            <span className={"text"}>Use Reserves {reservesBalance}</span>
                                        </label>
                                    </h4>
                                    <Button
                                        onClick={async () => {
                                            await onMnAMetaTrainV3(useReserves, isMarines, selectedStakedItems.map((s) => s))
                                        }}
                                        kind={pendingClaimTx ? "secondary" : "highlight"}
                                        space="top-right"
                                        disabled={selectedStakedItems.length === 0 || anySelectedOnCoolDown()}
                                    >
                                        {isV1 ? "Rescue"
                                            : selectedStakedItems.length === 0
                                                ? "Select any MnA to train."
                                                : `Train ${selectedStakedItems.length} for ${
                                                    numberWithCommas(numeral(allSelectedUpgradeOreCost()).format("0.00")) + " $ORE"
                                                }`
                                        }
                                    </Button>
                                    {isV1 ? null : <Button
                                        disabled={pendingClaimTx || selectedStakedItems.length > 0 || trainableItems().length === 0}
                                        onClick={async () => {
                                            await onMnAMetaTrainV3(useReserves, isMarines, trainableItems().map((t) => t.id))
                                        }}
                                        kind={pendingClaimTx ? "secondary" : "highlight"}
                                        space={"top-right"}
                                    >
                                        Train {"(" + trainableItems().length + ") for " + numberWithCommas(numeral(allUpgradeOreCost()).format("0.00")) + " $ORE"}
                                    </Button>}
                                    {
                                        <Button
                                            disabled={selectedStakedItems.length === 0 || !allSelectedOnCoolDown()}
                                            onClick={async () => {
                                                if (selectedStakedItems.length === 0) return;
                                                await onMnAResetCoolDown(selectedStakedItems)
                                            }}
                                            kind={pendingClaimTx ? "secondary" : "highlight"}
                                            space="top-right"
                                        >
                                            {isV1 ? "Rescue"
                                                : selectedStakedItems.length === 0 || !allSelectedOnCoolDown() ? "Select MnA on CoolDown to Reset" :
                                                    `Reset Cool Down (${selectedStakedItems.length}) for ${
                                                        numberWithCommas(numeral(allSelectedCoolDownCost()).format("0.00")) + " " + currency
                                                    }`
                                            }
                                        </Button>
                                    }
                                    {isV1 ? null : <Button
                                        disabled={pendingClaimTx || selectedStakedItems.length > 0 || coolDownAvailableItems().length === 0}
                                        onClick={async () => {
                                            await onMnAResetCoolDown(coolDownAvailableItems().map((s) => s.id))
                                        }}
                                        kind={pendingClaimTx ? "secondary" : "highlight"}
                                        space={"top-right"}
                                    >
                                        Reset All Cooldowns
                                        for {numberWithCommas(numeral(allCoolDownCost()).format("0.00")) + " " + currency}
                                    </Button>
                                    }
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>

                    {/* UNSTAKED */}
                    <table>
                        <thead>
                        <tr>
                            <th>Unstaked ({unstaked.length} Total)</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>
                            <td>
                                <div className="stake-card-content">
                                    {unstaked.length > 0 && isApprovedForAll && (
                                        <p className="stake-card-tip">Select Items to Stake</p>
                                    )}

                                    {unstaked.map((item) => {
                                        // SEE IF ITEM IS SELECTED
                                        const selected =
                                            _.indexOf(selectedUnstakedItems, item.id) !== -1;

                                        // DISABLE SELECTING UNLESS ITEMS HAVE BEEN APPROVED
                                        let onSelectMethod = isApprovedForAll
                                            ? toggleUnstaked
                                            : () => {
                                            };

                                        return (
                                            <SelectButton
                                                key={item.id}
                                                img={img}
                                                id={item.id}
                                                isMarine={item.isMarine}
                                                rank={item.rank}
                                                level={item.level}
                                                title={title}
                                                rarities={item.rarities}
                                                endTime={item.remainingDuration}
                                                isStaking={true}
                                                selected={selected}
                                                onSelect={onSelectMethod}
                                            />
                                        );
                                    })}

                                    {!unstaked.length && noItemsPlaceholder}
                                </div>
                            </td>
                        </tr>
                        </tbody>

                        <tfoot>
                        <tr>
                            <td>
                                {isApprovedForAll ? (
                                    isV1 ? null
                                        : <>
                                            <Button
                                                disabled={
                                                    pendingStakeTx || selectedUnstakedItems.length === 0
                                                }
                                                onClick={async () => {
                                                    if (selectedUnstakedItems.length === 0) return;
                                                    // CALL STAKE FUNCTION, PASS ID ARRAY AND CALLBACK TO CLEAR SELECTED ON SUCCESS
                                                    try {
                                                        setPendingStakeTx(true);
                                                        await onStake(selectedUnstakedItems);
                                                        setSelectedUnstakedItems([]);
                                                        setPendingStakeTx(false);
                                                    } catch (e) {
                                                        console.error(e);
                                                        setPendingStakeTx(false);
                                                    }
                                                }}
                                                kind={pendingStakeTx ? "secondary" : "highlight"}
                                                space={"right"}
                                            >
                                                Stake {selectedUnstakedItems.length > 0 ? selectedUnstakedItems.length : ""} &amp; Mine {currency}
                                            </Button>
                                            <Button
                                                disabled={
                                                    pendingStakeTx || selectedUnstakedItems.length !== 0
                                                }
                                                onClick={async () => {
                                                    if (selectedUnstakedItems.length !== 0) return;
                                                    // CALL STAKE FUNCTION, PASS ID ARRAY AND CALLBACK TO CLEAR SELECTED ON SUCCESS
                                                    try {
                                                        setPendingStakeTx(true);
                                                        await onStake(unstaked.map((s) => s.id));
                                                        setSelectedUnstakedItems([]);
                                                        setPendingStakeTx(false);
                                                    } catch (e) {
                                                        console.error(e);
                                                        setPendingStakeTx(false);
                                                    }
                                                }}
                                                kind={pendingStakeTx ? "secondary" : "highlight"}
                                                space={"top"}
                                            >
                                                Stake All &amp; Mine {currency}
                                            </Button>
                                        </>
                                ) : (
                                    <Button
                                        disabled={pendingApproveTx}
                                        onClick={async () => {
                                            try {
                                                setPendingApproveTx(true);
                                                const status = await onApprovalForAll();
                                                if (status === 1) {
                                                    toast.success("Approved all successfully!");
                                                }
                                                setPendingApproveTx(false);
                                            } catch (e) {
                                                console.error(e);
                                                toast.error("Approve all failed");
                                                setPendingApproveTx(false);
                                            }
                                        }}
                                        kind={pendingApproveTx ? "secondary" : "highlight"}
                                    >
                                        Approve
                                    </Button>
                                )}
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            }
        </>
    );
}
