import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

import useRefresh from "../hooks/useRefresh";
import {fetchFounderPassPublicDataAsync} from "./founderPass";
import {fetchMnAPublicDataAsync} from "./mnA";
import {fetchMnAv2PublicDataAsync} from "./mnAv2";
import {fetchMnAGamePublicDataAsync} from "./mnAGameCR";
import {fetchSpidoxPublicDataAsync} from "./spidox";
import {fetchPoolsPublicDataAsync} from "./rewardPool";
import lpPools from "../config/lpPools";
import BigNumber from "bignumber.js";
import {getLpPrice, getTokenPrice} from "../utils/priceHelper";
import {fetchWaterEggPublicDataAsync} from "./wateregg";
import {fetchFireEggPublicDataAsync} from "./fireegg";
import {fetchDarknessEggPublicDataAsync} from "./darknessegg";
import {fetchPoisonEggPublicDataAsync} from "./poisonegg";
import {BigNumber as bn} from "@ethersproject/bignumber/lib/bignumber";

// FounderPass - hooks
export const useFetchFounderPassPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchFounderPassPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useFounderPass = () => {
  const founderPass = useSelector((state) => state.founderPass.data);
  return founderPass;
};

export const useFounderPassUser = () => {
  const founderPass = useFounderPass();
  return {
    avaialbeClaimCount:
      founderPass && founderPass.user
        ? founderPass.user.availableClaimCount
        : 0,
    balance: founderPass && founderPass.user ? founderPass.user.balance : 0,
    tokenIds: founderPass && founderPass.user ? founderPass.user.tokenIds : [],
  };
};

// MnA - hooks
export const useFetchMnAPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchMnAPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useMnA = () => {
  const mnA = useSelector((state) => state.mnA.data);
  return mnA;
};

export const useMnAUser = () => {
  const mnA = useMnA();
  return {
    balance: mnA && mnA.user ? mnA.user.balance : 0,
    tokens: mnA && mnA.user ? mnA.user.tokens : [],
    stakedTokens: mnA && mnA.user ? mnA.user.stakedTokens : [],
    marineRewards: mnA && mnA.user ? mnA.user.marineRewards : [],
    alienRewards: mnA && mnA.user ? mnA.user.alienRewards : [],
  };
};

// MnAGameCR - hooks
export const useFetchMnAGamePublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchMnAGamePublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useMnAGameCR = () => {
  const mnAGameCR = useSelector((state) => state.mnAGameCR.data);
  return mnAGameCR;
};

export const useMnAGameCRUser = () => {
  const mnAGameCR = useMnAGameCR();
  return {
    pendingMint:
      mnAGameCR && mnAGameCR.user ? mnAGameCR.user.pendingMint : null,
    hasMintPending:
      mnAGameCR && mnAGameCR.user ? mnAGameCR.user.hasMintPending : false,
    canMint: mnAGameCR && mnAGameCR.user ? mnAGameCR.user.canMint : false,
  };
};

// Spidox - hooks
export const useFetchSpidoxPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchSpidoxPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useSpidox = () => {
  const spidox = useSelector((state) => state.spidox.data);
  return spidox;
};

export const useSpidoxUser = () => {
  const spidox = useSpidox();

  return {
    isWhitelist: spidox && spidox.user ? spidox.user.isWhitelist : false,
    balance: spidox && spidox.user ? spidox.user.balance : 0,
    tokenIds: spidox && spidox.user ? spidox.user.tokenIds : [],
  };
};

// WaterEgg
export const useFetchWaterEggPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchWaterEggPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useWaterEgg = () => {
  const wateregg = useSelector((state) => state.wateregg.data);
  return wateregg;
};

export const useWaterEggUser = () => {
  const wateregg = useWaterEgg();

  return {
    claimed: wateregg && wateregg.user ? wateregg.user.claimed : false,
    balance: wateregg && wateregg.user ? wateregg.user.balance : 0,
    tokenIds: wateregg && wateregg.user ? wateregg.user.tokenIds : [],
  };
};

// FireEgg
export const useFetchFireEggPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchFireEggPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useFireEgg = () => {
  const fireegg = useSelector((state) => state.fireegg.data);
  return fireegg;
};

export const useFireEggUser = () => {
  const fireegg = useFireEgg();

  return {
    claimed: fireegg && fireegg.user ? fireegg.user.claimed : false,
    balance: fireegg && fireegg.user ? fireegg.user.balance : 0,
    tokenIds: fireegg && fireegg.user ? fireegg.user.tokenIds : [],
  };
};

// DarknessEgg
export const useFetchDarknessEggPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchDarknessEggPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useDarknessEgg = () => {
  const darknessegg = useSelector((state) => state.darknessegg.data);
  return darknessegg;
};

export const useDarknessEggUser = () => {
  const darknessegg = useDarknessEgg();

  return {
    claimed: darknessegg && darknessegg.user ? darknessegg.user.claimed : false,
    balance: darknessegg && darknessegg.user ? darknessegg.user.balance : 0,
    tokenIds: darknessegg && darknessegg.user ? darknessegg.user.tokenIds : [],
  };
};

// PoisonEgg
export const useFetchPoisonEggPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchPoisonEggPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const usePoisonEgg = () => {
  const poisonegg = useSelector((state) => state.poisonegg.data);
  return poisonegg;
};

export const usePoisonEggUser = () => {
  const poisonegg = usePoisonEgg();

  return {
    claimed: poisonegg && poisonegg.user ? poisonegg.user.claimed : false,
    balance: poisonegg && poisonegg.user ? poisonegg.user.balance : 0,
    tokenIds: poisonegg && poisonegg.user ? poisonegg.user.tokenIds : [],
  };
};

// MnAv2 - hooks
export const useFetchMnAv2PublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchMnAv2PublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useMnAv2 = () => {
  const mnAv2 = useSelector((state) => state.mnAv2.data);
  return mnAv2;
};

export const useMnAv2User = () => {
  const mnAv2 = useMnAv2();
  return {
    balance: mnAv2 && mnAv2.user ? mnAv2.user.balance : 0,
    tokens: mnAv2 && mnAv2.user ? mnAv2.user.tokens : [],
    ownedTokenIds: mnAv2 && mnAv2.user ? mnAv2.user.ownedTokenIds : [],
    stakedTokens: mnAv2 && mnAv2.user ? mnAv2.user.stakedTokens : [],
    marineRewards: mnAv2 && mnAv2.user ? mnAv2.user.marineRewards : [],
    alienRewards: mnAv2 && mnAv2.user ? mnAv2.user.alienRewards : [],
    unclaimedReserves: mnAv2 && mnAv2.user ? mnAv2.user.unclaimedReserves : 0,
    reservesBalance: mnAv2 && mnAv2.user ? mnAv2.user.reservesBalance : 0,
    stakedTokenIds: mnAv2 && mnAv2.user ? mnAv2.user.stakedTokenIds : [],
  };
};

export const useIncubator = () => {
  const incubator = useSelector((state) => state.incubator.data);
  return incubator;
};

export const useIncubatorUser = () => {
  const incubator = useIncubator()
  return {
    incubators: incubator && incubator.user ? incubator.user.incubators : [],
    tokens: incubator && incubator.user ? incubator.user.tokens : [],
  }
}

export const useBossBattles = () => {
  const incubator = useSelector((state) => state.bossBattles.data);
  return incubator;
};

export const useBossBattlesUser = () => {
  const incubator = useBossBattles()
  return {
    activeBattleRecords: incubator && incubator.user ? incubator.user.activeRecords : [],
    previousBattleRecords: incubator && incubator.user ? incubator.user.previousRecords : [],
    ownedProtoss: incubator && incubator.user ? incubator.user.ownedProtoss : [],
    tokens: incubator && incubator.user ? incubator.user.tokens : [],
  }
}

// Reward Pool - hooks
export const useFetchPoolsPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchPoolsPublicDataAsync());
  }, [dispatch, slowRefresh]);
};

export const useRewardPools = () => {
  const pools = useSelector((state) => state.rewardPool.data);
  return pools;
};

export const useRewardPoolFromName = (name) => {
  const pool = useSelector((state) =>
    state.rewardPool.data.find((f) => f.name === name)
  );
  return pool;
};

export const useRewardPoolUser = (name) => {
  const pool = useRewardPoolFromName(name);
  return {
    allowance: pool.userData ? pool.userData.allowance : new BigNumber(0),
    stakedBalance: pool.userData
      ? pool.userData.stakedBalance
      : new BigNumber(0),
    earned: pool.userData ? pool.userData.earned : new BigNumber(0),
    lastDeposited:
      pool.userData && pool.userData.lastDeposited
        ? pool.userData.lastDeposited
        : 0,
    rewardPerToken: pool.userData && pool.userData.rewardPerToken ? pool.userData.rewardPerToken : new BigNumber(0)
  };
};

export const useOreReimbursement = () => {
  return useSelector((state) => state.oreReimbursement.data);
};

export const useOreReimbursementUser = () => {
  const reimbursement = useOreReimbursement();
  return {
    index: reimbursement && reimbursement.user ? reimbursement.user.index : 0,
    amount: reimbursement && reimbursement.user ? reimbursement.user.amount : 0,
    proof: reimbursement && reimbursement.user ? reimbursement.user.proof : null,
    claimableBalance: reimbursement && reimbursement.user ? reimbursement.user.claimableBalance : 0,
    lastClaimTime: reimbursement && reimbursement.user ? reimbursement.user.lastClaimTime : 0,
    remainingBalance: reimbursement && reimbursement.user ? reimbursement.user.remainingBalance : 0,
    totalClaimed: reimbursement && reimbursement.user ? reimbursement.user.totalClaimed : 0,
    perDay: reimbursement && reimbursement.user ? reimbursement.user.perDay : 0,
  };
};

export const useTokenPrice = (name, isLpToken, chainId) => {
  const ethPrice = useETHPrice(chainId);
  const lpPool = lpPools.find((f) => f.name === name);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    const fetchPrice = async (lpPool, isLpToken, chainId) => {
      if (lpPool) {
        if (isLpToken) {
          const res = await getLpPrice(lpPool, chainId);
          setPrice(res);
        } else {
          const res = await getTokenPrice(lpPool, chainId);
          setPrice(res);
        }
      }
    };
    fetchPrice(lpPool, isLpToken, chainId);
  }, [name, isLpToken, chainId, lpPool]);

  if (
    lpPool?.quoteTokenSymbol === "USDT" ||
    lpPool?.quoteTokenSymbol === "USDC"
  ) {
    return price;
  } else if (lpPool?.quoteTokenSymbol === "WETH") {
    return price * ethPrice;
  } else {
    return 1;
  }
};

export const useETHPrice = (chainId) => {
  const lpPool = lpPools.find((f) => f.name === "WETH");
  const [ethPrice, setETHPrice] = useState(0);
  useEffect(() => {
    const fetchPrice = async (lpPool, chainId) => {
      if (lpPool) {
        const res = await getTokenPrice(lpPool, chainId);
        setETHPrice(res);
      }
    };
    fetchPrice(lpPool, chainId);
  }, [chainId, lpPool]);
  return ethPrice;
};
