import SideNav from "../../components/sidenav"
import { Outlet } from "react-router"
import "./dashboard.scss"

export default function Dashboard() {
    return (
      <div className="dashboard">
        <SideNav /> 
        <Outlet />
      </div>
    )
  }