import { useCallback, useState } from "react";
import Header from "../components/dashboard/header";
import Marine from "../images/characters/default.png";
import Alien from "../images/characters/alien.png";
import Eggs from "../images/backgrounds/eggs.gif";
import Bridge from "../images/backgrounds/bridge.gif";
import Button from "../components/button";
import StakeCard from "../components/dashboard/stake-card";
import MintStatus from "../components/dashboard/mint-status";
import { useFounderPassUser, useMnAUser } from "../state/hooks";
import { getOwnerOfMnAToken } from "../utils/callHelpers";
import { getMnAAddress, getStakingPoolAddress } from "../utils/addressHelpers";
import { useMnAContract, useStakingPoolContract } from "../hooks/useContracts";
import { useMnAClaim } from "../hooks/useMnAClaim";
import { toast } from "react-toastify";
import { useIsApprovedForAll } from "../hooks/useAllowance";
import { useApproveForAll } from "../hooks/useApprove";
import { useMnAStake } from "../hooks/useMnAStake";
import { useOresClaim } from "../hooks/useOresClaim";
import {useRescueV1} from "../hooks/useRescueV1";
import {useWeb3Context} from "../hooks/web3Context";
import {useAccount} from "wagmi";

export default function Minting(props) {
  const { address, connected, connect } = useAccount();
  const [claimable, setClaimable] = useState(false);

  // founder pass check
  const founderPassUser = useFounderPassUser();
  const founderPassTokenIds = founderPassUser.tokenIds;
  if (founderPassTokenIds.length > 0) {
    getOwnerOfMnAToken(getMnAAddress(), founderPassTokenIds[0])
      .then((owner) => {
        if (owner.toLowerCase() === getMnAAddress().toLowerCase()) {
          setClaimable(true);
        }
      })
      .catch((e) => {});
  }

  // claim action
  const [claimPendingTx, setClaimPendingTx] = useState(false);
  const MnAContract = useMnAContract();
  const { onMnAClaim } = useMnAClaim(MnAContract);
  const handleClaim = useCallback(async () => {
    try {
      setClaimPendingTx(true);
      const claimStatus = await onMnAClaim();
      if (claimStatus === 1) {
        toast.success("Claimed successfully!");
      }
      setClaimPendingTx(false);
    } catch (e) {
      console.error(e);
      toast.error("Claim failed");
    }
  }, [onMnAClaim]);

  // MnA
  const mnAUser = useMnAUser();
  const stakedMarines = mnAUser.stakedTokens.filter(
    (stakedToken) => stakedToken.isMarine
  );
  const unstakedMarines = mnAUser.tokens.filter(
    (unstakedToken) => unstakedToken.isMarine
  );

  const stakedAliens = mnAUser.stakedTokens.filter(
    (stakedToken) => !stakedToken.isMarine
  );

  const unstakedAliens = mnAUser.tokens.filter((token) => !token.isMarine);

  // MnAGameCR

  const unclaimedOresForMarines = mnAUser.marineRewards;
  const unclaimedOresForMarinesFormatted = unclaimedOresForMarines
    ? `${unclaimedOresForMarines.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })} $ORES`
    : `0.000 $ORES`;

  const unclaimedOresForAliens = mnAUser.alienRewards;
  const unclaimedOresForAliensFormatted = unclaimedOresForAliens
    ? `${unclaimedOresForAliens.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })} $ORES`
    : `0.000 $ORES`;

  // Staking
  const stakingPoolContract = useStakingPoolContract();
  const isApprovedForAll = useIsApprovedForAll(
    getMnAAddress(),
    getStakingPoolAddress()
  );

  const { onApprovalForAll } = useApproveForAll(
    MnAContract,
    getStakingPoolAddress()
  );

  const { onMnAStake } = useMnAStake(stakingPoolContract, address);
  const { onOresClaim } = useOresClaim(stakingPoolContract);
  const { onRescue } = useRescueV1(stakingPoolContract);

  // CALLBACK METHODS FOR BUTTONS
  /*const claimOres = async (ids) => {
    try {
      const status = await onOresClaim(ids, false);
      if (status === 1) {
        toast.success(`Claimed $ORES successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Claimed $ORES failed!`);
    }
  };*/

  const rescue = async (ids) => {
    try {
      const status = await onRescue(ids);
      if (status === 1) {
        toast.success(`Rescued ${ids.length} MnA successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Rescuing MnA's failed!`);
    }
  };

  const stakeMarines = async (ids) => {
    try {
      const status = await onMnAStake(ids);
      if (status === 1) {
        toast.success(`Staked ${ids} successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Staked ${ids} failed!`);
    }
  };

  const unstakeMarines = async (ids) => {
    try {
      const status = await onOresClaim(ids, true);
      if (status === 1) {
        toast.success(`Unstaked ${ids} successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Unstake3d ${ids} failed!`);
    }
  };

  return (
    <div>
      <Header title="Minting &amp; Staking" />
      <div className="grid">
        <div className="col-12">
          <MintStatus />
        </div>
      </div>

      <div className="grid">
        <div className="col-6_md-12">
          {/* MARINES MINTING */}
          <StakeCard
            background={Bridge}
            title="Marine Staking"
            img={Marine}
            isApprovedForAll={isApprovedForAll}
            onApprovalForAll={onApprovalForAll}
            staked={stakedMarines}
            unstaked={unstakedMarines}
            isV1={true}
            onClaim={rescue/*claimOres*/}
            onUnstake={unstakeMarines}
            onStake={stakeMarines}
            unclaimed={unclaimedOresForMarinesFormatted}
          />
        </div>

        <div className="col-6_md-12">
          {/* ALIENS MINTING */}
          <StakeCard
            background={Eggs}
            title="Alien Staking"
            img={Alien}
            isApprovedForAll={isApprovedForAll}
            onApprovalForAll={onApprovalForAll}
            staked={stakedAliens}
            unstaked={unstakedAliens}
            isV1={true}
            onClaim={rescue/*claimOres*/}
            onUnstake={unstakeMarines}
            onStake={stakeMarines}
            unclaimed={unclaimedOresForAliensFormatted}
          />
        </div>
      </div>

      {claimable ? (
        <div className="grid">
          <div className="col-12">
            {/* CLAIMING */}
            <h3 className="section-title">CLAIMING</h3>
            <Button
              disabled={claimPendingTx}
              onClick={() => {
                handleClaim();
              }}
              kind="secondary"
            >
              Claim
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
