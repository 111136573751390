import {useState} from "react";
import numeral from 'numeral';
import "./select-button.scss";
import Countdown from "react-countdown";
import {toast} from "react-toastify";
import {useMnAv2Contract, useStakingPoolv3Contract} from "../../../hooks/useContracts";
import {useMnAMetaTrain} from "../../../hooks/useMetaTrain";
import {useMnAResetCoolDown} from "../../../hooks/useResetCooDown";
import NumericInput from 'react-numeric-input';

import sungod from "../../../images/icons/sungod.png";

export default function SelectButton(
    {
        onSelect,
        id,
        isMarine,
        img,
        title,
        rank,
        level,
        selected,
        cost = 0,
        isV1 = false,
        useReserves = true,
        isStaking = false,
        reward = null,
        rarities = undefined,
        disabled = false,
        endTime = null,
        hasCoolDown = false
    }
) {
    const [isSelected, toggleSelected] = useState(false);

    const mnaContract = useMnAv2Contract();
    const stakingPoolContract = useStakingPoolv3Contract();
    const {onMnAMetaTrainV3, onDefinedMnAMetaTrainV3} = useMnAMetaTrain(stakingPoolContract);
    const {onMnAResetCoolDown} = useMnAResetCoolDown(stakingPoolContract);
    const [levelField, setLevelField] = useState(level + 1);

    function fetchRarityScore() {
        if (!rarities) return 0;
        return rarities ? rarities.reduce((a,b) => a + b) : 0
    }

    // ON CLICK FIRE CALLBACK FUNCTION PASSING ID AND TOGGLE SELECTED STATE
    const toggle = () => {
        // ONLY CALL FUNCTIONS IF NOT DISABLED
        if (!disabled) {
            onSelect(id);
            toggleSelected(!isSelected);
        } else {
            if (!isStaking && (!reward || reward < 3)) {
                toast.error(`Your ${isMarine ? "Marine" : "Alien"} has not acquired enough $KLAYE to unstake.`);
            }
        }
    };

    return (
        <div className={`select-button-container ${isStaking ? "unstaked" : ""}`}>
            {isStaking === false ?
                <div className={`klaye-display ${reward >= 3 ? "good" : "bad"}`}>
                    $Klaye: {numeral(reward).format('0.000')}
                </div>
                : null
            }
            <button
                className={
                    `select-button ${selected ? "is-selected" : ""} 
                    ${disabled ? "is-disabled" : ""}
                    }`
                }
                onClick={toggle}
            >
                <strong>ID: {id}</strong>
                {!isMarine ? <em>R{rank}</em> : null}
                {level >= 0 ? <span>LV. {level}</span> : null}
                <strong style={{paddingTop: "10%"}}>GEN: {id <= 6969 ? 0 : 1}</strong>
                <div className={"marine"} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/svg/${id}.svg)`}}/>
                <div className="display-text">Rarity
                    Score: {fetchRarityScore(id)}
                </div>
                {selected && <h4>Selected</h4>}
                {endTime && hasCoolDown ?
                    <>
                        <div className="cooldown-display">
                            Cooldown
                            <Countdown date={endTime} className="countdown-display"/>
                        </div>
                    </>
                    : null
                }
                {(!endTime && cost && cost > 0) ?
                    <h4 className={`upgrade-cost ${cost >= 3 ? "good" : "bad"}`}>
                        {numeral(cost).format('0.000')} $ORE
                    </h4>
                    : null
                }
            </button>
            {isStaking === false ?
                <div className={"info-container"}>
                    <button className={`info-button ${(endTime && hasCoolDown) || level === 70 ? "adjust-width" : ""}`}
                            onClick={async () => {
                                if(level === levelField) return;
                                (endTime
                                    ? await onMnAResetCoolDown([id])
                                    : levelField > level + 1
                                        ? await onDefinedMnAMetaTrainV3(useReserves, isMarine, levelField, id)
                                        : await onMnAMetaTrainV3(useReserves, isMarine, [id]))
                            }}
                    >
                        {(endTime && hasCoolDown
                            ? "Reset CoolDown"
                            : `Train ${isMarine ? "Marine" : "Alien"}`)
                        }
                    </button>
                    {(endTime && hasCoolDown) || level === 70 ? null :
                        (level === 68 || level === 69)
                            ? <img src={sungod} alt={"sungod"} onClick={async() => await onMnAMetaTrainV3(useReserves, isMarine, [id])}/>
                            :
                        <NumericInput
                            className={"input"}
                            style={false}
                            size={1}
                            min={levelField}
                            max={70}
                            value={levelField}
                            step={1}
                            onChange={(e) => {
                                if(e && e >= level + 1) {
                                    setLevelField(e);
                                }
                            }}
                        />
                    }
                </div>
                : null
            }
        </div>
    );
}
