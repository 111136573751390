import Header from "../components/dashboard/header";
import Reaper from "../images/characters/collector.gif";
import Bio from "../components/dashboard/bio";
import ItemCard from "../components/dashboard/item-card";
import WaterEgg from "../images/items/egg-blue.png";
import DarknesEgg from "../images/items/egg-purple.png";
import FireEgg from "../images/items/egg-orange.png";
import PoisonEgg from "../images/items/egg-green.png";
import {
  useDarknessEgg,
  useDarknessEggUser,
  useFireEgg,
  useFireEggUser,
  useMnAUser,
  usePoisonEgg,
  usePoisonEggUser,
  useWaterEgg,
  useWaterEggUser,
} from "../state/hooks";
import { getBalanceNumber } from "../utils/formatBalance";
import BigNumber from "bignumber.js";
import {
  useDarknessEggContract,
  useERC20,
  useFireEggContract,
  useMnAContract,
  usePoisonEggContract,
  useWaterEggContract,
} from "../hooks/useContracts";
import { useAllowance, useIsApprovedForAll } from "../hooks/useAllowance";

import {
  getDarknessEggAddress,
  getFireEggAddress,
  getMnAAddress,
  getORESAddress,
  getPoisonEggAddress,
  getWaterEggAddress,
} from "../utils/addressHelpers";
import { useApprove, useApproveForAll } from "../hooks/useApprove";
import { usePurchase } from "../hooks/usePurchase";
import { usePurchaseWithMnA } from "../hooks/usePurchaseWithMnA";
import {useWeb3Context} from "../hooks/web3Context";
import {useAccount} from "wagmi";

export default function Collector(props) {
  const quote =
    "I striketh a bargain with thee Offer $ORES or thy soul to taketh an elemental egg A small price to pay for war.";

  const { address, connected, connect } = useAccount();

  const oresContract = useERC20(getORESAddress());
  const mnaContract = useMnAContract();
  const waterEgg = useWaterEgg();
  const { claimed: waterEggClaimed } = useWaterEggUser();
  const fireEgg = useFireEgg();
  const { claimed: fireEggClaimed } = useFireEggUser();
  const darknessEgg = useDarknessEgg();
  const { claimed: darknessEggClaimed } = useDarknessEggUser();
  const poisonEgg = usePoisonEgg();
  const { claimed: poisonEggClaimed } = usePoisonEggUser();
  const mnaUser = useMnAUser();
  const tokens = mnaUser.tokens;
  const marines = tokens.filter((token) => token.isMarine);
  const aliens = tokens.filter((token) => !token.isMarine);
  const waterEggContract = useWaterEggContract();
  const fireEggContract = useFireEggContract();
  const darknessEggContract = useDarknessEggContract();
  const poisonEggContract = usePoisonEggContract();

  // WaterEgg
  const waterEggAllowance = useAllowance(
    getORESAddress(),
    getWaterEggAddress()
  );
  const isWaterEggApproved =
      address &&
    waterEggAllowance &&
    new BigNumber(waterEggAllowance.toString()).isGreaterThan(0);
  const { onApprove: onApproveForWaterEgg } = useApprove(
    oresContract,
    getWaterEggAddress()
  );

  const { onPurchase: onPurchaseWaterEgg } = usePurchase(waterEggContract);

  // FireEgg
  const fireEggAllowance = useAllowance(getORESAddress(), getFireEggAddress());
  const isFireEggApproved =
      address &&
    fireEggAllowance &&
    new BigNumber(fireEggAllowance.toString()).isGreaterThan(0);
  const { onApprove: onApproveForFireEgg } = useApprove(
    oresContract,
    getFireEggAddress()
  );
  const { onPurchase: onPurchaseFireEgg } = usePurchase(fireEggContract);

  // DarknessEgg
  const darknessEggAllowance = useAllowance(
    getORESAddress(),
    getDarknessEggAddress()
  );
  const isDarknessEggApproved =
      address &&
    darknessEggAllowance &&
    new BigNumber(darknessEggAllowance.toString()).isGreaterThan(0);
  const isApprovedForAllForDarknessEgg = useIsApprovedForAll(
    getMnAAddress(),
    getDarknessEggAddress()
  );
  const { onApprove: onApproveForDarknessEgg } = useApprove(
    oresContract,
    getDarknessEggAddress()
  );
  const { onApprovalForAll: onApproveAllForDarknessEgg } = useApproveForAll(
    mnaContract,
    getDarknessEggAddress()
  );
  const { onPurchaseWithMnA: onPurchaseDarknessEgg } =
    usePurchaseWithMnA(darknessEggContract);

  // PoisonEgg
  const poisonEggAllowance = useAllowance(
    getORESAddress(),
    getPoisonEggAddress()
  );
  const isPoisonEggApproved =
      address &&
    poisonEggAllowance &&
    new BigNumber(poisonEggAllowance.toString()).isGreaterThan(0);
  const isApprovedForAllForPoisonEgg = useIsApprovedForAll(
    getMnAAddress(),
    getPoisonEggAddress()
  );
  const { onApprove: onApproveForPoisionEgg } = useApprove(
    oresContract,
    getPoisonEggAddress()
  );
  const { onApprovalForAll: onApproveAllForPoisonEgg } = useApproveForAll(
    mnaContract,
    getPoisonEggAddress()
  );

  const { onPurchaseWithMnA: onPurchasePoisonEgg } =
    usePurchaseWithMnA(poisonEggContract);

  const waterEggsRemaining = waterEgg.MAX_MINT - waterEgg.totalMinted;
  const fireEggsRemaining = fireEgg.MAX_MINT - fireEgg.totalMinted;
  const poisonEggsRemaining = poisonEgg.MAX_MINT - poisonEgg.totalMinted;
  const darknessEggsRemaining = darknessEgg.MAX_MINT - darknessEgg.totalMinted;
  const waterEggPrice = getBalanceNumber(new BigNumber(waterEgg.mintCost), 18);
  const waterEggPriceFormatted = `${waterEggPrice.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })} $ORES`;

  const fireEggPrice = getBalanceNumber(new BigNumber(fireEgg.mintCost), 18);
  const fireEggPriceFormatted = `${fireEggPrice.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })} $ORES`;

  const poisonEggPrice = getBalanceNumber(
    new BigNumber(poisonEgg.mintCost),
    18
  );
  const poisonEggPriceFormatted = `${poisonEggPrice.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })} $ORES`;

  const darknessEggPrice = getBalanceNumber(
    new BigNumber(darknessEgg.mintCost),
    18
  );
  const darknessEggPriceFormatted = `${darknessEggPrice.toLocaleString(
    undefined,
    {
      maximumFractionDigits: 0,
    }
  )} $ORES`;

  return (
    <div>
      <Header title="The Collector" />
      <Bio img={Reaper} alt="Collector" quote={quote} />

      <div className="grid">
        <div className="col-12">
          <h3 className="section-title">Collection</h3>
        </div>

        <div className="col-6_md-12">
          <div className="l-pad-right-2">
            <ItemCard
              img={WaterEgg}
              name="Water Egg"
              remaining={waterEggsRemaining}
              price={waterEggPriceFormatted}
              onPurchase={onPurchaseWaterEgg}
              isApproved={isWaterEggApproved}
              onApprove={onApproveForWaterEgg}
              claimed={waterEggClaimed}
            />
          </div>
        </div>

        <div className="col-6_md-12">
          <div className="l-pad-left-2">
            <ItemCard
              img={FireEgg}
              name="Fire Egg"
              remaining={fireEggsRemaining}
              price={fireEggPriceFormatted}
              onPurchase={onPurchaseFireEgg}
              isApproved={isFireEggApproved}
              onApprove={onApproveForFireEgg}
              claimed={fireEggClaimed}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="grid">
        <div className="col-12">
          <h3 className="section-title">Soul Harvest</h3>
        </div>

        <div className="col-6_md-12">
          <div className="l-pad-right-2">
            <ItemCard
              sacrafice={true}
              img={DarknesEgg}
              name="Darkness Egg"
              aliens={aliens}
              remaining={darknessEggsRemaining}
              price={`${darknessEggPriceFormatted} + GEN 1 ALIEN`}
              onPurchase={onPurchaseDarknessEgg}
              isApproved={isDarknessEggApproved}
              onApprove={onApproveForDarknessEgg}
              isApproveForAll={isApprovedForAllForDarknessEgg}
              onApproveForAll={onApproveAllForDarknessEgg}
              claimed={darknessEggClaimed}
              disabled={true}
            />
          </div>
        </div>

        <div className="col-6_md-12">
          <div className="l-pad-left-2">
            <ItemCard
              sacrafice={true}
              img={PoisonEgg}
              marines={marines}
              name="Poison Egg"
              remaining={poisonEggsRemaining}
              price={`${poisonEggPriceFormatted} + GEN 1 MARINE`}
              onPurchase={onPurchasePoisonEgg}
              isApproved={isPoisonEggApproved}
              onApprove={onApproveForPoisionEgg}
              isApproveForAll={isApprovedForAllForPoisonEgg}
              onApproveForAll={onApproveAllForPoisonEgg}
              claimed={poisonEggClaimed}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
