import { useCallback } from "react";

export const useGenClaim = (mnAContract) => {
  const handleGenClaim = useCallback(async () => {
    try {
      const tx = await mnAContract.claim();
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [mnAContract]);

  return { onGenClaim: handleGenClaim };
};
