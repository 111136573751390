import {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import {useDispatch} from "react-redux";
import Home from "./pages/home";
import Stats from "./pages/stats";
import Minting from "./pages/minting";
import Collector from "./pages/collector";
import Vault from "./pages/vault";

import Swap from "./pages/swap";
import Bootcamp from "./pages/bootcamp";
import Klaye from "./pages/klaye";
import Incubator from "./pages/incubator"

import Dashboard from "./layouts/dashboard";
import {
    useFetchDarknessEggPublicData,
    useFetchFireEggPublicData,
    useFetchFounderPassPublicData,
    useFetchMnAGamePublicData,
    useFetchMnAPublicData,
    useFetchMnAv2PublicData,
    useFetchPoisonEggPublicData,
    useFetchPoolsPublicData,
    useFetchSpidoxPublicData,
    useFetchWaterEggPublicData,
} from "./state/hooks";
import useRefresh from "./hooks/useRefresh";
import {fetchFounderPassUserDataAsync} from "./state/founderPass";
import {fetchMnAUserDataAsync} from "./state/mnA";
import {fetchMnAv2UserDataAsync} from "./state/mnAv2";
import {fetchMnAGameUserDataAsync} from "./state/mnAGameCR";
import {fetchSpidoxUserDataAsync} from "./state/spidox";
import {fetchPoolsUserDataAsync} from "./state/rewardPool";
import {fetchWaterEggUserDataAsync} from "./state/wateregg";
import {fetchFireEggUserDataAsync} from "./state/fireegg";
import {fetchDarknessEggUserDataAsync} from "./state/darknessegg";
import {fetchPoisonEggUserDataAsync} from "./state/poisonegg";
import {useWeb3Context} from "./hooks/web3Context";
import {fetchOreReimbursementDataAsync} from "./state/oreReimbursement";
import {useOreReimbursementContract} from "./hooks/useContracts";
import {chains} from "./hooks/wagmi";

import {
    cssStringFromTheme,
    darkTheme as rainbowDarkTheme, lightTheme,
    RainbowKitProvider, Theme,
} from "@rainbow-me/rainbowkit";
import CustomAvatar from "./components/sidenav/WalletAvatar";
import {useAccount, useSigner} from "wagmi";
import {fetchIncubatorUserDataAsync} from "./state/protoss/protoss-state";
import BossBattles from "./pages/battles/incubator";
import {fetchBossBattleUserDataAsync} from "./state/battles/battles-state";

export default function App() {
    /*useEagerConnect(DEFAULT_CHAIN_ID);*/
    useFetchFounderPassPublicData();
    useFetchMnAPublicData();
    useFetchMnAGamePublicData();
    useFetchSpidoxPublicData();
    useFetchPoolsPublicData();
    useFetchWaterEggPublicData();
    useFetchFireEggPublicData();
    useFetchDarknessEggPublicData();
    useFetchPoisonEggPublicData();
    useFetchMnAv2PublicData();

    const dispatch = useDispatch();
    const {fastRefresh} = useRefresh();

    const {
        connect,
        hasCachedProvider,
        networkId,
        providerInitialized,
    } = useWeb3Context();


    const { address, isConnected } = useAccount();
    const { data: signer } = useSigner();

    const [walletChecked, setWalletChecked] = useState(false);

    useEffect(() => {
        if (hasCachedProvider()) {
            // then user DOES have a wallet
            connect().then(() => {
                setWalletChecked(true);
            }).catch((error) => {
                // rejection
                console.error(error);
            });
        } else {
            // then user DOES NOT have a wallet
            setWalletChecked(true);
        }
    }, []);

    useEffect(() => {
        if (walletChecked) {
            if (networkId !== -1) {
                // future placeholder for loading data
            }
        }
    }, [walletChecked, networkId]);

    useEffect(() => {
        if (isConnected && providerInitialized) {
            // future placeholder for loading account data
        }
    }, [isConnected, networkId, providerInitialized]);

    const oreReimbursementContract = useOreReimbursementContract()

    useEffect(() => {
        if (address) {
            dispatch(fetchFounderPassUserDataAsync(address));
            dispatch(fetchMnAUserDataAsync(address));
            dispatch(fetchMnAGameUserDataAsync(address));
            dispatch(fetchSpidoxUserDataAsync(address));
            dispatch(fetchPoolsUserDataAsync(address));
            dispatch(fetchWaterEggUserDataAsync(address));
            dispatch(fetchFireEggUserDataAsync(address));
            dispatch(fetchOreReimbursementDataAsync(oreReimbursementContract, address));
            dispatch(fetchDarknessEggUserDataAsync(address));
            dispatch(fetchPoisonEggUserDataAsync(address));
            dispatch(fetchMnAv2UserDataAsync(address));
            if(signer) {
                dispatch(fetchIncubatorUserDataAsync(address, signer))
                dispatch(fetchBossBattleUserDataAsync(address, signer))
            }
        }
    }, [address, dispatch, fastRefresh]);

    return (
        <>
            <RainbowKitProvider
                appInfo={{
                    appName: "SpaceGame"
                }}
                chains={chains}
                avatar={CustomAvatar}
                theme={rainbowDarkTheme()}
                initialChain={chains[0]}
                showRecentTransactions={true}
            >
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/dashboard" element={<Dashboard/>}>
                        <Route index element={<Stats/>}/>
                        <Route path="minting" element={<Minting/>}/>
                        <Route path="collector" element={<Collector/>}/>
                        <Route path="vault" element={<Vault/>}/>
                        <Route path="incubator" element={<Incubator/>}/>
                        <Route path="battles" element={<BossBattles/>}/>
                        <Route path="swap" element={<Swap/>}/>
                        {/*<Route path="training" element={<Training />} />*/}
                        <Route path="bootcamp" element={<Bootcamp/>}/>
                        <Route path="claim" element={<Klaye/>}/>
                    </Route>
                </Routes>
            </RainbowKitProvider>
        </>
    );
}
