import { useCallback } from "react";

export const useMintReveal = (mnaGameCRContract) => {
  const handleMintReveal = useCallback(async () => {
    try {
      const tx = await mnaGameCRContract.mintReveal();
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [mnaGameCRContract]);

  return { onMintReveal: handleMintReveal };
};
