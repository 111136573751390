const pools = [
  {
    name: "$ORES-WETH",
    isNativePool: false,
    isLpPool: false,
    address: {
      4: "",
      137: "0x60E36D0091BC46E44950b2EAc4dD2AF4701A535A",
      31337: "0x60E36D0091BC46E44950b2EAc4dD2AF4701A535A",
    },
    stakeToken: {
      4: "",
      137: "0xC3252C1ada2334CaB9d0bDbD47132680d1F6B410",
      31337: "0xC3252C1ada2334CaB9d0bDbD47132680d1F6B410",
    },
    stakeTokenSymbol: "$ORES-WETH",
    stakeTokenDecimal: 18,
    rewardToken: {
      4: "",
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      31337: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
    rewardTokenSymbol: "USDT",
    rewardTokenDecimal: 6,
    lockingDuration: 0,
  },
  {
    name: "$KLAYE",
    isNativePool: false,
    isLpPool: false,
    address: {
      4: "",
      137: "0xeFf2D62c65AB7d5119920781Da202c80fd7B6e84",
      31337: "0xeFf2D62c65AB7d5119920781Da202c80fd7B6e84",
    },
    stakeToken: {
      4: "",
      137: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
      31337: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
    },
    stakeTokenSymbol: "$KLAYE",
    stakeTokenDecimal: 18,
    rewardToken: {
      4: "",
      137: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
      31337: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
    },
    rewardTokenSymbol: "$ORES",
    rewardTokenDecimal: 18,
    lockingDuration: 0,
  },
  {
    name: "$KLAYE-WETH",
    isNativePool: false,
    isLpPool: false,
    address: {
      4: "",
      137: "0xA5D5Bc5a9bC4d19Fbb591dCB4d41cdB096E5157E",
      31337: "0xA5D5Bc5a9bC4d19Fbb591dCB4d41cdB096E5157E",
    },
    stakeToken: {
      4: "",
      137: "0xd3af04D7C976083c1288759D0A91a4573922232c",
      31337: "0xd3af04D7C976083c1288759D0A91a4573922232c",
    },
    stakeTokenSymbol: "$KLAYE-WETH",
    stakeTokenDecimal: 18,
    rewardToken: {
      4: "",
      137: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
      31337: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
    },
    rewardTokenSymbol: "$ORES",
    rewardTokenDecimal: 18,
    lockingDuration: 0,
  },
];

export default pools;
