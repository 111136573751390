import Stars from "../../images/backgrounds/ep2-stars.png";

// IMPORTS
import "./incubator.scss";
import water from "../../images/items/egg-blue.png";
import fire from "../../images/items/egg-orange.png";
import poison from "../../images/items/egg-green.png";
import darkness from "../../images/items/egg-purple.png";
import collector from "../../images/bosses/collector.gif";
import tanto from "../../images/bosses/tanto.png";
import elmyra from "../../images/bosses/elmyra.gif";
import razor from "../../images/bosses/razor.png";
import necron from "../../images/bosses/necron.gif";
import ores from "../../images/characters/ores.png";
import marine from "../../images/characters/marine.png";
import alien from "../../images/characters/alien.png";
import protoss from "../../images/characters/protoss.svg";
import Button from "../../components/button";
import {useEffect, useState} from "react";
import {
    useBossBattleContract,
    useDarknessEggContract,
    useERC20,
    useFireEggContract,
    useIncubatorContract,
    useMnAv2Contract,
    usePoisonEggContract,
    useProtossContract,
    useWaterEggContract
} from "../../hooks/useContracts";
import {
    useBossBattlesUser,
    useDarknessEggUser,
    useFireEggUser,
    useIncubatorUser,
    useMnAv2User,
    usePoisonEggUser,
    useWaterEggUser
} from "../../state/hooks";
import {numberWithCommas} from "../../helpers/numbers";
import numeral from "numeral";
import {useAccount} from "wagmi";
import {useIncubator} from "../../hooks/useIncubator";
import {toast} from "react-toastify";
import Countdown from "react-countdown";
import {useAllowance, useIsApprovedForAll} from "../../hooks/useAllowance";
import {
    getBossBattleAddress,
    getDarknessEggAddress,
    getFireEggAddress,
    getIncubatorAddress,
    getMnAv2Address,
    getORESV2Address,
    getPoisonEggAddress, getProtossAddress,
    getWaterEggAddress
} from "../../utils/addressHelpers";
import BigNumber from "bignumber.js";
import {useApprove, useApproveForAll} from "../../hooks/useApprove";
import {formatNumber} from "../../helpers";
import {useAccountTokenBalance, useTokenBalance} from "../../hooks/useTokenBalance";
import Header from "../../components/dashboard/header";
import {useEnlistInBattle} from "../../hooks/useEnlistInBattle";

const incubator_metadata = [
    {
        type: "Water",
        unlockCost: 100000,
        marineCount: 1,
        alienCount: 0
    }
]

interface EntitySelection {
    isMarine: boolean,
    isProtoss: boolean | undefined,
    id: number | undefined,
    level: number | undefined,
    metadata?: string | undefined
}

interface IncubatorSelection {
    primaryId: number | undefined,
    sacrifices: EntitySelection[]
}

interface IRewards {
    ore: number;
    klaye: number;
    elgoresyte: number;
}

interface IncubatorDefinition {
    displayImg: string,
    displayName: string,
    type: string,
    id: number,
    unlockCost: number,
    rewards: IRewards,
    selector: IncubatorSelection
}

const Incubators: IncubatorDefinition[] = [
    {
        displayImg: collector,
        displayName: "Collector",
        type: collector,
        id: 0,
        unlockCost: 25000,
        rewards: {
            ore: 3000,
            klaye: 100,
            elgoresyte: 1
        },
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: tanto,
        displayName: "Tanto",
        type: tanto,
        id: 1,
        unlockCost: 50000,
        rewards: {
            ore: 6000,
            klaye: 250,
            elgoresyte: 5
        },
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: razor,
        displayName: "Razor",
        type: razor,
        id: 2,
        unlockCost: 50000,
        rewards: {
            ore: 6000,
            klaye: 250,
            elgoresyte: 5
        },
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: elmyra,
        displayName: "Elmyra",
        type: elmyra,
        id: 3,
        unlockCost: 75000,
        rewards: {
            ore: 10000,
            klaye: 400,
            elgoresyte: 10
        },
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: true,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    },
    {
        displayImg: necron,
        displayName: "Necron",
        type: necron,
        id: 4,
        unlockCost: 100000,
        rewards: {
            ore: 25000,
            klaye: 1000,
            elgoresyte: 25
        },
        selector: {
            primaryId: undefined,
            sacrifices: [
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: true,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: false,
                    id: undefined,
                    level: undefined
                },
                {
                    isMarine: false,
                    isProtoss: true,
                    id: undefined,
                    level: undefined
                }
            ]
        }
    }
]

export default function BossBattles() {
    const [selectedStakedItems, setSelectedStakedItems] = useState([]);
    const [selectEntity, setSelectEntity] = useState(false);

    const [selectedIncubator, setSelectedIncubator] = useState<IncubatorDefinition | undefined>(undefined);

    const [selectMnAIndex, setSelectMnAIndex] = useState(0);
    const [selectMnAType, setSelectMnAType] = useState(0);
    const [useReserves, setUseReserves] = useState(true)
    const [pendingTx, setPendingTx] = useState(false)
    const [update, setUpdate] = useState(false)

    const {address} = useAccount();

    const incubatorUser = useIncubatorUser()
    const battleUser = useBossBattlesUser()

    // claim action

    const MnAContract = useMnAv2Contract();
    const protossContract = useProtossContract();
    const oresContract = useERC20(getORESV2Address());

    const oreAllowance = useAllowance(
        getORESV2Address(),
        getBossBattleAddress()
    );
    const isOreApproved =
        address &&
        oreAllowance &&
        new BigNumber(oreAllowance.toString()).isGreaterThan(0);
    const {onApprove: onApproveOre} = useApprove(
        oresContract,
        getBossBattleAddress()
    );

    const isMnAApprovedForAll = useIsApprovedForAll(
        getMnAv2Address(),
        getBossBattleAddress()
    );

    const {onApprovalForAll: onMnAApprove} = useApproveForAll(
        MnAContract,
        getBossBattleAddress()
    );

    const {onApprovalForAll: onProtossApprove} = useApproveForAll(
        protossContract,
        getBossBattleAddress()
    );

    const isProtossApprovedForAll = useIsApprovedForAll(
        getProtossAddress(),
        getBossBattleAddress()
    );

    // MnA
    const mnAv2User = useMnAv2User();

    const oreBalance = useTokenBalance(getORESV2Address());
    const formattedOreBalance = oreBalance
        ? `${oreBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $ORE`
        : `0.000 $ORE`;

    const klayeBalance = useAccountTokenBalance(1);
    const formattedKlayeBalance = klayeBalance
        ? `${klayeBalance.toLocaleString(undefined, {
            maximumFractionDigits: 3,
        })} $KLAYE`
        : `0.000 $KLAYE`;

    function hasEnoughOre() {
        return !selectedIncubator?.unlockCost ? true : oreBalance >= selectedIncubator?.unlockCost
    }

    const incubationContract = useIncubatorContract()
    const bossBattlesContract = useBossBattleContract()

    useEffect(() => {

    }, [oreBalance, battleUser.activeBattleRecords, battleUser.previousBattleRecords, isMnAApprovedForAll, isProtossApprovedForAll, isOreApproved])

    const {onEnlist, onFinalize} = useEnlistInBattle(bossBattlesContract, protossContract)

    function fetchRarityScore(id: number) {
        if (!incubatorUser || !incubatorUser.tokens) return 0;
        let record = incubatorUser.tokens.filter((token: { id: number }) => token.id === id)[0]
        let rarities = record?.rarities
        return rarities ? rarities[0].reduce((a: number, b: number) => a + b) : 0
    }

    function fetchActiveBattleRecords() {
        return battleUser.activeBattleRecords
    }

    const submitIncubation = async () => {
        try {
            setPendingTx(true)
            const marines = selectedIncubator?.selector.sacrifices.filter((s) => s.isMarine).map((s) => s.id)
            const aliens = selectedIncubator?.selector.sacrifices.filter((s) => !s.isMarine && !s.isProtoss).map((s) => s.id)
            const protoss = selectedIncubator?.selector.sacrifices.filter((s) => s.isProtoss).map((s) => s.id)

            const status = await onEnlist(selectedIncubator!.id, marines, aliens ? aliens : [], protoss ? protoss : []);
            if (status === 1) {
                toast.success(`Successfully enlisted in battle!`);
            }
            setPendingTx(false)
            return status
        } catch (e) {
            setPendingTx(false)
            console.error(e);
            toast.error(`Failed to enlist troops!`);
        }
    }

    const stakedMarines = mnAv2User.tokens?.filter(
        (stakedToken: { id: number, isMarine: any; }) => stakedToken.isMarine
    );

    const stakedAliens = mnAv2User.tokens?.filter(
        (stakedToken: { id: number, isMarine: any; }) => !stakedToken.isMarine
    );

    function availableMarines() {
        return stakedMarines && stakedMarines?.filter((x: any) => {
            return !selectedIncubator?.selector.sacrifices.find((s: any) => s.id === x.id)
        })
    }

    function availableAliens() {
        return stakedAliens && stakedAliens?.filter((x: any) => {
            return !selectedIncubator?.selector.sacrifices.find((s: any) => s.id === x.id)
        })
    }

    function availableProtoss() {
        return battleUser && battleUser.ownedProtoss && battleUser.ownedProtoss?.filter((x: any) => {
            return !selectedIncubator?.selector.sacrifices.find((s: any) => s.id === x.id)
        })
    }

    function findMetadata(id: number) {
        return battleUser && battleUser.ownedProtoss && battleUser.ownedProtoss?.find((x: any) => x.id === id)?.metadata
    }

    return (
        <div>
            <div className="l-space-bottom-4">
                <Header title="Boss Battles" background={Stars}/>
            </div>

            <div className="incubator incubator-bg">
                <div className="boss-container">
                    <div className="grid-middle-align-center">
                        <table>
                            <tbody>
                            <tr>
                                {Incubators.map((incubator, index) => {
                                    let first = index === 0;
                                    let last = index === Incubators.length - 1;
                                    let isMiddle = !first && !last;
                                    return (
                                        <td colSpan={1} key={index}>
                                            <div className={`battle-button-container`}>
                                                <button
                                                    className={`select-button undecorated`}
                                                    onClick={() => {
                                                        setSelectedIncubator(incubator)
                                                    }}
                                                >
                                                    <div className="boss-image"
                                                         style={{
                                                             backgroundImage: `url(${incubator?.displayImg})`
                                                         }}
                                                    >
                                                        <div className="display-box">
                                                            <div className="unlock-cost-text">
                                                                <div
                                                                    className="ore-text">{numberWithCommas(numeral(incubator.unlockCost).format("0"))}</div>
                                                                <div className="ore-text"><img src={ores}/></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="incubator">
                <div className={`incubator-button-container`}>
                    {!selectedIncubator ?
                        <div style={{justifyContent: "center", textAlign: "center"}}>
                            <span className="display-text pos-top">Select a boss to get started</span>
                        </div>
                        : !selectEntity
                            ? <div style={{textAlign: "center", display: "block"}}>
                                <div style={{textAlign: "center"}}>
                                    <span>Battling </span>
                                    <span style={{color: "#B9EA2B"}}>{selectedIncubator?.displayName} </span>
                                    <span>will cost </span>
                                    <span style={{
                                        color: "red",
                                        textDecoration: "underline"
                                    }}> {numberWithCommas(numeral(selectedIncubator?.unlockCost).format("0"))} </span>
                                    <span style={{
                                        color: "#B9EA2B",
                                        textDecoration: "underline",
                                        textDecorationColor: "red"
                                    }}> $ORE</span>
                                </div>
                                < br/>
                                <div style={{textAlign: "center"}}>
                                    <span style={{color: "green"}}>Rarity Score:</span>
                                    <span> This is the recorded rarity of each of your individual assets traits combined. The </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>lower</span>
                                    <span> the number, the </span>
                                    <span style={{color: "red", textDecoration: "underline"}}>better</span>
                                    <span> the assets rarity score is.</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className={`incubator-info-content`} style={{display: `${selectEntity ? "block" : "flex"}`}}>
                        {!selectEntity
                            ? <button className={`select-button`}>
                                {!selectedIncubator ?
                                    <span className="display-text pos-top" style={{textAlign: "center"}}>Select a a boss to get started</span> : null}
                                <div className={"selection-image top-margin"}
                                     style={{
                                         filter: `${!selectedIncubator ? "grayscale(80%)" : "none"}`,
                                         backgroundImage: `url(${!selectedIncubator ? collector : selectedIncubator.type})`
                                     }}/>
                            </button>
                            : null
                        }
                        {!selectEntity ? (
                                selectedIncubator?.selector.sacrifices.map((item: EntitySelection, index) => {
                                    return (
                                        <div key={index} className={"display-box"}>
                                            <button
                                                className={`select-button ${selectedIncubator.selector.primaryId && selectedIncubator.selector.primaryId === item.id ? "primary-selection" : ""}`}
                                                onClick={() => {
                                                    setSelectMnAIndex(index)
                                                    setSelectMnAType(item.isMarine ? 0 : item.isProtoss ? 2 : 1)
                                                    setSelectEntity(true)
                                                }}
                                            >
                                                {!item.id ?
                                                    <span
                                                        className="display-text pos-top">Click to select a sacrifice</span> : null}
                                                {!item.id ?
                                                    <div className={"selection-image top-margin"}
                                                         style={{
                                                             filter: `${!item.id ? "grayscale(80%)" : "none"}`,
                                                             backgroundImage: `url(${item.isMarine ? marine : item.isProtoss ? protoss : alien})`
                                                         }}/>
                                                    :
                                                    <div className={"selection-image top-margin"}>
                                                        <strong>ID: {item.id}</strong>
                                                        <span>LV. {item.level}</span>
                                                        <div className={`${selectMnAType === 2 ? 'protoss-image' : 'mna-image'}`}
                                                             style={{backgroundImage: item.isProtoss ? `url(${findMetadata(item.id)})` : `url(${process.env.PUBLIC_URL}/svg/${item.id}.svg)`}}
                                                        />
                                                        {item.isProtoss ? null : <div className="display-text">Rarity
                                                            Score: {fetchRarityScore(item.id)}</div>
                                                        }
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    )
                                }))
                            : (<>
                                <div className="incubator-button-container">
                                    <div className="grid-middle">
                                        <div className="col-1_md-3 back-selector">
                                            <h4 className="hover-cursor" onClick={() => {
                                                setSelectMnAIndex(0)
                                                setSelectMnAType(0)
                                                setSelectEntity(false)
                                            }}>
                                                {"<< Back"}
                                            </h4>
                                        </div>
                                        <div className="col-10_md-12">
                                            <h4>Select a {selectMnAType === 0 ? "Marine" : selectMnAType === 1 ? "Alien" : "Protoss"} to send to battle</h4>
                                        </div>
                                    </div>
                                    {(selectMnAType === 0 ? availableMarines() : selectMnAType === 1 ? availableAliens() : availableProtoss()).map((item: any, index: number) => {
                                        return (
                                            <button key={index} className={`select-button`}
                                                    onClick={() => {
                                                        let selection = selectedIncubator?.selector.sacrifices.at(selectMnAIndex);
                                                        if (selection) {
                                                            selection.id = item.id;
                                                            selection.level = item.level;
                                                            if(!item.isProtoss)
                                                                selection.isMarine = item.isMarine;
                                                            else {
                                                                selection.isProtoss = item.isProtoss;
                                                                selection.metadata = item.metadata
                                                            }
                                                        }
                                                        setSelectMnAIndex(0)
                                                        setSelectEntity(false)
                                                    }}
                                            >
                                                <strong>ID: {item.id}</strong>
                                                <span>LV. {item.level}</span>
                                                <div className={`${selectMnAType === 2 ? 'protoss-image' : 'mna-image'}`}
                                                     style={{backgroundImage: selectMnAType !== 2 ? `url(${process.env.PUBLIC_URL}/svg/${item.id}.svg)` : `url(${item.metadata})`}}
                                                />
                                                ${selectMnAType === 2 ? null :
                                                <div className="display-text">Rarity
                                                    Score: {fetchRarityScore(item.id)}</div>
                                            }
                                            </button>
                                        )
                                    })
                                    }
                                </div>
                            </>)
                        }
                    </div>
                    {selectedIncubator
                        ?
                        <div style={{justifyContent: "center", textAlign: "center"}}>
                            <div style={{fontSize: '24px'}}>
                                <span>There is a </span>
                                <span style={{color: "red", textDecoration: "underline"}}>50% chance</span>
                                <span> that a </span>
                                <span style={{color: "red", textDecoration: "underline"}}>single</span>
                                <span> Marine will be burnt and a </span>
                                <span style={{color: "red", textDecoration: "underline"}}>15% chance</span>
                                <span> that an Alien will be burnt </span>
                                <span style={{color: "red", textDecoration: "underline"}}>per</span>
                                <span> Boss Battle</span>
                            </div>
                            <br/>
                            <br/>
                            <span style={{
                                color: "#B9EA2B",
                                textDecoration: "underline",
                                textDecorationColor: "red"
                            }}> Rarity Scores</span>
                            <span> are used to </span>
                            <span style={{color: "red", textDecoration: "underline"}}>determine</span>
                            <span> the amount of damage per hour your assets will inflict.</span>
                            <br/>
                            <br/>
                            <span style={{color: "red", textDecoration: "underline"}}>Rarity score is NOT calculated from OpenSea.</span>
                        </div>
                        : null
                    }
                    <div
                        style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "12px"}}>
                        {selectedIncubator
                            ? <>
                                <div style={{justifyContent: "center", textAlign: "center", paddingBottom: "16px"}}>
                                    <div style={{justifyContent: "center", textAlign: "center"}}>
                                            <br/>
                                            <span>You will be need to </span>
                                            <span style={{color: "red", textDecoration: "underline"}}>approve</span>
                                            <span> the battle contract to use your MnA, Protoss, and $ORE</span>
                                            <br/>
                                            <br/>
                                            <span style={{
                                                color: "#B9EA2B"
                                            }}>$ORE</span>
                                            <span> balance: {formattedOreBalance}</span>
                                            <br/>
                                        </div>
                                </div>
                                {!isOreApproved
                                    ?
                                    <Button
                                        onClick={async () => {
                                            try {
                                                if (isOreApproved) return;
                                                setPendingTx(true)
                                                let status = await onApproveOre()
                                                if (status === 1) {
                                                    toast.success("Approved $ORE Successfully!");
                                                }
                                                setPendingTx(false)
                                            } catch (e) {
                                                console.error(e);
                                                toast.error("Approval Failed!");
                                                setPendingTx(false)
                                            }
                                        }}
                                        kind={"primary"}
                                        to={undefined}
                                        href={undefined}
                                        space={undefined}
                                        block={undefined}
                                        disabled={!!isOreApproved || pendingTx}
                                    >
                                        Approve $ORE
                                    </Button>
                                    :
                                    isMnAApprovedForAll
                                        ? isProtossApprovedForAll ?
                                            <Button
                                                onClick={async () => {
                                                    let status;
                                                    try {
                                                        setPendingTx(true)
                                                        status = await submitIncubation()
                                                        setPendingTx(false)
                                                    } catch (e) {
                                                        console.error(e);
                                                        toast.error("Approval Failed!");
                                                        setPendingTx(false)
                                                    } finally {
                                                        if (status !== 1) return
                                                        selectedIncubator.selector.sacrifices = selectedIncubator.selector.sacrifices.map((s) => {
                                                            return {
                                                                isMarine: s.isMarine,
                                                                isProtoss: undefined,
                                                                id: undefined,
                                                                level: undefined
                                                            }
                                                        })
                                                        selectedIncubator.selector.primaryId = undefined
                                                    }
                                                }}
                                                kind={"primary"}
                                                to={undefined}
                                                href={undefined}
                                                space={undefined}
                                                block={undefined}
                                                disabled={pendingTx || !selectedIncubator?.selector.sacrifices.every((s) => s.id)}
                                            >
                                                Enlist In Battle
                                            </Button>
                                            : <Button
                                                onClick={async () => {
                                                    try {
                                                        setPendingTx(true)
                                                        let status = await onProtossApprove()
                                                        if (status === 1) {
                                                            toast.success("Protoss Approved!")
                                                        }
                                                        setPendingTx(false)
                                                    } catch (e) {
                                                        console.error(e);
                                                        toast.error("Approval Failed!");
                                                        setPendingTx(false)
                                                    }
                                                }}
                                                kind={"primary"}
                                                to={undefined}
                                                href={undefined}
                                                space={undefined}
                                                block={undefined}
                                                disabled={pendingTx || isProtossApprovedForAll}
                                            >
                                                Approve Protoss
                                            </Button>
                                        : <Button
                                            onClick={async () => {
                                                try {
                                                    setPendingTx(true)
                                                    let status = await onMnAApprove()
                                                    if (status === 1) {
                                                        toast.success("MnA Approved!")
                                                    }
                                                    setPendingTx(false)
                                                } catch (e) {
                                                    console.error(e);
                                                    toast.error("Approval Failed!");
                                                    setPendingTx(false)
                                                }
                                            }}
                                            kind={"primary"}
                                            to={undefined}
                                            href={undefined}
                                            space={undefined}
                                            block={undefined}
                                            disabled={pendingTx || isMnAApprovedForAll}
                                        >
                                            Approve Marines and Aliens
                                        </Button>
                                }
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className="incubator">
                <div className={`incubator-button-container`}>
                    {fetchActiveBattleRecords()?.length === 0
                        ? null :
                        <div style={{justifyContent: "center", textAlign: "center"}}>
                            <span>Active Battles</span>
                        </div>
                    }
                    <div className="incubator-info-content">
                        <div className="incubator-button-container protoss">
                            {fetchActiveBattleRecords()?.length === 0
                                ? <p>No Battle Records Found</p>
                                : (fetchActiveBattleRecords()?.map((record: any, index: number) => {
                                    return (
                                        <div key={index} style={{padding: "4px"}}>
                                            <button key={index} className={`select-button protoss`}
                                                    onClick={() => {
                                                    }}
                                            >
                                                <div className={"protoss-image"}
                                                     style={{
                                                         backgroundImage: `url(${Incubators[record.boss].type})`,
                                                         filter: `${record.endTimestamp ? "grayscale(80%)" : "none"}`
                                                     }}
                                                >
                                                    {!record.endTimestamp ?
                                                        <div className="display-text">Id: {record.id}</div>
                                                        : null
                                                    }
                                                </div>
                                                {!record.endTimestamp ?
                                                    <Button
                                                        onClick={async () => {
                                                            try {
                                                                setPendingTx(true)
                                                                let status = await onFinalize(record.id)
                                                                if (status === 1) {
                                                                    toast.success("Battle finalized!")
                                                                }
                                                                setPendingTx(false)
                                                            } catch (e) {
                                                                console.error(e);
                                                                toast.error("Failed to finalize battle!");
                                                                setPendingTx(false)
                                                            }
                                                        }}
                                                        kind={"primary"}
                                                        to={undefined}
                                                        href={undefined}
                                                        space={undefined}
                                                        block={undefined}
                                                        disabled={pendingTx}
                                                    >
                                                        Claim Rewards
                                                    </Button>
                                                    :
                                                    <Countdown date={record.endTimestamp} className={"display-text"}/>}
                                            </button>
                                        </div>
                                    )
                                }))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

const RewardDialogue = () => {
    return (
        <>
        </>
    )
}