import axios from "axios";
import multicall from "./multicall";
import erc20ABI from "../config/abis/erc20.json";
import erc721ABI from "../config/abis/erc721.json";
import { abi as AccountTokenStorageABI } from "../config/abis/AccountStorageToken.json";
import MnAABI from "../config/abis/MnA.json";
import MnAV2ABI from "../config/abis/MnAv2.json";
import { getBalanceNumber } from "./formatBalance";
import {BigNumber} from "ethers";
import {getMnAv2Address, getStakingPoolv2Address, getSubgraphUrl} from "./addressHelpers";

export const getTokenBalance = async (account, tokenAddress) => {
  const calls = [
    {
      address: tokenAddress,
      name: "balanceOf",
      params: [account],
    },
    {
      address: tokenAddress,
      name: "decimals",
    },
  ];
  const [balance, decimal] = await multicall(erc20ABI, calls);
  return getBalanceNumber(balance, decimal);
};

export const getAccountTokenBalance = async (account, type) => {
  const calls = [
    {
      address: '0x874F5B7fBE862F3c4f3829D7D23B6aEEbb343751',
      name: "balanceOf",
      params: [account, type],
    }
  ];
  const balance = await multicall(AccountTokenStorageABI, calls);
  return getBalanceNumber(balance, 18);
};

export const getAllowance = async (owner, spender, tokenAddress) => {
  const calls = [
    {
      address: tokenAddress,
      name: "allowance",
      params: [owner, spender],
    },
  ];
  const allowance = await multicall(erc20ABI, calls);

  return BigNumber.from(allowance.toString());
};

export const getIsApprovedForAll = async (owner, operator, nftTokenAddress) => {
  const calls = [
    {
      address: nftTokenAddress,
      name: "isApprovedForAll",
      params: [owner, operator],
    },
  ];

  const isApprovedForAllRes = await multicall(erc721ABI, calls);
  return isApprovedForAllRes[0];
};

export const getOwnerOfMnAToken = async (MnAAddress, tokenId) => {
  const calls = [
    {
      address: MnAAddress,
      name: "ownerOf",
      params: [tokenId],
    },
  ];

  const [owner] = await multicall(MnAABI, calls);
  return owner[0];
};

export const getGameStats = async () => {
  try {
    const mnav2Address = getMnAv2Address();
    /*const marineSupply = [
      {
        address: mnav2Address,
        name: "totalSupply"
      },
    ];*/

    //const supply = await multicall(MnAV2ABI, marineSupply);

    const totalBalance = [
      {
        address: mnav2Address,
        name: "balanceOf",
        params: [getStakingPoolv2Address()]
      },
    ];

    const balRes = await multicall(MnAV2ABI, totalBalance);

    const res = await axios.post(getSubgraphUrl(), {
      query: `{
        infos {
          id
          marineMinted
          marineStolen
          alienMinted
          alienStolen
          marineStaked
          alienStaked
          oresClaimed
        }
      }
      `,
    });
    const resp = res.data.data.infos[0];
    const totalStaked = Number(balRes);
    return {
      marineMinted: resp.marineMinted,
      marineStolen: resp.marineStolen,
      marineStaked: totalStaked,
      alienStaked: 0,
      alienMinted: resp.alienMinted,
      alienStolen: resp.alienStolen,
      oresClaimed: resp.oresClaimed,
    };
  } catch (e) {
    console.error(e);
    return {
      marineMinted: 0,
      marineStolen: 0,
      marineStaked: 0,
      alienStaked: 0,
      alientMinted: 0,
      alienStolen: 0,
      oresClaimed: 0,
    };
  }
};
