// import { constants } from "ethers";
import AccountTree from "../merkle/accountTree";

export const getMerkleRoot = (whitelist) => {
  const accountTree = new AccountTree(whitelist);
  return accountTree.getHexRoot();
};

export const getMerkleProof = (whitelist, account) => {
  const accountTree = new AccountTree(whitelist);
  let merkleProof;
  try {
    merkleProof = accountTree.getProof(account);
  } catch (e) {
    merkleProof = [];
  }

  return merkleProof;
};
