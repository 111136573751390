import { createSlice } from "@reduxjs/toolkit";
import { getMnAGameAddress } from "../../utils/addressHelpers";
import multicall from "../../utils/multicall";
import MnAGameCRABI from "../../config/abis/MnAGameCR.json";

export const MnAGameCR = {
  allowCommits: false,
  user: {
    pendingMint: null,
    hasMintPending: false,
    canMint: false,
  },
};

const initialState = { data: MnAGameCR };
export const mnAGameSlice = createSlice({
  name: "MnAGameCR",
  initialState,
  reducers: {
    setMnAGamePublicData: (state, action) => {
      const liveMnAGameData = action.payload;
      state.data = { ...state.data, ...liveMnAGameData };
    },
    setMnAGameUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setMnAGamePublicData, setMnAGameUserData } =
  mnAGameSlice.actions;

export const fetchMnAGamePublicDataAsync = () => async (dispatch) => {
  const mnAGameData = await fetchMnAGame();
  dispatch(setMnAGamePublicData(mnAGameData));
};

export const fetchMnAGameUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchMnAGameUser(account);
  dispatch(setMnAGameUserData(userData));
};

const fetchMnAGame = async () => {
  const mnAGameAddress = getMnAGameAddress();
  const calls = [
    {
      address: mnAGameAddress,
      name: "allowCommits",
    },
  ];

  const [allowCommits] = await multicall(MnAGameCRABI, calls);

  return {
    ...MnAGameCR,
    allowCommits: allowCommits[0],
  };
};

const fetchMnAGameUser = async (account) => {
  const mnAGameAddress = getMnAGameAddress();

  const calls = [
    {
      address: mnAGameAddress,
      name: "hasMintPending",
      params: [account],
    },
    {
      address: mnAGameAddress,
      name: "canMint",
      params: [account],
    },
  ];
  const [hasMintPending, canMint] = await multicall(MnAGameCRABI, calls);

  let pendingMint = null;
  if (hasMintPending[0]) {
    [pendingMint] = await multicall(MnAGameCRABI, [
      {
        address: mnAGameAddress,
        name: "getPendingMint",
        params: [account],
      },
    ]);
  }
  return {
    pendingMint: pendingMint,
    hasMintPending: hasMintPending[0],
    canMint: canMint[0],
  };
};
export default mnAGameSlice.reducer;
