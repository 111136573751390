import "./stats-bar.scss";

export default function StatsBar({totalStaked}) {

    return (
        <div className="stats-bar">
            <h3>Total Marines &amp; Aliens in Action</h3>
            <div className="stats-bar-total">
                <div className="stats-bar-progress" style={{width: `${totalStaked}%`}}></div>
            </div>
            <p><strong>{`${totalStaked}`}%</strong> of all marines/aliens are being staked</p>
        </div>
    )
}