import multicall from "./multicall";
import erc20ABI from "../config/abis/erc20.json";
import { getBalanceNumber } from "./formatBalance";

export const getTokenPrice = async (pool, chainId) => {
  const calls = [
    {
      address: pool.tokenAddress[chainId],
      name: "balanceOf",
      params: [pool.lpAddress[chainId]],
    },
    {
      address: pool.quoteTokenAddress[chainId],
      name: "balanceOf",
      params: [pool.lpAddress[chainId]],
    },
  ];

  const [tokenBalance, quoteTokenBalance] = await multicall(erc20ABI, calls);
  const tokenBalanceInNum = getBalanceNumber(tokenBalance, pool.tokenDecimal);
  const quoteTokenBalanceInNum = getBalanceNumber(
    quoteTokenBalance,
    pool.quoteTokenDecimal
  );
  return quoteTokenBalanceInNum / tokenBalanceInNum;
};

export const getLpPrice = async (pool, chainId) => {
  const calls = [
    {
      address: pool.quoteTokenAddress[chainId],
      name: "balanceOf",
      params: [pool.lpAddress[chainId]],
    },
    {
      address: pool.lpAddress[chainId],
      name: "totalSupply",
    },
  ];

  const [quoteTokenBalance, lpTotalSupply] = await multicall(erc20ABI, calls);

  const quoteTokenBalanceInNum = getBalanceNumber(
    quoteTokenBalance,
    pool.quoteTokenDecimal
  );
  const lpTotalSupplyInNum = getBalanceNumber(lpTotalSupply, 18);
  return (quoteTokenBalanceInNum * 2) / lpTotalSupplyInNum;
};
