import { ethers } from "ethers";
import { DEFAULT_GAS_PRICE } from "../config";
import { simpleRpcProvider } from "./providers";
import erc20ABI from "../config/abis/erc20.json";
import erc721ABI from "../config/abis/erc721.json";
import multicallABI from "../config/abis/multicall.json";
import founderPassABI from "../config/abis/FounderPass.json";
import MnAABI from "../config/abis/MnA.json";
import MnAv2ABI from "../config/abis/MnAv2.json";
import SpidoxABI from "../config/abis/Spidox.json";
import MnAGameCRABI from "../config/abis/MnAGameCR.json";
import StakingPoolABI from "../config/abis/StakingPool.json";
import StakingPoolv2ABI from "../config/abis/StakingPoolv2.json";
import {abi as StakingPoolv3ABI} from "../config/abis/StakingPoolv3.json";
import RewardsABI from "../config/abis/rewardPools.json";
import WaterEggABI from "../config/abis/WaterEgg.json";
import FireEggABI from "../config/abis/FireEgg.json";
import DarknessEggABI from "../config/abis/DarknessEgg.json";
import PoisonEggABI from "../config/abis/PoisonEgg.json";
import {abi as OreReimbursementPool} from "../config/abis/OreReimbursementPool.json";
import {abi as AccountTokenStorage} from "../config/abis/OreReimbursementPool.json";
import {abi as Incubator} from "../config/abis/EggIncubator.json";
import {abi as BossBattles} from "../config/abis/BossBattles.json";
import {abi as Protoss} from "../config/abis/Protoss.json";

import {
  getFounderPassAddress,
  getMnAAddress,
  getMnAGameAddress,
  getMulticallAddress,
  getStakingPoolAddress,
  getSpidoxAddress,
  getRewardsAddress,
  getWaterEggAddress,
  getFireEggAddress,
  getDarknessEggAddress,
  getPoisonEggAddress,
  getMnAv2Address,
  getStakingPoolv2Address,
  getStakingPoolv3Address,
  getOreReimbursementAddress,
  getAccountTokenStorageAddress, getIncubatorAddress, getProtossAddress, getBossBattleAddress,
} from "./addressHelpers";

export const getDefaultGasPrice = () => {
  return DEFAULT_GAS_PRICE;
};

const getContract = (abi, address, signer) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getERC20Contract = (address, signer) => {
  return getContract(erc20ABI, address, signer);
};

export const getERC721Contract = (address, signer) => {
  return getContract(erc721ABI, address, signer);
};

export const getMulticallContract = (signer) => {
  return getContract(multicallABI, getMulticallAddress(), signer);
};

export const getFounderPassContract = (signer) => {
  return getContract(founderPassABI, getFounderPassAddress(), signer);
};

export const getMnAContract = (signer) => {
  return getContract(MnAABI, getMnAAddress(), signer);
};

export const getMnAv2Contract = (signer) => {
  return getContract(MnAv2ABI, getMnAv2Address(), signer);
};

export const getMnAGameCRContract = (signer) => {
  return getContract(MnAGameCRABI, getMnAGameAddress(), signer);
};

export const getStakingContract = (signer) => {
  return getContract(StakingPoolABI, getStakingPoolAddress(), signer);
};

export const getStakingv2Contract = (signer) => {
  return getContract(StakingPoolv2ABI, getStakingPoolv2Address(), signer);
};

export const getOreReimbursementContract = (signer) => {
  return getContract(OreReimbursementPool, getOreReimbursementAddress(), signer);
};

export const getAccountTokenStorageContract = (signer) => {
  return getContract(AccountTokenStorage, getAccountTokenStorageAddress(), signer);
};

export const getIncubatorContract = (signer) => {
  return getContract(Incubator, getIncubatorAddress(), signer);
};

export const getBossBattleContract = (signer) => {
  return getContract(BossBattles, getBossBattleAddress(), signer);
};

export const getProtossContract = (signer) => {
  return getContract(Protoss, getProtossAddress(), signer);
};

export const getStakingv3Contract = (signer) => {
  return getContract(StakingPoolv3ABI, getStakingPoolv3Address(), signer);
};

export const getSpidoxContract = (signer) => {
  return getContract(SpidoxABI, getSpidoxAddress(), signer);
};

export const getRewardsContract = (signer) => {
  return getContract(RewardsABI, getRewardsAddress(), signer);
};

export const getWaterEggContract = (signer) => {
  return getContract(WaterEggABI, getWaterEggAddress(), signer);
};

export const getFireEggContract = (signer) => {
  return getContract(FireEggABI, getFireEggAddress(), signer);
};

export const getDarknessEggContract = (signer) => {
  return getContract(DarknessEggABI, getDarknessEggAddress(), signer);
};

export const getPoisonEggContract = (signer) => {
  return getContract(PoisonEggABI, getPoisonEggAddress(), signer);
};

export const getVaultContract = (signer, address) => {
  return getContract(RewardsABI, address, signer);
};
