import { createSlice } from "@reduxjs/toolkit";

import BigNumber from "bignumber.js";
import { DEFAULT_CHAIN_ID } from "../../config";
import { Contracts } from "../../config/contracts";
import multicall from "../../utils/multicall";
import DarknessEggAbi from "../../config/abis/DarknessEgg.json";
import { getDarknessEggAddress } from "../../utils/addressHelpers";

export const DarknessEgg = {
  MAX_MINT: 0,
  totalMinted: 0,
  mintCost: 0,
  user: {
    claimed: false,
    balance: 0,
    tokenIds: [],
  },
};

const initialState = { data: DarknessEgg };
export const darknesseggSlice = createSlice({
  name: "DarknessEgg",
  initialState,
  reducers: {
    setDarknessEggPublicData: (state, action) => {
      const liveDarknessEggData = action.payload;
      state.data = { ...state.data, ...liveDarknessEggData };
    },
    setDarknessEggUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, user: userData };
    },
  },
});

export const { setDarknessEggPublicData, setDarknessEggUserData } =
  darknesseggSlice.actions;

export const fetchDarknessEggPublicDataAsync = () => async (dispatch) => {
  const darknesseggData = await fetchDarknessEgg();
  dispatch(setDarknessEggPublicData(darknesseggData));
};

export const fetchDarknessEggUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchDarknessEggUser(account);
  dispatch(setDarknessEggUserData(userData));
};

const fetchDarknessEgg = async () => {
  const darknesseggAddress = Contracts.darknessegg[DEFAULT_CHAIN_ID];
  const calls = [
    {
      address: darknesseggAddress,
      name: "MAX_MINT",
    },
    {
      address: darknesseggAddress,
      name: "totalMinted",
    },
    {
      address: darknesseggAddress,
      name: "mintCost",
    },
  ];

  const [maxMint, totalMinted, mintCost] = await multicall(
    DarknessEggAbi,
    calls
  );

  return {
    ...DarknessEgg,
    MAX_MINT: new BigNumber(maxMint.toString()).toJSON(),
    totalMinted: new BigNumber(totalMinted.toString()).toJSON(),
    mintCost: new BigNumber(mintCost.toString()).toJSON(),
  };
};

const fetchDarknessEggUser = async (account) => {
  const darknesseggAddress = getDarknessEggAddress();

  const calls = [
    {
      address: darknesseggAddress,
      name: "claimed",
      params: [account],
    },
    {
      address: darknesseggAddress,
      name: "balanceOf",
      params: [account],
    },
  ];
  const [claimed, balance] = await multicall(DarknessEggAbi, calls);

  let tokenIds = [];
  const balanceInNum = Number(balance.toString());
  const tokenIdCalls = [];
  if (balanceInNum > 0) {
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIdCalls.push({
        address: darknesseggAddress,
        name: "tokenOfOwnerByIndex",
        params: [account, idx],
      });
    }

    const tokenIdsRes = await multicall(DarknessEggAbi, tokenIdCalls);
    for (let idx = 0; idx < balanceInNum; idx++) {
      tokenIds.push(Number(tokenIdsRes[idx].toString()));
    }
  }

  return {
    claimed: claimed[0],
    balance: Number(balance.toString()),
    tokenIds: tokenIds,
  };
};
export default darknesseggSlice.reducer;
