import "./bio.scss"

export default function BioCard({title, name, img, badge, rank, role}) {



    return (
      <div className={`bio`}>
        <div className="bio-card">
          <div className={`bio-badge ${badge}`}></div>
          <img src={img} alt={title} />
          <h3>{rank}</h3>
        </div>
                    
        <h4>{name}</h4>
        <p>{role}</p>
      </div>
    )
  }