import Header from "../components/dashboard/header";
import Marine from "../images/characters/default.png";
import MarineV2 from "../images/characters/berserk.png";
import Alien from "../images/characters/alien.png";
import AlienV2 from "../images/characters/alien-wings.png";
import Eggs from "../images/backgrounds/egg.png";
import MarineBanner from "../images/backgrounds/symbols.png";
import Stars from "../images/backgrounds/ep2-stars.png";
import SwapCard from "../components/dashboard/swap-card";
import SwapStatus from "../components/dashboard/swap-status";
import { useMnAUser, useMnAv2User } from "../state/hooks";
import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import { useMnAContract, useMnAv2Contract } from "../hooks/useContracts";
import { getMnAAddress, getMnAv2Address } from "../utils/addressHelpers";
import { useApproveForAll } from "../hooks/useApprove";
import { useIsApprovedForAll } from "../hooks/useAllowance";
import { useMigration } from "../hooks/useMigration";

export default function Minting(props) {
  const mnaUser = useMnAUser();
  const tokens = mnaUser.tokens;
  const v1Marines = tokens.filter((token) => token.isMarine);
  const v1Aliens = tokens.filter((token) => !token.isMarine);

  const mnaUserv2 = useMnAv2User();
  const tokensv2 = mnaUserv2.tokens;
  const v2Marines = tokensv2.filter((token) => token.isMarine);
  const v2Aliens = tokensv2.filter((token) => !token.isMarine);

  const mnaContract = useMnAContract();

  const [mnaApproved, setMnAApproved] = useState(false);
  const isApprovedForAllForMnA =
    useIsApprovedForAll(getMnAAddress(), getMnAv2Address()) || mnaApproved;

  const { onApprovalForAll } = useApproveForAll(mnaContract, getMnAv2Address());
  const handleMnAApprove = useCallback(async () => {
    try {
      const approveStatus = await onApprovalForAll();
      if (approveStatus === 1) {
        setMnAApproved(true);
        toast.success("Approved successfully!");
      }
    } catch (e) {
      console.error(e);
      toast.error("Approve failed");
    }
  }, [onApprovalForAll]);

  const mnaV2Contract = useMnAv2Contract();
  const { onMigration } = useMigration(mnaV2Contract);

  // MARINE SWAP METHOD
  const migrateTokens = async (ids) => {
    try {
      const status = onMigration(ids);

      if (status === 1) {
        toast.success(`Swapped ${ids} successfully!`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`Swap ${ids} failed!`);
    }
  };

  return (
    <div>
      <Header title="Swap NFT" background={Stars} />

      <div className="grid">
        <div className="col-12">
          <SwapStatus />
        </div>
      </div>

      <div className="grid">
        <div className="col-6_md-12">
          {/* MARINES MINTING */}
          <SwapCard
            background={MarineBanner}
            img={Marine}
            imgV2={MarineV2}
            title="Marine Swap"
            v1={v1Marines}
            v2={v2Marines}
            isApproved={isApprovedForAllForMnA}
            onApprove={handleMnAApprove}
            onSwap={migrateTokens}
          />
        </div>

        <div className="col-6_md-12">
          {/* ALIENS MINTING */}
          <SwapCard
            background={Eggs}
            img={Alien}
            imgV2={AlienV2}
            title="Alien Swap"
            v1={v1Aliens}
            v2={v2Aliens}
            isApproved={isApprovedForAllForMnA}
            onApprove={handleMnAApprove}
            onSwap={migrateTokens}
          />
        </div>
      </div>
    </div>
  );
}
