import { useCallback } from "react";

export const usePurchaseWithMnA = (eggContract) => {
  const handlePurchaseWithMnA = useCallback(
    async (tokenId) => {
      try {
        const tx = await eggContract.mint(1, tokenId);
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [eggContract]
  );

  return { onPurchaseWithMnA: handlePurchaseWithMnA };
};
