import Header from "../components/dashboard/header";
import Marine from "../images/characters/default.png";
import Alien from "../images/characters/alienoo.png";
import Teeth from "../images/characters/teeth.png";
import $ORES from "../images/characters/ores.png";
import StatsCard from "../components/dashboard/stats-card";
import StatsBar from "../components/dashboard/stats-bar";
import { useGameStats } from "../hooks/useTokenBalance";
import BigNumber from "bignumber.js";
import { getBalanceNumber } from "../utils/formatBalance";
import { ORES_FOR_EPOCH1 } from "../config";

export default function Stats(props) {
  const gameStats = useGameStats();

  const marinesMinted = gameStats.marineMinted ?? 0;
  const marinesStaked = gameStats.marineStaked ?? 0;
  const aliensMinted = gameStats.alienMinted ?? 0;
  const aliensStaked = gameStats.alienStaked ?? 0;
  const marinesStolen = gameStats.marineStolen ?? 0;
  const aliensStolen = gameStats.alienStolen ?? 0;
  const collected = getBalanceNumber(new BigNumber(gameStats.oresClaimed));
  const uncollected = ORES_FOR_EPOCH1 - collected;
  const collectedFormatted = `${collected.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })}`;

  const uncollectedFormatted = `${uncollected.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })}`;
  let totalStaked = Math.floor(
    ((marinesStaked + aliensStaked) * 100) / (Number(marinesMinted) + Number(aliensMinted))
  );
  return (
    <div>
      <Header title="Game Stats" />

      <div className="grid">
        <div className="col-12">
          {/* TOTAL IN ACTION (STAKED) */}
          <StatsBar totalStaked={totalStaked} />
        </div>
      </div>

      <div className="grid">
        <div className="col-6_md-12">
          {/* MARINES STATS */}
          <StatsCard
            img={Marine}
            title="Marine Stats"
            heading1="Minted"
            heading2="Staked"
            data1={marinesMinted}
            data2={marinesStaked}
          />
        </div>

        <div className="col-6_md-12">
          {/* ALIENS STATS */}
          <StatsCard
            img={Alien}
            title="Alien Stats"
            heading1="Minted"
            heading2="Staked"
            data1={aliensMinted}
            data2={aliensStaked}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-6_md-12">
          {/* CAPTURED STATS */}
          <StatsCard
            img={Teeth}
            title="Captured Stats"
            heading1="Marines Stolen"
            heading2="Aliens Stolen"
            data1={marinesStolen}
            data2={aliensStolen}
          />
        </div>

        <div className="col-6_md-12">
          {/* $ORES */}
          <StatsCard
            img={$ORES}
            title="$ORES for Epoch 1"
            heading1="Claimed"
            heading2="Remaining"
            data1={collectedFormatted}
            data2={uncollectedFormatted}
          />
        </div>
      </div>
    </div>
  );
}
