import { NETWORK } from "./types";

export const Contracts = {
  multicall: {
    [NETWORK.LOCAL]: "0x643eA0a4305e396C6A33a85398366cFdfb07dAca",
    [NETWORK.RINKEBY]: "0xB9BD2bA1d1F851ef16Ee518e23d443E5a12B2922",
    [NETWORK.MATIC]: "0x643eA0a4305e396C6A33a85398366cFdfb07dAca",
  },
  weth: {
    [NETWORK.LOCAL]: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    [NETWORK.RINKEBY]: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
    [NETWORK.MATIC]: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  },
  founderPass: {
    [NETWORK.LOCAL]: "0xf4a57Dac3D3a4772347F813c6bF52b6286ac649e",
    [NETWORK.RINKEBY]: "0x51DdE1b055295d2c87A560EDF8fBB821BD655Bb4",
    [NETWORK.MATIC]: "0xf4a57Dac3D3a4772347F813c6bF52b6286ac649e",
  },
  mnA: {
    [NETWORK.LOCAL]: "0xdbe147fc80b49871e2a8D60cc89D51b11bc88b35",
    [NETWORK.RINKEBY]: "0xea444d53f63AD7e3a847257D9A35349149d0ec40",
    [NETWORK.MATIC]: "0xdbe147fc80b49871e2a8D60cc89D51b11bc88b35",
  },
  mnAGameCR: {
    [NETWORK.LOCAL]: "0xa7cF1E2ab93c03f322638E94c0d36f32ECc65372",
    [NETWORK.RINKEBY]: "0x4e8Cd440d8d5ea1F7ec570e58F1523C2c0fCCA27",
    [NETWORK.MATIC]: "0xa7cF1E2ab93c03f322638E94c0d36f32ECc65372",
  },
  stakingPool: {
    [NETWORK.RINKEBY]: "0xF29BAbB97864F849f640B762e77942DC48A6B6A2",
    [NETWORK.LOCAL]: "0x3912c25f9030f17aCbB2525F7D9245d5EE3C60D8",
    [NETWORK.MATIC]: "0x3912c25f9030f17aCbB2525F7D9245d5EE3C60D8",
  },
  ores: {
    [NETWORK.LOCAL]: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
    [NETWORK.RINKEBY]: "0x1090d5041621aB9636A9c4b7AE7b98CA0C4d3B76",
    [NETWORK.MATIC]: "0xE47a7eBF8eC907854CCabead79a5E720a329Bd31",
  },
  oresV2: {
    [NETWORK.LOCAL]: "0x7C426908D72416e6423FF8B279af8295f1fE34b5",
    [NETWORK.RINKEBY]: "0x7C426908D72416e6423FF8B279af8295f1fE34b5",
    [NETWORK.MATIC]: "0x7C426908D72416e6423FF8B279af8295f1fE34b5",
  },
  spidox: {
    [NETWORK.LOCAL]: "0x853bf5ad76d3AE766B7C3677FD0819c3a1af3443",
    [NETWORK.RINKEBY]: "0x9f184961b2b4c2a51Fe6cd3BDdaD69d284fCA0fC",
    [NETWORK.MATIC]: "0x853bf5ad76d3AE766B7C3677FD0819c3a1af3443",
  },
  rewards: {
    [NETWORK.RINKEBY]: "0x0E88fd51155A7804EB9E26F58DD03aC9A23Ab747",
    [NETWORK.LOCAL]: "0x60E36D0091BC46E44950b2EAc4dD2AF4701A535A",
    [NETWORK.MATIC]: "0x60E36D0091BC46E44950b2EAc4dD2AF4701A535A",
  },
  wateregg: {
    [NETWORK.LOCAL]: "0x5998ACB94fd4F341A712aD7977A3f5c83fE480F0",
    [NETWORK.RINKEBY]: "0xc40Cfac1Bc96b557BED6Bc58A8bE615b682e5293",
    [NETWORK.MATIC]: "0x5998ACB94fd4F341A712aD7977A3f5c83fE480F0",
  },
  fireegg: {
    [NETWORK.LOCAL]: "0x47565809997bC7cC599Eda6dCec23A72b803042E",
    [NETWORK.RINKEBY]: "0xe33D273bf6069AdbC22296F96Daf99bf9d9A4412",
    [NETWORK.MATIC]: "0x47565809997bC7cC599Eda6dCec23A72b803042E",
  },
  darknessegg: {
    [NETWORK.LOCAL]: "0x80c72bbf49aFeAeD03b6C5E56AA42A08FE075F12",
    [NETWORK.RINKEBY]: "0xB0941044a0c6A22Cd640fBE686664571ae94e7c2",
    [NETWORK.MATIC]: "0x80c72bbf49aFeAeD03b6C5E56AA42A08FE075F12",
  },
  poisonegg: {
    [NETWORK.LOCAL]: "0x30e22B833fAC9b3b57242103e9f12b6e2c9410A1",
    [NETWORK.RINKEBY]: "0xcc04C432144A47A609aA2DF1B582E90bB6ADD2F6",
    [NETWORK.MATIC]: "0x30e22B833fAC9b3b57242103e9f12b6e2c9410A1",
  },
  mnAv2: {
    [NETWORK.LOCAL]: "0x017bd8887521444ff8Fbce992A37a2FE53057149",
    [NETWORK.RINKEBY]: "0x937D139d866bCdE632e10F4886d3cf4a2eA0C153",
    [NETWORK.MATIC]: "0x017bd8887521444ff8Fbce992A37a2FE53057149",
  },
  stakingPoolv2: {
    [NETWORK.LOCAL]: "0x74f120f659aDEBd48414f4CbB2d5BB294452F625",
    [NETWORK.RINKEBY]: "0xD147B10a258ee748B130D3913EF98f2d446021D6",
    [NETWORK.MATIC]: "0x74f120f659aDEBd48414f4CbB2d5BB294452F625",
  },
  levelMath: {
    [NETWORK.LOCAL]: "0x8Ab109589A2f378e89b2b46f599Fda9a58F9B2fA",
    [NETWORK.RINKEBY]: "0xE08a421aD03CEEf8c3e4d17Bf5ab42b1c9e0870f",
    [NETWORK.MATIC]: "0x8Ab109589A2f378e89b2b46f599Fda9a58F9B2fA",
  },
  klaye: {
    [NETWORK.LOCAL]: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
    [NETWORK.RINKEBY]: "0xdc1b803916eD68ab4Bf0a572feE680f67115BEE6",
    [NETWORK.MATIC]: "0x24700a82a3EDD08C410f8D9099db684FfDc1Ab10",
  },
  calculator: {
    [NETWORK.RINKEBY]: "0xdc1b803916eD68ab4Bf0a572feE680f67115BEE6",
    [NETWORK.MATIC]: "0xfF10bD1baacCfbE0B797f5B6EfF20f28aD4faE62",
  },
  oreReimbursement: {
    [NETWORK.LOCAL]: "0x92164EC1E709A84179510608eF982da2367D4799",
    [NETWORK.MATIC]: "0x92164EC1E709A84179510608eF982da2367D4799",
  },
  accountTokenStorage: {
    [NETWORK.LOCAL]: "0x874F5B7fBE862F3c4f3829D7D23B6aEEbb343751",
    [NETWORK.MATIC]: "0x874F5B7fBE862F3c4f3829D7D23B6aEEbb343751",
  },
  incubator: {
    [NETWORK.LOCAL]: "0x6Ec5fC9CA44f7a1B34A1d0f2c9Ec83D3F9f2c001",
    [NETWORK.MATIC]: "0x6Ec5fC9CA44f7a1B34A1d0f2c9Ec83D3F9f2c001",
  },
  protoss: {
    [NETWORK.LOCAL]: "0x10f88f158512Ba2752201f0D7a5Ca747642Be9aF",
    [NETWORK.MATIC]: "0x10f88f158512Ba2752201f0D7a5Ca747642Be9aF",
  },
  bossBattles: {
    [NETWORK.LOCAL]: "0x9c74DAF758df12640cBBeB0441D8F2DeB03F2bfC",
    [NETWORK.MATIC]: "0x9c74DAF758df12640cBBeB0441D8F2DeB03F2bfC",
  },
};
