import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Web3ReactProvider} from "@web3-react/core";
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import ScrollToTop from "./components/dashboard/scrolltop";

import "./styles/index.scss";
import App from "./App";
import {getLibrary} from "./utils/web3React";
import {RefreshContextProvider} from "./contexts/RefreshContext";
import store from "./state";
import "react-toastify/dist/ReactToastify.css";
import {Web3ContextProvider} from "./hooks/web3Context";
import {WagmiConfig} from "wagmi";
import {wagmiClient} from "./hooks/wagmi";

ReactDOM.render(
    <React.StrictMode>
        <Web3ContextProvider>
            <WagmiConfig client={wagmiClient}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Web3ReactProvider getLibrary={getLibrary}>
                            <RefreshContextProvider>
                                <ToastContainer/>
                                <ScrollToTop/>
                                <App/>
                            </RefreshContextProvider>
                        </Web3ReactProvider>
                    </BrowserRouter>
                </Provider>
            </WagmiConfig>
        </Web3ContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
