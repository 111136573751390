import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {getAccountTokenBalance, getGameStats, getTokenBalance} from "../utils/callHelpers";
import useRefresh from "./useRefresh";
import {useWeb3Context} from "./web3Context";
import {useAccount} from "wagmi";

export const useTokenBalance = (tokenAddress) => {
  const { address, connected, connect } = useAccount();
  const [tokenBalance, setTokenBalance] = useState(0);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    if (address && ethers.utils.isAddress(tokenAddress)) {
      const fetchTokenBalance = async (_account, _tokenAddress) => {
        const res = await getTokenBalance(_account, _tokenAddress);
        setTokenBalance(res);
      };

      fetchTokenBalance(address, tokenAddress);
    }
  }, [address, tokenAddress, slowRefresh]);

  return tokenBalance;
};

export const useAccountTokenBalance = (type) => {
  const { address, connected, connect } = useAccount();
  const [tokenBalance, setTokenBalance] = useState(0);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    if (address) {
      const fetchTokenBalance = async (_account, type) => {
        const res = await getAccountTokenBalance(_account, type);
        setTokenBalance(res);
      };

      fetchTokenBalance(address, type);
    }
  }, [address, slowRefresh, type]);

  return tokenBalance;
};

export const useGameStats = () => {
  const { slowRefresh } = useRefresh();
  const [gameStats, setGameStats] = useState({});

  useEffect(() => {
    getGameStats().then((res) => setGameStats(res));
  }, [slowRefresh]);

  return gameStats;
};
